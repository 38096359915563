<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">Partager "{{documentHistorique.name}}"</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isLoading">

        <!-- formulaire de partage d'un document -->
        <form class="mb-4">
            <!-- input email -->
            <mat-form-field appearance="outline" class="input-full-width mb-2">
                <mat-label>Email</mat-label>
                <input  (keyup.enter)="addEmail()"
                        matInput
                        type="email"
                        placeholder="Sélectionner des emails"
                        [formControl]="formControlEmail"
                        [matAutocomplete]="auto">
                <button *ngIf="formControlEmail.value" (click)="addEmail()" mat-button matSuffix mat-icon-button aria-label="Submit">
                    <mat-icon>add</mat-icon>
                </button>
                <mat-hint>Appuyez sur "Entrer" ou cliquez sur le "+" pour ajouter une adresse email.</mat-hint>
            </mat-form-field>
            <!-- appel du "mat-autocomplete" de l'input emails créé ci-dessus -->
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addEmail()" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredEmails" [value]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete>

            <!-- on crée un espace où s'affiche les emails sélectionnés -->
            <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip   *ngFor="let email of emails; let i = index"
                            removable="true"
                            selectable="false"
                            (removed)="removeEmail(i)"
                            class="mt-2">
                    {{email}}
                    <mat-icon matChipRemove>clear</mat-icon>
                </mat-chip>
            </mat-chip-list>

            <!-- textarea message -->
            <mat-form-field appearance="outline" class="input-full-width mt-4">
                <mat-label>Message</mat-label>
                <textarea matInput name="message" [(ngModel)]="message"></textarea>
            </mat-form-field>
        </form>

        <div class="text-center mt-3">
            <button [disabled]="emails.length == 0 || isSaving" mat-raised-button color="primary" (click)="onSubmit()">
                <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
                <mat-icon *ngIf="!isSaving">send</mat-icon>
                Envoyer
            </button>
        </div>

    </div>
</div>
