<form fxFlex fxLayout="column" fxLayoutAlign="center center" class="example-form">
    <img src="/assets/YFERE_LogoCouleurTexte.png" alt="YFERE" height="100px" class="mb-3">
    <div *ngIf="isBusy" class="p-5">
        <mat-progress-spinner mode="indeterminate" class="m-auto"></mat-progress-spinner>
    </div>
    <div *ngIf="!isBusy">
        <div>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Mot de passe</mat-label>
                <input matInput name="first" type="password" [disabled]="isBusy" [(ngModel)]="password.first"
                       autocorrect="false" required>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Répéter le Mot de passe</mat-label>
                <input matInput name="second" type="password" [disabled]="isBusy" [(ngModel)]="password.second"
                       autocorrect="false" required>
            </mat-form-field>
        </div>
        <div class="text-center">
            <button [disabled]="!password.first || password.first !== password.second" mat-raised-button color="primary" type="submit" (click)="submit()">Envoyer</button>
        </div>
    </div>
</form>
