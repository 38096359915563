import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {SocietiesResult, TradesResult, UsersResult} from '@src/app/shared/interfaces';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Society} from '@src/app/model/society.model';
import {BaseComponent} from '@src/app/shared/base.component';
import {Trade} from '@src/app/model/trade.model';

@Component({
    selector: 'app-annuaire-user-filter',
    templateUrl: './annuaire-user-filter.component.web.html',
    styleUrls: ['./annuaire-user-filter.component.web.scss']
})
export class AnnuaireUserFilterComponent extends BaseComponent implements OnInit {

    @Output() usersFiltred = new EventEmitter<Observable<UsersResult>>();

    open = true;
    isBusy: boolean;
    optionsTrades$: Observable<Trade[]>;
    optionsSocieties$: Observable<Society[]>;
    filterForm: FormGroup;

    constructor(
        private apiService: ApiService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;

        if (this.hasRoles(['ROLE_AFFAIRE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {
            this.optionsTrades$ = this.apiService.getTrades().pipe(
                map((tradesResult: TradesResult) => tradesResult.trades)
            );
            this.optionsSocieties$ = this.apiService.getSocietiesFilter({filter: {visible: 1}}, 1, 2000).pipe(
                map((societiesResult: SocietiesResult) => societiesResult.societies),
                tap(() => this.isBusy = false)
            );
        }

        this.filterForm = new FormGroup({
            name: new FormControl(),
            trade: new FormControl(),
            society: new FormControl(),
            visible: new FormControl(),
        });

        this.onChanges();

        const storageFilter = this.storageService.userListFilter;

        if (storageFilter && (storageFilter.name || storageFilter.trade || storageFilter.society || storageFilter.visible)) {
            this.filterForm.patchValue(storageFilter);
        }
    }

    toggle() {
        this.open = !this.open;
    }

    onChanges(): void {
        this.filterForm.valueChanges.subscribe(val => {
            this.storageService.userListFilter = val;
            this.usersFiltred.emit(this.apiService.getUsersAnnuaire({filter: val}));
        });
    }

    onReset(): void {
        this.filterForm.reset();
    }
}
