<div fxFlex fxLayout="column" class="bg-white">
    <mat-paginator *ngIf="societies" [length]="length"
                   [pageSize]="pageSize" [pageIndex]="pageIndex"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   (page)="pageEventHandler($event)">
    </mat-paginator>

    <div fxFlex fxLayoutAlign="center center" *ngIf="isBusy" class="p-5">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <!-- nouvelle liste -->
    <div class="container-fluid">
        <div *ngIf="!isBusy" class="row">
            <div class="{{selectedSociety ? 'col-lg-7' : 'col-lg-12'}}">
                <app-annuaire-society-item  *ngFor="let society of societies" [society]="society" [viewContainerRef]="viewContainerRef"
                                           (societyDeleted)="societyDeletedHandler($event)" (societyEmitted)="societyEmittedHandler($event)"
                                           (societyTapped)="societyTappedHandler($event)"></app-annuaire-society-item>
            </div>
            <div class="col-lg-5">
                <app-annuaire-society-detail [society]="selectedSociety" [viewContainerRef]="viewContainerRef" [class.sticky]="sticky"></app-annuaire-society-detail>
            </div>
        </div>
    </div>

    <mat-paginator *ngIf="societies" [length]="length"
                   [pageSize]="pageSize" [pageIndex]="pageIndex"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   (page)="pageEventHandler($event)">
    </mat-paginator>
</div>
