<form fxFlex fxLayout="column" fxLayoutAlign="center center" class="example-form">
    <img src="/assets/YFERE_LogoCouleurTexte.png" alt="YFERE" height="100px" class="mb-3">
    <div *ngIf="isBusy" class="p-5">
        <mat-progress-spinner mode="indeterminate" class="m-auto"></mat-progress-spinner>
    </div>
    <div *ngIf="!isBusy">
        <div>
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Adresse email</mat-label>
                <input #emailRef matInput name="email" type="email" [disabled]="isBusy" [(ngModel)]="email"
                       autocorrect="false" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            </mat-form-field>
        </div>
        <div class="text-center">
            <button [disabled]="!emailRef.checkValidity()" mat-raised-button color="primary" type="submit" (click)="submit()">Envoyer</button>
        </div>
    </div>
</form>
