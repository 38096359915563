<div class="d-flex flex-row flex-grow-1 rounded mt-3" [ngClass]="{'bg-specific': dossier.code == 'CHIFFRAGE_1', 'bg-white': dossier.code != 'CHIFFRAGE_1'}">
    <a [routerLink]="isActionBusy ? null : dossier.code == 'CHIFFRAGE_1' ? ['/specific', dossier.id] : ['/affair-dossier', dossier.id]"
       matRipple [matRippleDisabled]="isActionBusy"
       class="d-flex flex-column flex-grow-1 cursor-pointer text-decoration-none text-reset has-ripple overflow-hidden rounded-left py-2 pl-2">
        <div class="d-flex flex-row justify-content-start align-items-center p-2" [class.text-muted]="isActionBusy">
            <div class="dossier-list-picto border rounded-lg mr-2">
                <mat-icon *ngIf="dossier.code != 'CHIFFRAGE_1'" class="fz-20">folder</mat-icon>
                <mat-icon *ngIf="dossier.code == 'CHIFFRAGE_1'" fontSet="fas" fontIcon="fa-binoculars" class="fz-20"></mat-icon>
            </div>
            <div>{{dossier.label}}</div>
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center p-2">
            <span class="mr-3" [class.text-muted]="isActionBusy">
                <mat-icon fontSet="far" fontIcon="fa-file"></mat-icon>
                <span>{{dossier.nbDocuments}}</span>
            </span>
            <span [class.text-danger]="dossier.nbDiscutions > 0" [class.text-muted]="isActionBusy">
                <mat-icon fontSet="far" fontIcon="fa-comment"></mat-icon>
                <span>{{dossier.nbDiscutions}}</span>
            </span>
            <span *ngIf="dossier.users.length && !hasRoles(['ROLE_GUEST']) && ((dossier.allowTo.societies && dossier.code != 'CHIFFRAGE_1') || affair.isChargeAffair)"
                  [matTooltip]="concatTooltip(dossier.users)" matTooltipPosition="above" [matTooltipDisabled]="isActionBusy"
                  class="ml-auto d-flex flex-row align-items-center" [class.text-muted]="isActionBusy">
                <mat-icon>person</mat-icon>
                <span>{{dossier.users.length}}</span>
            </span>
        </div>
    </a>
    <div *ngIf="!hasRoles(['ROLE_GUEST']) && ((dossier.allowTo.edit && dossier.code != 'CHIFFRAGE_1') || affair.isChargeAffair)"
         class="d-flex flex-column justify-content-center align-items-center pr-2">
        <button mat-icon-button [disabled]="isActionBusy"  [matMenuTriggerFor]="dossierMenu" [matMenuTriggerData]="{dossier: dossier}">
            <mat-spinner *ngIf="isActionBusy" class="btn-spinner"></mat-spinner>
            <mat-icon *ngIf="!isActionBusy">more_vert</mat-icon>
        </button>
        <mat-menu #dossierMenu="matMenu" xPosition="before">
            <ng-template matMenuContent let-dossier="dossier">
                <button mat-menu-item (click)="onTapEdit()">
                    <mat-icon>edit</mat-icon>
                    <span>Modifier</span>
                </button>
                <button mat-menu-item (click)="onTapAuthorize()">
                    <mat-icon>admin_panel_settings</mat-icon>
                    <span>Autoriser</span>
                </button>
                <button *ngIf="dossier.state == 2 || dossier.state == 3" mat-menu-item (click)="onTapDo()">
                    <mat-icon class="text-danger">undo</mat-icon>
                    <span>À faire</span>
                </button>
                <button *ngIf="dossier.state == 1 || dossier.state == 3" mat-menu-item (click)="onTapWork()">
                    <mat-icon class="text-warning">{{ dossier.state == 1 ? 'redo' : 'undo'}}</mat-icon>
                    <span>En cours</span>
                </button>
                <button *ngIf="dossier.state == 1 || dossier.state == 2" mat-menu-item (click)="onTapValid()">
                    <mat-icon class="text-success">redo</mat-icon>
                    <span>Validé</span>
                </button>
                <button *ngIf="dossier.allowTo.remove" mat-menu-item (click)="onTapDelete()">
                    <mat-icon class="text-danger">delete_outline</mat-icon>
                    <span>Supprimer</span>
                </button>
            </ng-template>
        </mat-menu>
    </div>
</div>
