<div class="d-flex flex-column">
    <app-affair-nav [dossier]="dossier" [back]="back" [backQueryParams]="backQueryParams" [title]="title" [querySearch]="querySearch" [isBusy]="isBusy" [items]="items"
                    class="d-flex flex-row justify-content-between align-items-center bg-primary-light rounded-bottom p-3 mb-3">
    </app-affair-nav>

    <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isBusy" class="container-fluid">
        <div class="row pb-3">
            <div class="col-lg-8 mb-3">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span class="fz-18">Documents du dossier :</span>
                    <div class="d-flex flex-row align-items-center">
                        <label *ngIf="dossier.allowTo.document && !hasRoles(['ROLE_GUEST'])" for="file" class="d-inline-flex">
                            <mat-icon matRipple [matRippleCentered]="true" matTooltip="Ajouter des documents"
                                      matTooltipPosition="left" class="icon-20">
                                file_upload
                            </mat-icon>
                        </label>
                        <input type="file" id="file" multiple (change)="handleFileInput($event.target.files)">
                        <mat-icon *ngIf="dossier.allowTo.edit && !hasRoles(['ROLE_GUEST'])" (click)="onTapAddDossier(dossier)"
                                  matTooltip="Ajouter un dossier" matTooltipPosition="above"
                                  matRipple [matRippleCentered]="true" class="icon-20 ml-2">
                            create_new_folder
                        </mat-icon>
                        <button (click)="onTapDownloadAllDocument()" mat-stroked-button color="primary" class="btn-rounded ml-2">
                            <mat-spinner *ngIf="spinner.allDownload" class="btn-spinner"></mat-spinner>
                            <mat-icon *ngIf="!spinner.allDownload">get_app</mat-icon>
                            <span class="d-none d-lg-inline-block  ml-1">Tout télécharger</span>
                        </button>
                        <mat-spinner *ngIf="spinner.allValid" class="btn-spinner ml-3 mr-2"></mat-spinner>
                        <mat-icon *ngIf="dossier.allowTo.edit && !hasRoles(['ROLE_GUEST']) && !spinner.allValid" (click)="onTapValidAll(dossier)"
                                  matTooltip="Valider tous les fichiers" matTooltipPosition="above"
                                  matRipple class="icon-20 text-success mx-2">
                            done_all
                        </mat-icon>
                    </div>
                </div>

                <div *ngIf="dossier" class="d-flex flex-column bg-primary-light rounded px-3 pb-3 pt-2">
                    <div *ngIf="upload.isUploading" class="d-flex flex-row align-items-center order-0 mt-2">
                        <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2 ml-4 ">
                            <mat-icon *ngIf="!upload.isProcessing">publish</mat-icon>
                            <mat-spinner *ngIf="upload.isProcessing"
                                         class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
                            <div class="d-flex flex-grow-1 mx-2">
                                <mat-progress-bar *ngIf="!upload.isProcessing" mode="determinate" [value]="upload.progress"></mat-progress-bar>
                                <span *ngIf="upload.isProcessing">Traitement du fichier en cours ...</span>
                            </div>
                        </div>
                    </div>
                    <p *ngIf="!isBusy && !upload.isUploading && !dossier.documentHistoriques.length" class="no-result d-flex justify-content-center align-items-center text-danger">
                        <mat-icon>report</mat-icon> Aucun document n'a été ajouté dans ce dossier.
                    </p>
                    <app-document *ngFor="let documentHistorique of dossier.documentHistoriques; trackBy: trackBy;"
                                  (documentClicked)="documentClickedHandler($event)"
                                  (activityAdded)="activityAddedHandler($event)"
                                  [preview]="previewIdDocumentHistorique == documentHistorique.id"
                                  [documentHistorique]="documentHistorique"
                                  [documentHistoriques]="dossier.documentHistoriques"
                                  [selectedDocumentHistorique]="selectedDocumentHistorique" [dossier]="dossier"
                                  [viewContainerRef]="viewContainerRef" class="order-1"></app-document>
                    <app-dossier *ngFor="let subDossier of dossier.dossiers; trackBy: trackBy;"
                                 (dossierDeleted)="dossierDeletedHandler($event)" [dossier]="subDossier"
                                 [selectedDocumentHistorique]="selectedDocumentHistorique"
                                 [viewContainerRef]="viewContainerRef" [activeStatus]="activeStatus"
                                 class="d-flex flex-column order-1 ml-4"></app-dossier>
                </div>
            </div>

            <div class="col-lg-4">
                <div *ngIf="activityOpen" class="bg-primary-light rounded">
                    <div *ngIf="activityList; else loading" class="activity-list pb-3" fxLayout="column" fxLayoutAlign="space-around">
                        <div class="activity-list-title d-flex flex-row p-3">
                            <div class="d-flex flex-grow-1 align-items-center activity-list-title-text">Activités</div>
                            <div>
                                <button (click)="onTapCloseActivity()" mat-icon-button class="activity-list-title-action">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="activity-input d-flex flex-fill flex-row align-items-center px-3">
                            <div class="activity-input-avatar">
                                <span>{{ initiale }}</span>
                            </div>
                            <div class="d-flex flex-grow-1">
                                <mat-form-field appearance="outline" class="mat-input-search pb-0 w-100 no-margin">
                                    <mat-label>Votre message</mat-label>
                                    <input matInput name="message" type="text" [disabled]="spinner.message"
                                           (keyup.enter)="onSubmitMessage()" [(ngModel)]="message" autocorrect="false">
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-spinner *ngIf="spinner.message" class="btn-spinner btn-spinner-lg"></mat-spinner>
                                <mat-icon *ngIf="!spinner.message" (click)="onSubmitMessage()"
                                          class="icon-30 text-primary cursor-pointer active">send
                                </mat-icon>
                            </div>
                        </div>

                        <app-activity *ngFor="let activity of activityList" [activity]="activity" class="px-3"></app-activity>
                    </div>
                    <ng-template #loading>
                        <div class="d-flex flex-column justify-content-center align-items-center py-3">
                            <mat-spinner></mat-spinner>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
