import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '@src/app/model/user.model';
import {ApiService} from '@src/app/services/api.service';
import {SocietiesResult, UsersResult} from '@src/app/shared/interfaces';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Society} from '@src/app/model/society.model';
import {BaseComponent} from '@src/app/shared/base.component';
import {Trade} from '@src/app/model/trade.model';

@Component({
    selector: 'app-annuaire-society-filter',
    templateUrl: './annuaire-society-filter.component.web.html',
    styleUrls: ['./annuaire-society-filter.component.web.scss']
})
export class AnnuaireSocietyFilterComponent extends BaseComponent implements OnInit {

    @Output() societiesFiltred = new EventEmitter<Observable<SocietiesResult>>();

    open = true;
    isBusy: boolean;
    optionsUsers$: Observable<User[]>;
    filterForm: FormGroup;

    constructor(
        private apiService: ApiService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;

        if (this.hasRoles(['ROLE_AFFAIRE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {
            this.optionsUsers$ = this.apiService.getUsersAnnuaire({filter: {visible: 1}}, 1, 1000).pipe(
                map((usersResult: UsersResult) => usersResult.users),
                tap(() => this.isBusy = false)
            );
        }

        this.filterForm = new FormGroup({
            name: new FormControl(),
            user: new FormControl(),
        });

        this.onChanges();

        const storageFilter = this.storageService.societyListFilter;

        if (storageFilter && (storageFilter.name || storageFilter.user)) {
            this.filterForm.patchValue(storageFilter);
        }
    }

    toggle() {
        this.open = !this.open;
    }

    onChanges(): void {
        this.filterForm.valueChanges.subscribe(val => {
            this.storageService.societyListFilter = val;
            this.societiesFiltred.emit(this.apiService.getSocietiesFilter({filter: val}));
        });
    }

    onReset(): void {
        this.filterForm.reset();
    }
}
