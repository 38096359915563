<div [ngSwitch]="activity.type">
    <div *ngSwitchCase="'ADD_FILE'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a ajouté le fichier.
                <mat-icon class="text-success">note_add</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'UPDATE_FILE'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a mis à jour le fichier.
                <mat-icon class="text-warning">insert_drive_file</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'VALID'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a validé son dossier entreprise.
                <mat-icon class="text-success">verified</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'REFUSE'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a refusé son dossier entreprise.
                <mat-icon class="text-danger">new_releases</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'VALID_SOCIETY'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a validé la réponse de l'entreprise.
                <mat-icon class="text-success">verified</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'REFUSE_SOCIETY'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a refusé la réponse de l'entreprise.
                <mat-icon class="text-danger">new_releases</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'ADD_FAVORIS'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a ajouté le fichier en favoris.
                <mat-icon class="text-warning">star</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'REMOVE_FAVORIS'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a supprimé le fichier de ces favoris.
                <mat-icon class="text-danger">star_border</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'FILE_VALID'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a changé l'état du fichier en "Validé".
                <mat-icon class="text-success">redo</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'FILE_WORK'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a changé l'état du fichier en "En cours".
                <mat-icon class="text-warning">redo</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'FILE_DO'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a changé l'état du fichier en "A faire".
                <mat-icon class="text-danger">redo</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'COMMENT'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a ajouté un commentaire.
                <mat-icon class="text-success">add_comment</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'ADD_SUIVIS'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} suit désormais le fichier.
                <mat-icon class="text-success">visibility</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'REMOVE_SUIVIS'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} ne suit plus le fichier.
                <mat-icon class="text-danger">visibility_off</mat-icon>
            </div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchCase="'MESSAGE'" class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div class="d-flex flex-column flex-grow-1">
            <div class="activity-item-fullname">{{ activity.user.fullName }} a ajouter un commentaire :</div>
            <div class="bg-white rounded p-2 my-1">{{activity.message}}</div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>

    <div *ngSwitchDefault class="activity-item d-flex flex-row">
        <div class="activity-item-avatar">
            <span>{{ activity.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ activity.user.fullName }} a fait une action.</div>
            <div class="activity-item-datetime">{{ activity.datetime | amCalendar }}</div>
        </div>
    </div>
</div>
