import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Dossier} from '@src/app/model/dossier.model';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import * as moment from 'moment';
import {ActionResult} from '@src/app/shared/interfaces';

@Component({
  selector: 'app-modal-date-limite',
  templateUrl: './modal-date-limite.component.web.html',
})
export class ModalDateLimiteComponent implements OnInit {

  notifier = new Subject();
  isLoading: boolean;
  isSaving: boolean;
  dossier: Dossier;
  dossierForm: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<ModalDateLimiteComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {dossier: Dossier},
      private apiService: ApiService,
      private toastService: ToastService,
      private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.isSaving = false;
    this.dossier = this.data.dossier;
    this.createForm();
  }

  createForm(): void {
    this.dossierForm = new FormGroup({
      dateLimit: new FormControl(this.dossier.dateLimit),
    });
    this.cdRef.detectChanges();
    this.isLoading = false;
  }

  onSubmit(): void {
    if (this.dossierForm.valid) {
      this.isSaving = true;
      const data = this.dossierForm.value;
      const dateLimit = data.dateLimit ? data.dateLimit.format() : null;
      data.dateLimit = this.formatDate(data.dateLimit);
      this.apiService.putDossierDateLimite(this.dossier.id, data).subscribe((result: ActionResult) => {
        this.isSaving = false;
        if (result.success) {
          this.dialogRef.close({dateLimit: dateLimit});
        }
      }, () => this.isSaving = false);
    }
  }

  private formatDate(date: any) {
    if (date) {
      if (typeof date === 'object') {
        return date.format('DD/MM/YYYY');
      } else {
        return moment(date).format('DD/MM/YYYY');
      }
    }
    return null;
  }

}
