import {Component, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import {SimpleChanges} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {ENTITIES, Society, THIRD_PARTIES, TYPES} from '@src/app/model/society.model';
import {Contact} from '@src/app/model/contact.model';

@Component({
    selector: 'app-annuaire-society-detail',
    templateUrl: './annuaire-society-detail.component.html',
    styleUrls: ['./annuaire-society-detail.component.scss'],
})
export class AnnuaireSocietyDetailComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() viewContainerRef: ViewContainerRef;
    @Input() society: Society;
    isBusy: boolean;
    contact: Contact;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.contact = null;
    }

    onTapContact(contact: Contact) {
        this.contact = contact;
    }

    getType(key: number): string {
        return TYPES.find(t => t.key === key).label;
    }

    getLegalEntity(key: number): string {
        return ENTITIES.find(e => e.key === key).label;
    }

    getThirdParty(key: number): string {
        return THIRD_PARTIES.find(tp => tp.key === key).label;
    }
}
