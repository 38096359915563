import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {AppComponent} from '@src/app/app.component';
import {User} from '@src/app/model/user.model';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {CIVILITES} from '@src/app/model/contact.model';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {ErrorStateMatcher} from '@src/app/shared/utils';
import {Location} from '@angular/common';
import {MatDrawerMode} from '@angular/material/sidenav';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.web.html',
})
export class AccountComponent extends BaseComponent implements OnInit {
    title = 'Mon compte';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy: boolean;
    isSaving = false;
    user: User;
    userForm: FormGroup;
    matcher: ShowOnDirtyErrorStateMatcher;
    civilities = CIVILITES;

    constructor(
        private appComponent: AppComponent,
        private apiService: ApiService,
        private router: Router,
        private toastService: ToastService,
        public viewContainerRef: ViewContainerRef,
        private location: Location,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit() {
        this.isBusy = true;
        this.apiService.getUser(this.storageService.userId).subscribe((user) => {
            this.user = user;
            this.createForm();
        });
    }

    goBack(): void {
        this.location.back();
    }

    createForm() {
        this.isBusy = false;
        this.userForm = new FormGroup({
            civility: new FormControl(this.user.contact.civility),
            name: new FormControl(this.user.contact.name),
            surname: new FormControl(this.user.contact.surname),
            email: new FormControl(this.user.contact.email),
            phone: new FormControl(this.user.contact.phone),
            position: new FormControl(this.user.contact.position),
            address: new FormControl(this.user.contact.address),
            postalCode: new FormControl(this.user.contact.postalCode),
            city: new FormControl(this.user.contact.city),
            country: new FormControl(this.user.contact.country),
            update: new FormControl(1),
        });
        this.userForm.addControl('user', new FormGroup({
            plainPassword: new FormGroup({
                first: new FormControl(''),
                second: new FormControl(''),
            }, {validators: this.checkPasswords}),
        }));

        this.matcher = new ErrorStateMatcher();
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        const first = group.get('first').value;
        const second = group.get('second').value;

        return first === second ? null : {notSame: true};
    }

    onSubmit(): void {
        if (this.userForm.valid) {
            this.isSaving = true;
            const data = this.userForm.value;
            if (data.user.plainPassword.first === '') {
                delete data.user;
            }
            this.apiService.putAccount(this.storageService.userId, this.userForm.value).subscribe((contact) => {
                this.isSaving = false;
                if (contact) {
                    this.storageService.initiale = contact.initiale;
                    this.appComponent.initiale = contact.initiale;
                    this.storageService.email = contact.email;
                    this.storageService.fullName = contact.name;
                    this.toastService.show(`Votre compte a été modifié.`);
                    this.router.navigate(['home']);
                }
            }, () => this.isSaving = false);
        }
    }
}
