import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Dossier, DossierSpecificList} from '@src/app/model/dossier.model';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {Trade} from '@src/app/model/trade.model';
import * as moment from 'moment';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormService} from '@src/app/services/form.service';
import {FilterAuthorize, BridgeModalStorage} from '@src/app/shared/interfaces';
import {User} from '@src/app/model/user.model';
import {AlertService} from '@src/app/services/alert.service';
import {ModalService} from '@src/app/services/modal.service';
import {DossierService} from '@src/app/services/dossier.service';

@Component({
    selector: 'app-modal-authorize',
    templateUrl: 'modal-authorize.component.web.html',
})
export class ModalAuthorizeComponent implements OnInit, OnDestroy {

    notifier = new Subject();
    dossier: Dossier;
    searchForm: FormGroup;
    filter: FilterAuthorize;
    trades: Trade[];
    formControlObj: FormControl;
    selectedUsers: User[];
    allUsers: User[];
    unalteredUsers: User[];
    isLoading: boolean;
    isSaving: boolean;

    // TODO clean formService cause not used anymore
    constructor(
        public dialogRef: MatDialogRef<ModalAuthorizeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {dossier: DossierSpecificList, parentDossier: Dossier, returnList: boolean},
        private apiService: ApiService,
        private formService: FormService,
        private alertService: AlertService,
        private modalService: ModalService,
        private dossierService: DossierService,
    ) {
        dialogRef.afterClosed().subscribe(args => {
            if (!(args instanceof BridgeModalStorage)) {
                this.formService.remove(['selected', 'filter', 'user']);
            }
        });
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.isSaving = false;
        this.filter = this.formService.filter ? this.formService.filter : new FilterAuthorize();

        // tslint:disable-next-line:max-line-length
        const id = this.data.dossier.versions?.length ? this.data.dossier.versions[this.data.dossier.versions.length - 1].id : this.data.dossier.id;

        this.dossierService.get(id)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                this.dossier = dossier;
                this.selectedUsers = this.formService.selected ? this.formService.selected : this.dossier.users;
                this.selectedUsers = this.dossier.users;
                this.allUsers = [];
                this.unalteredUsers = [];

                this.apiService.getTrades()
                    .pipe(takeUntil(this.notifier))
                    .subscribe((response) => {
                        this.trades = response.trades;
                        this.isLoading = false;
                    });

                this.searchForm = new FormGroup({
                    name: new FormControl(this.filter.name),
                    affair: new FormControl(this.filter.affair),
                    trade: new FormControl(this.filter.trade),
                });

                this.searchForm.valueChanges
                    .pipe(takeUntil(this.notifier))
                    .subscribe((values) => {
                        this.formService.filter = this.filter = values;
                        if (this.formService.user) {
                            this.allUsers = [this.formService.user];
                            this.unalteredUsers = [...this.formService.selected, this.formService.user];
                            this.formService.remove(['user']);
                        } else {
                            const data = {filter: values};
                            data.filter.affair = data.filter.affair ? this.dossier.affair.id : null;
                            this.apiService.getUsersFilter(data)
                                .pipe(takeUntil(this.notifier))
                                .subscribe((users) => {
                                    this.unalteredUsers = users;
                                    this.allUsers = this.unalteredUsers.filter(this.compareArray(this.selectedUsers));
                                });
                        }
                    });

                this.formControlObj = new FormControl(this.selectedUsers);

                this.formControlObj.valueChanges
                    .pipe(takeUntil(this.notifier))
                    .subscribe((value: User[]) => {
                        this.selectedUsers = value;
                        this.formService.selected = this.selectedUsers;
                        this.allUsers = this.unalteredUsers.filter(this.compareArray(this.selectedUsers));
                    });
            });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    // TODO create an utility or directive for this alert before close
    onClose(args: Dossier|BridgeModalStorage = null) {
        if (!args && this.formService.selected && this.dossier && this.formService.selected !== this.dossier.users) {
        // if (!args && this.dossier) {
            const text = `Des modifications ont été apportées.<br>
             Êtes-vous certain de vouloir fermer sans sauvegarder ?`;
            this.alertService.show(
                text,
                'Sauvegarder',
                null,
                'Ne pas sauvegarder'
            ).then((result) => {
                if (result.isConfirmed) { this.onSubmit(); }
                if (result.isDenied) {  this.dialogRef.close(args); }
            });
        } else {
            this.dialogRef.close(args);
        }
    }

    // TODO user service
    onTapAddUser(type: string = 'user') {
        this.formService.selected = this.selectedUsers;
        this.formService.filter = this.filter;
        const args = new BridgeModalStorage({from: 'authorize', to: type, storage: ['selected', 'filter']});

        this.modalService.show(
            {bridge: args},
            type,
            null,
            false
        ).subscribe((result: User) => {
            if (result) { this.searchForm.reset(); }
        });
    }

    compareList(c1: { id: number }, c2: { id: number }) {
        return c1 && c2 && c1.id === c2.id;
    }

    compareArray(otherArray) {
        return function (current) {
            return otherArray.filter(function (other) {
                return other.id === current.id && other.name === current.name;
            }).length === 0;
        };
    }

    onSubmit(): void {
        if (this.formControlObj.valid) {
            this.isSaving = true;
            const data = {
                type: this.dossier.typeSpecific,
                state: this.dossier.state,
                label: this.dossier.label,
                dateLimit: this.formatDate(this.dossier.dateLimit),
                users: this.formControlObj.value.map(s => s.id),
                shortcuts: this.dossier.shortcuts.map(s => s.id),
                diffuse: this.data.parentDossier?.code === 'CHIFFRAGE_1' ? 1 : 0,
                specificDetail: this.dossier.specificDetail ? 1 : 0,
                specificList: this.data.returnList ? 1 : 0,
            };
            this.apiService.postDossier(this.dossier.id, data)
                .pipe(takeUntil(this.notifier))
                .subscribe((dossier: Dossier) => {
                    this.isSaving = false;
                    if (dossier) { this.onClose(dossier); }
                }, () => this.isSaving = false);
        }
    }

    private formatDate(date: any) {
        if (date) {
            if (typeof date === 'object') {
                return date.format('DD/MM/YYYY');
            } else {
                return moment(date).format('DD/MM/YYYY');
            }
        }
        return null;
    }

    trackByFn(index, item) {
        return item.id;
    }
}
