<div class="d-flex flex-row justify-content-between align-items-center mb-3">
    <span class="fz-18 my-2">Documents de l'utilisateur :</span>
    <div *ngIf="!isBusy" class="d-flex flex-row align-items-center">
        <label *ngIf="dossier.allowTo.document" for="file_user_{{dossier.id}}" class="d-inline-flex">
            <mat-icon matRipple [matRippleCentered]="true" matTooltip="Ajouter des documents" matTooltipPosition="left" class="icon-20">
                file_upload
            </mat-icon>
        </label>
        <input type="file" id="file_user_{{dossier.id}}" class="d-none" multiple (change)="handleFileInput($event.target.files, dossier)">
    </div>
</div>
<div *ngIf="isBusy">
    <div class="bg-primary-light rounded p-3">
        <div class="d-flex flex-column justify-content-center align-items-center py-3">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<div *ngIf="!isBusy" class="bg-primary-light rounded p-3">
    <div class="row no-gutters flex-nowrap">
        <div class="col-auto d-flex flex-fill align-items-start">
            <div class="d-flex align-items-center">
                <mat-icon mat-list-icon color="primary">person</mat-icon>
                <span>{{dossierSpecificUser.user.fullName}}</span>
            </div>
        </div>
        <div class="col-auto d-flex align-items-start pr-2">
            <span [class.invisible]="dossier.nbNotifications.total == 0"
                  class="d-inline-flex badge justify-content-center align-items-center mt-1">
                {{dossier.nbNotifications.total}}
            </span>
        </div>
        <div class="col-auto d-flex flex-column align-items-start">
            <div *ngIf="dossier.diffuseDate" matTooltip="Date de diffusion" matTooltipPosition="left"
                 class="d-flex align-items-center fz-14 cursor-help">
                <mat-icon>today</mat-icon>
                <span class="ml-1">{{dossier.diffuseDate | amCalendar}}</span>
            </div>
            <div *ngIf="dossier.dateSpecificResponded" matTooltip="Date de réponse" matTooltipPosition="left"
                 class="d-flex align-items-center fz-14 cursor-help">
                <mat-icon>event_available</mat-icon>
                <span class="ml-1">{{dossier.dateSpecificResponded | amCalendar}}</span>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column">
        <div *ngIf="uploading" class="d-flex flex-row align-items-center order-0 mt-2">
            <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2 ml-4 ">
                <mat-icon *ngIf="!processing">publish</mat-icon>
                <mat-spinner *ngIf="processing"
                             class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
                <div class="d-flex flex-grow-1 mx-2">
                    <mat-progress-bar *ngIf="!processing" mode="determinate" [value]="progress"></mat-progress-bar>
                    <span *ngIf="processing">Traitement du fichier en cours ...</span>
                </div>
            </div>
        </div>
        <app-specific-document *ngFor="let documentHistorique of dossier.documentHistoriques; trackBy: trackBy"
                               (documentClicked)="documentClickedHandler($event)"
                               (notificationUpdated)="notificationUpdatedHandler($event)"
                               [preview]="previewIdDocumentHistorique == documentHistorique.id"
                               [documentHistorique]="documentHistorique"
                               [documentHistoriques]="dossier.documentHistoriques"
                               [selectedDocumentHistorique]="selectedDocumentHistorique" [dossier]="dossier"
                               [viewContainerRef]="viewContainerRef" class="order-1"></app-specific-document>
    </div>
    <div class="d-flex flex-row align-items-center mt-3">
        <div class="fz-16 font-weight-bold d-flex flex-fill">Réponses</div>
        <div>
            <button *ngIf="dossier.allowTo.edit" (click)="onTapDownloadAllDocument(dossierReponse)" mat-stroked-button color="primary" class="btn-rounded ml-2">
                <mat-spinner *ngIf="spinner.allDownload" class="btn-spinner"></mat-spinner>
                <mat-icon *ngIf="!spinner.allDownload">get_app</mat-icon>
                <span class="d-none d-lg-inline-block  ml-1">Tout télécharger</span>
            </button>
            <input type="file" id="file_reponse_{{dossierReponse.id}}" class="d-none" multiple (change)="handleFileInput($event.target.files, dossierReponse)">
            <label *ngIf="dossierReponse.allowTo.document" for="file_reponse_{{dossierReponse.id}}" class="d-inline-flex">
                <mat-icon matRipple [matRippleCentered]="true" matTooltip="Ajouter des documents" matTooltipPosition="left" class="icon-20">
                    file_upload
                </mat-icon>
            </label>
        </div>
    </div>
    <div class="d-flex flex-column">
        <div *ngIf="reponseUploading" class="d-flex flex-row align-items-center order-0 mt-2">
            <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2 ml-4 ">
                <mat-icon *ngIf="!reponseProcessing">publish</mat-icon>
                <mat-spinner *ngIf="reponseProcessing"
                             class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
                <div class="d-flex flex-grow-1 mx-2">
                    <mat-progress-bar *ngIf="!reponseProcessing" mode="determinate" [value]="reponseProgress"></mat-progress-bar>
                    <span *ngIf="reponseProcessing">Traitement du fichier en cours ...</span>
                </div>
            </div>
        </div>
        <app-specific-document *ngFor="let documentHistorique of dossierReponse.documentHistoriques; trackBy: trackBy"
                               (documentClicked)="documentClickedHandler($event)"
                               (notificationUpdated)="notificationUpdatedHandler($event)"
                               [preview]="previewIdDocumentHistorique == documentHistorique.id"
                               [documentHistorique]="documentHistorique"
                               [documentHistoriques]="dossierReponse.documentHistoriques"
                               [dossier]="dossierReponse" [selectedDocumentHistorique]="selectedDocumentHistorique"
                               [viewContainerRef]="viewContainerRef" class="order-1"></app-specific-document>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-end mt-3">
        <button *ngIf="dossier.allowTo.diffuse" (click)="onTapDiffuse(dossier)" [disabled]="spinner.diffuse" mat-button class="btn-dossier-action mt-2">
            <mat-spinner *ngIf="spinner.diffuse" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
            <mat-icon *ngIf="!spinner.diffuse">email</mat-icon>
            {{ dossier.diffuseDate ? 'Re-Diffuser' : 'Diffuser' }}
        </button>
<!--        <button *ngIf="dossier.allowTo.valide_ca" (click)="onTapValidCa(dossier)" [disabled]="spinner.valideCa" mat-button class="btn-dossier-action mt-2">-->
<!--            <mat-spinner *ngIf="spinner.valideCa" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>-->
<!--            <mat-icon *ngIf="!spinner.valideCa" class="text-success">done_all</mat-icon>-->
<!--            Valider-->
<!--        </button>-->
<!--        <button *ngIf="dossier.allowTo.valide_ca" (click)="onTapRefuseCa(dossier)" [disabled]="spinner.refuseCa" mat-button class="btn-dossier-action mt-2">-->
<!--            <mat-spinner *ngIf="spinner.refuseCa" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>-->
<!--            <mat-icon *ngIf="!spinner.refuseCa" class="text-danger">close</mat-icon>-->
<!--            Refuser-->
<!--        </button>-->
        <button *ngIf="dossierReponse.allowTo.valide_user_btn" (click)="onTapValidResponse(dossierReponse)" [disabled]="spinner.valideResponse" mat-button class="btn-dossier-action mt-2">
            <mat-spinner *ngIf="spinner.valideResponse" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
            <mat-icon *ngIf="!spinner.valideResponse" class="text-success">done_all</mat-icon>
            Diffuser Réponse
        </button>
    </div>
</div>
