<div *ngIf="isVisible" class="my-3">
    <div *ngIf="isBusy" class="d-flex justify-content-center align-content-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <!-- création due la vue globale de l'affaire -->
    <div *ngIf="!isBusy" class="container-fluid">
        <div class="row">
            <div class="global-view col-md-8">
                <div class="block-1 row">
                    <div class="col-md-6">
                        <!-- image affaire, s'il y en a une -->
                        <img *ngIf="affair?.imageUrl" src="{{affair.imageUrl}}" alt="image affaire {{affair.label}} - référence {{affair.reference}}" class="img-fluid rounded w-100">
                    </div>
                    <div class=" col-md-6 affair-step-info">
                        <!-- informations affaire (label, référence, adresse) -->
                        <h3 class="m-0">{{affair.label}}</h3>
                        <p class="m-0 mb-2">{{affair.reference}}</p>
                        <address class="fz-14">
                            {{affair.address}}<br *ngIf="affair.address">
                            {{affair.postalCode ? affair.postalCode + ' - ' : ''}}{{affair.city}}<br>
                            {{affair.country}}
                        </address>
                        <!-- étape affaire et sont avancement -->
                        <div class="mt-4">
                            <div *ngIf="affair.stepLabel.step" class="bg-primary text-white text-center rounded mt-auto p-2">{{affair.stepLabel.step.label}}</div>
                            <div *ngIf="affair.stepLabel.avancement" class="affair-grid-progress">
                                <div *ngIf="affair.stepLabel.avancement[3].pourcent > 0" class="bg-green"
                                    [style]="'width: ' + affair.stepLabel.avancement[3].pourcent + '%'"></div>
                                <div *ngIf="affair.stepLabel.avancement[2].pourcent > 0" class="bg-yellow"
                                    [style]="'width: ' + affair.stepLabel.avancement[2].pourcent + '%'"></div>
                                <div *ngIf="affair.stepLabel.avancement[1].pourcent > 0" class="bg-red"
                                    [style]="'width: ' + affair.stepLabel.avancement[1].pourcent + '%'"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- tableaux -->
                <div class="affair-step-tab row mt-4">
                    <!-- tableau des dates de l'affaire -->
                    <div class="col-md-6">
                        <div class="border rounded overflow-hidden bg-white">
                            <div class="bg-primary text-white py-3 px-3 text-center">Dates</div>
                            <ul class="list-unstyled m-0">
                                <li *ngFor="let date of affair.dates; let last = last; let index = index" [ngClass]="{'bg-grey': 0 === index % 2}" [class.border-bottom]="!last" class="row px-3 py-2 fz-14 lh-20">
                                    <span class="col-md-8">{{date.titre}}</span>
                                    <span class="col-md-4">{{date.date | date:'dd/MM/yyyy'}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- tableau des équipes présente sur l'affaire -->
                    <div class="col-md-6">
                        <mat-accordion cdkDropListGroup [cdkDropListGroupDisabled]="hasRoles(['ROLE_GUEST']) || !affair.allowTo.consult" [multi]="isOpenForced">
                            <mat-expansion-panel #panelAffair [expanded]="isOpenForced || isOpenDefault" hideToggle class="shadow-none border">
                                <mat-expansion-panel-header (click)="expandPanelAffair($event)" class="bg-primary">
                                    <mat-panel-title class="text-white justify-content-center my-3">
                                    Équipe Affaire
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div cdkDropList (cdkDropListDropped)="onDrop($event)" [cdkDropListData]="affair.members.affair" >
                                    <div *ngIf="!affair.members.affair.length" class="py-2 px-3 fz-14 text-danger">
                                        L'équipe affaire n'est pas encore composée.
                                    </div>
                                    <div class="px-3">
                                        <div cdkDrag *ngFor="let teamAffair of sortByUser(affair.members.affair, 'fullName'); let last = last; trackBy: trackBy"
                                             (cdkDragStarted)="onDrag($event)" [class.border-bottom]="!last"
                                             class="row no-gutters py-2 bg-white">
                                            <div class="col" cdkDragHandle [class.cursor-grab]="!hasRoles(['ROLE_GUEST']) && affair.allowTo.consult">
                                                <div class="row no-gutters">
                                                    <div class="col-6 col-md-12 col-lg-6 d-flex align-items-center fz-14">
                                                        <mat-icon class="mr-1">{{ teamAffair.user.visible ? 'person' : 'person_outline' }}</mat-icon>
                                                        {{teamAffair.user.fullName}}
                                                    </div>
                                                    <div class="col-6 col-md-12 col-lg-6 d-flex align-items-center fz-12">
                                                        <mat-icon class="mr-1">business</mat-icon>
                                                        {{teamAffair.user.labelSociety}}
                                                    </div>
                                                    <div *ngIf="teamAffair.user.trades.length" class="col-12 mt-1">
                                                        <span *ngFor="let userTrade of teamAffair.user.trades; let last = last;" class="chip fz-10 text-nowrap" [class.mr-2]="!last">
                                                            {{userTrade.label}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!hasRoles(['ROLE_GUEST']) && affair.allowTo.consult" class="col-auto d-flex align-items-center fz-12">
                                                <button mat-icon-button [matMenuTriggerFor]="menuUser" [matMenuTriggerData]="{user: teamAffair.user, list: 'affair'}">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel *ngIf="affair.allowTo.consult && !hasRoles(['ROLE_GUEST'])" #panelProjet [expanded]="isOpenForced || !isOpenDefault" hideToggle class="shadow-none rounded border">
                                <mat-expansion-panel-header (click)="expandPanelProjet($event)" class="bg-primary">
                                    <mat-panel-title class="text-white justify-content-center my-3">Personnes consultées</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div cdkDropList (cdkDropListDropped)="onDrop($event)" [cdkDropListData]="affair.members.projet">
                                    <div *ngIf="!affair.members.projet.length" class="py-2 px-3">
                                        <span class=" fz-14 text-danger">L'équipe projet n'est pas encore composée.</span>
                                    </div>
                                    <div class="px-3">
                                        <div cdkDrag [cdkDragDisabled]="!teamProjet.user.visible" *ngFor="let teamProjet of sortByUser(affair.members.projet, 'fullName'); let last = last; trackBy: trackBy"
                                             (cdkDragStarted)="onDrag($event)" [class.border-bottom]="!last"
                                             class="row no-gutters py-2 bg-white">
                                            <div class="col" cdkDragHandle [class.cursor-grab]="!hasRoles(['ROLE_GUEST']) && affair.allowTo.consult && teamProjet.user.visible">
                                                <div class="row no-gutters">
                                                    <div class="col d-flex align-items-center fz-14">
                                                        <mat-icon class="mr-1">{{ teamProjet.user.visible ? 'person' : 'person_outline' }}</mat-icon>
                                                        {{teamProjet.user.fullName}}
                                                    </div>
                                                    <div class="col d-flex align-items-center fz-12">
                                                        <mat-icon class="mr-1">business</mat-icon>
                                                        {{teamProjet.user.labelSociety}}
                                                    </div>
                                                    <div *ngIf="teamProjet.user.trades.length" class="col-12 mt-1">
                                                        <span *ngFor="let userTrade of teamProjet.user.trades; let last = last;" class="chip fz-10 text-nowrap" [class.mr-2]="!last">
                                                            {{userTrade.label}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!hasRoles(['ROLE_GUEST']) && affair.allowTo.consult" class="col-auto d-flex align-items-center fz-12">
                                                <button mat-icon-button [matMenuTriggerFor]="menuUser" [matMenuTriggerData]="{user: teamProjet.user, list: 'projet'}">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <mat-menu #menuUser="matMenu" xPosition="before">
                            <ng-template matMenuContent let-user="user" let-list="list">
                                <button *ngIf="!user.visible" mat-menu-item (click)="onTapEditToUser(user, list)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Transformer</span>
                                </button>
                                <button mat-menu-item (click)="onTapEdit(user, list)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Modifier</span>
                                </button>
                            </ng-template>
                        </mat-menu>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <!-- tableau des actualités sur l'affaire -->
                <div>
                    <div class="border rounded overflow-hidden bg-white">
                        <div class="bg-primary text-white py-3 px-3 text-center">Actualités</div>
                        <ul class="list-unstyled m-0">
                            <!-- <li class="py-2 px-3 fz-14 lh-20 text-danger" *ngIf="actualite == ''">Aucune actualité pour l'instant.</li> -->
                            <li class="py-2 px-3 fz-14 lh-20 border-bottom">
                                <h3 class="m-0 my-2">Titre Article.</h3>
                                <span>
                                    <img *ngIf="affair?.imageUrl" src="{{affair?.imageUrl}}" alt="image affaire {{affair?.label}} - référence {{affair?.reference}}" class="w-100">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, excepturi perferendis. Saepe nesciunt, assumenda placeat quas dignissimos in ad fugit dolores blanditiis recusandae? Aut nostrum rerum dolor facere aspernatur ut.
                                </span>
                                <legend class="m-0 p-0 mt-2 pt-2 border-top fz-12">publié le 16/03/2021, par Anonymous</legend>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
