import {Component} from '@angular/core';
import {AppComponent} from '@src/app/app.component';
import {User} from '@src/app/model/user.model';
import {Router} from '@angular/router';
import {AuthService} from '@src/app/services/auth.service';
import {StorageService} from '@src/app/services/storage.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {SharedService} from '@src/app/services/shared.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.web.html',
    styleUrls: ['./login.component.web.scss'],
    providers: [SharedService, StorageService]
})
export class LoginComponent extends BaseComponent {
    title = 'Connexion';
    mode = 'over';
    backdrop = true;
    user: User;
    isAuthenticating = false;

    constructor(
        private appComponent: AppComponent,
        private router: Router,
        private authService: AuthService,
        // private storageService: StorageService,
    ) {
        super();
        this.user = new User();
        this.user.email = '';
        // this.user.email = 'nicolas.kgcom@gmail.com';
        this.user.password = '';
        // this.user.password = 'nicolas';
    }

    submit() {
        if (!this.user.email) {
            alert('Entrer une adresses email valide.');
            return;
        }

        this.isAuthenticating = true;
        this.login();
    }

    login() {
        this.authService.login(this.user).subscribe(
            (result) => {
                this.storageService.afterLoginSave(result, this.user);
                this.appComponent.initiale = result.user.initiale;
                this.appComponent.connected = true;
                this.appComponent.roles = result.user.roles;
                // this.sharedService.updateRoles(result.user.roles);
                this.router.navigate(['home']);
            },
            (error) => {
                alert('Malheureusement, nous n\'avons pas pu trouver votre compte.');
                this.isAuthenticating = false;
            }
        );
    }
}
