<h1 mat-dialog-title >
    <span class="d-inline-block mt-1">Ajouter des fichiers</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div *ngIf="!isLoading" mat-dialog-actions class="justify-content-start py-0 mb-0">
    <label for="file_modal" class="d-inline-flex align-items-center cursor-pointer">
        <mat-icon class="icon-20 text-primary">file_upload</mat-icon>
        <span class="text-primary ml-2">Ajouter des documents</span>
    </label>
    <input type="file" id="file_modal" class="d-none" multiple (change)="handleFileInput($event.target.files)">
</div>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <div *ngIf="uploading" class="d-flex flex-row align-items-center order-0 mt-2">
            <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2">
                <mat-icon *ngIf="!processing">publish</mat-icon>
                <mat-spinner *ngIf="processing" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
                <div class="d-flex flex-grow-1 mx-2">
                    <mat-progress-bar *ngIf="!processing" mode="determinate" [value]="progress"></mat-progress-bar>
                    <span *ngIf="processing">Traitement des documents en cours ...</span>
                </div>
            </div>
        </div>
        <mat-list>
            <mat-list-item *ngFor="let documentHistorique of dossier.documentHistoriques; trackBy: trackByFn;">
                <mat-icon mat-list-icon>note</mat-icon>
                <div mat-line>{{documentHistorique.name}}</div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div *ngIf="!isLoading" mat-dialog-actions class="justify-content-end pb-3">
    <button [mat-dialog-close]="dossier" mat-raised-button color="primary" type="submit">
        Suivant
        <mat-icon>navigate_next</mat-icon>
    </button>
</div>
