import {Injectable, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {BottomSheetActionOptions} from '@src/app/shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class ActionBottomSheetService {

    // TODO on peux normalement le supprimer car plus utilisé coté web
    public showBottomSheet(type: string, data: any, options: BottomSheetActionOptions, vcRef: ViewContainerRef): Observable<any> {
        // Nothing here
        return new Observable(subscriber => {
            subscriber.next('');
        });
    }
}
