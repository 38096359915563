import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AnnuaireNavItems, AnnuaireViewsItems, SocietiesResult, UsersResult} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode, MatSidenav} from '@angular/material/sidenav';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '@src/app/services/modal.service';

@Component({
    selector: 'app-annuaire',
    templateUrl: './annuaire.component.html',
    styleUrls: ['./annuaire.component.scss'],
})
export class AnnuaireComponent extends BaseComponent implements OnInit {
    title = 'Annuaires';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy = true;
    views: AnnuaireViewsItems;
    back: any[];
    querySearch: any;
    items: AnnuaireNavItems = {
        back: false,
        state: false,
        views: true,
        add: true,
        filter: true,
    };
    usersResult$: Observable<UsersResult>;
    societiesResult$: Observable<SocietiesResult>;
    isUserFilterActif: boolean;
    isSocietyFilterActif: boolean;

    @ViewChild('filter') filter: MatSidenav;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private activatedRoute: ActivatedRoute,
        private modalService: ModalService,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            if (params['view']) {
                this.views = {
                    isSociety: params['view'] === 'society',
                    isUser: params['view'] === 'user',
                };
            } else {
                this.views = {
                    isSociety: false,
                    isUser: true,
                };
            }
        });

        this.isUserFilterActif = false;
        this.isSocietyFilterActif = false;
        this.getFilterState();

        this.items.back = !this.hasRoles(['ROLE_GUEST']);
    }

    viewHandler(views: AnnuaireViewsItems): void {
        this.views = views;
    }

    filterHandler(): void {
        this.filter.open();
    }

    modalFilterHandler(type: string): void {
        if (type === 'user') {
            this.modalService.show(
                { type: 'annuaire-user-filter' },
                'base-modal',
                this.viewContainerRef,
                true
            ).subscribe((usersResult$: Observable<UsersResult>) => {
                if (usersResult$) { this.userFilterHandler(usersResult$); }
            });
        }
        if (type === 'society') {
            this.modalService.show(
                { type: 'annuaire-society-filter' },
                'base-modal',
                this.viewContainerRef,
                true
            ).subscribe((societiesResult$: Observable<SocietiesResult>) => {
                if (societiesResult$) { this.societyFilterHandler(societiesResult$); }
            });
        }
    }

    getFilterState(): void {
        const storageUserFilter = this.storageService.userListFilter;
        const storageSocietyFilter = this.storageService.societyListFilter;
        // tslint:disable-next-line:max-line-length
        this.isUserFilterActif = !!(storageUserFilter && (storageUserFilter.name || storageUserFilter.society || storageUserFilter.trade || storageUserFilter.visible));
        // tslint:disable-next-line:max-line-length
        this.isSocietyFilterActif = !!(storageSocietyFilter && (storageSocietyFilter.name || storageSocietyFilter.user));
    }

    userFilterHandler(usersResult$: Observable<UsersResult>): void {
        this.isBusy = true;
        this.getFilterState();
        this.usersResult$ = usersResult$;
    }

    societyFilterHandler(societiesResult$: Observable<SocietiesResult>): void {
        this.isBusy = true;
        this.getFilterState();
        this.societiesResult$ = societiesResult$;
    }
}
