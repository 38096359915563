<div class="px-3">
    <div class="activity-item d-flex flex-row" [routerLink]="['/affair-dossier', data.dossier.id]"
         [queryParams]="{_d: data.document.id}">
        <div class="activity-item-avatar">
            <span>{{ data.user.initiale }}</span>
        </div>
        <div>
            <div class="activity-item-fullname">{{ data.user.fullName }} a ajouter un commentaire :</div>
            <div class="bg-white rounded p-2 my-1">{{data.message}}</div>
            <div class="activity-item-datetime">{{ data.datetime | amCalendar }}</div>
        </div>
    </div>
</div>
