import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ApiService} from '@src/app/services/api.service';
import {Affair, AffairMember} from '@src/app/model/affair.model';
import {ActionResult, SimpleChanges} from '@src/app/shared/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {ToastService} from '@src/app/services/toast.service';
import {ModalService} from '@src/app/services/modal.service';
import * as moment from 'moment';
import {MatExpansionPanel} from '@angular/material/expansion';
import {CdkDragDrop, CdkDragStart, transferArrayItem} from '@angular/cdk/drag-drop';
import {BaseComponent} from '@src/app/shared/base.component';
import {User} from '@src/app/model/user.model';

@Component({
    selector: 'app-step-global',
    templateUrl: './step-global.component.html',
    styleUrls: ['./step-global.component.scss'],
})

export class StepGlobalComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

    @Input() isVisible: boolean;
    @Input() viewContainerRef: ViewContainerRef;
    isBusy: boolean;
    affair: Affair;
    affairId: number;
    actualite = [];
    isOpenDefault: boolean;
    isOpenForced: boolean;
    isMemberUpdate: boolean;

    @ViewChild('panelAffair') panelAffair: MatExpansionPanel;
    @ViewChild('panelProjet') panelProjet: MatExpansionPanel;

    public constructor(
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toggleBottomSheetService: ActionBottomSheetService,
        private toastService: ToastService,
        private modalService: ModalService,
    ) {
        super();
    }

    ngOnInit() {
        this.isBusy = true;
        this.isOpenDefault = true;
        this.isOpenForced = false;
        this.isMemberUpdate = false;
        this.activatedRoute.params.subscribe((params) => {
            this.affairId = parseInt(params['id'], 10);
            if (this.isVisible) { this.getAffair(); }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.isVisible.currentValue
            && !changes.isVisible.previousValue
            && typeof changes.isVisible.previousValue !== 'undefined'
        ) {
            this.isBusy = true;
            this.getAffair();
        }
    }

    getAffair() {
        this.apiService.getAffairGlobal(this.affairId).subscribe((affair) => {
            this.affair = affair;
            this.isBusy = false;
        });
    }

    expandPanelAffair($event) {
        $event.stopPropagation();

        if (this.isOpenDefault) {
            this.panelAffair.open();
        } else {
            this.isOpenDefault = !this.isOpenDefault;
        }
    }

    expandPanelProjet($event) {
        $event.stopPropagation();

        if (!this.isOpenDefault) {
            this.panelAffair.open();
            this.panelProjet.close();
        }
        this.isOpenDefault = !this.isOpenDefault;
    }

    onDrop(event: CdkDragDrop<AffairMember[]>): void {
        if (event.previousContainer !== event.container) {
            const member = event.previousContainer.data[event.previousIndex];
            const newStatus = member.status === 1 ? 2 : 1;
            this.apiService.updateAffairUserStatus(this.affair.id, member.user.id, newStatus).subscribe((result: ActionResult) => {
               if (result.success) {
                   this.isOpenForced = false;
                   event.previousContainer.data[event.previousIndex].status = newStatus;
                   transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
               }
            });
        } else {
            this.isOpenForced = false;
        }
    }

    onDrag(event: CdkDragStart): void {
        this.isOpenForced = true;
    }

    // 1 => Projet, 2 => Affair
    onTapMember(member: AffairMember): void {
        this.isMemberUpdate = true;
        const newStatus = member.status === 1 ? 2 : 1;
        this.apiService.updateAffairUserStatus(this.affair.id, member.user.id, newStatus).subscribe((result: ActionResult) => {
            if (result.success) {
                member.status = newStatus;
                if (newStatus === 1) {
                    this.affair.members.affair = this.affair.members.affair.filter(p => p.user.id !== member.user.id);
                    this.affair.members.projet.push(member);
                } else {
                    this.affair.members.projet = this.affair.members.projet.filter(p => p.user.id !== member.user.id);
                    this.affair.members.affair.push(member);
                }
                this.isMemberUpdate = false;
            }
        });
    }

    onTapEditToUser(user: User, list: string) {
        this.modalService.show(
            {user: user},
            'user',
            null,
            false
        ).subscribe((result: User) => {
            if (result) {
                this.affair.members[list].map((am: AffairMember) => {
                    if (am.user.id === result.id) {
                        am.user = result;
                        am.user.labelSociety = result.contact.society.name;
                    }
                    return am;
                });
            }
        });
    }

    onTapEdit(user: User, list: string) {
        this.modalService.show(
            {user: user},
            user.visible ? 'user' : 'guest',
            null,
            false
        ).subscribe((result: User) => {
            if (result) {
                this.affair.members[list].map((am: AffairMember) => {
                    if (am.user.id === result.id) {
                        am.user = result;
                        am.user.labelSociety = result.contact.society.name;
                    }
                    return am;
                });
            }
        });
    }

    convertDate(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }

    trackByFn(index, item) {
        return index;
    }

    trackBy(index, item) {
        return item;
    }

    trackByUser(index, item) {
        return item.user.fullName;
    }

    sortByUser(objects: any[], prop: string) {
        return objects.sort((a, b) => a.user[prop].toLowerCase() > b.user[prop].toLowerCase() ? 1 : a.user[prop] === b.user[prop] ? 0 : -1);
    }

}
