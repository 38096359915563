<div class="d-flex flex-column bg-primary-light position-relative rounded overflow-hidden h-100">
    <div *ngIf="affair.nbDiscutions > 0" class="list-affair-notification">
        <i class="far fa-comment"></i> {{affair.nbDiscutions}}
    </div>
    <!-- header du bloque affair -->
    <div class="d-flex justify-content-between align-items-start ">
        <a [routerLink]="isActionBusy ? null : ['/affaire', affair.id]" matRipple [matRippleDisabled]="isActionBusy"
           class="d-flex flex-row flex-fill justify-content-between pl-3 pt-3 pb-2 has-ripple has-ripple-dark text-decoration-none text-reset">
            <span class="fz-18 font-weight-bold" [class.text-muted]="isActionBusy">{{affair.label}}</span>
        </a>
        <button *ngIf="affair.isChargeAffair" [disabled]="isActionBusy" mat-icon-button [matMenuTriggerFor]="editMenu" [matMenuTriggerData]="{affair: affair}"
                class="align-self-start mt-2">
            <mat-spinner *ngIf="isActionBusy" class="btn-spinner"></mat-spinner>
            <mat-icon *ngIf="!isActionBusy">more_vert</mat-icon>
        </button>
    </div>

    <a [routerLink]="isActionBusy ? null : ['/affaire', affair.id]" matRipple [matRippleDisabled]="isActionBusy"
       class="d-flex flex-column flex-fill justify-content-end flex-wrap text-left cursor-pointer px-3 py-2 has-ripple has-ripple-dark text-decoration-none text-reset">
        <div *ngIf="affair.reference">• Ref: {{affair.reference}}</div>
        <div *ngIf="affair.stepLabel.step" [class.text-muted]="isActionBusy" class="bg-white text-center rounded mt-2 list-affair-step-list p-2">{{affair.stepLabel.step.label}}</div>
        <div *ngIf="affair.stepLabel.avancement" class="affair-grid-progress">
            <div *ngIf="affair.stepLabel.avancement[3].pourcent > 0" class="bg-green"
                 [style]="'width: ' + affair.stepLabel.avancement[3].pourcent + '%'"></div>
            <div *ngIf="affair.stepLabel.avancement[2].pourcent > 0" class="bg-yellow"
                 [style]="'width: ' + affair.stepLabel.avancement[2].pourcent + '%'"></div>
            <div *ngIf="affair.stepLabel.avancement[1].pourcent > 0" class="bg-red"
                 [style]="'width: ' + affair.stepLabel.avancement[1].pourcent + '%'"></div>
        </div>
    </a>
</div>

<mat-menu #editMenu="matMenu" xPosition="before">
    <ng-template matMenuContent let-affair="affair">
        <a [routerLink]="['/affair-form', affair.id]" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Éditer</span>
        </a>
        <button (click)="onTapDelete(affair)" mat-menu-item color="warn">
            <mat-icon class="text-danger">delete_outline</mat-icon>
            <span>Supprimer</span>
        </button>
    </ng-template>
</mat-menu>
