import {Component, Inject, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {Dossier} from '@src/app/model/dossier.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '@src/app/services/api.service';
import {takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {DossierService} from '@src/app/services/dossier.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {DocumentHistorique} from '@src/app/model/document-historique.model';

@Component({
    selector: 'app-modal-shortcut',
    templateUrl: 'modal-shortcut-component.web.html',
    styleUrls: ['modal-shortcut-component.web.scss'],
})
export class ModalShortcutComponent implements OnInit, OnDestroy {

    notifier = new Subject();
    dossier: Dossier;
    shortcuts: Dossier[];
    isLoading: boolean;
    isSaving: boolean;
    formControlObj: FormControl;
    selectedShortcuts: any[];
    selectedShortcutsFavoris: {} = {};

    constructor(
        public dialogRef: MatDialogRef<ModalShortcutComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {dossier: Dossier, light: boolean},
        private apiService: ApiService,
        private dossierService: DossierService,
        public viewContainerRef: ViewContainerRef,
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.isSaving = false;
        this.dossier = this.data.dossier;
        this.selectedShortcuts = this.dossier.shortcuts;

        this.apiService.getDossierShortcut(this.dossier.id)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossiers: Dossier[]) => {
                this.shortcuts = dossiers;
                for (const shortcut of this.selectedShortcuts) {
                    this.selectedShortcutsFavoris[shortcut.id] = this.shortcuts.find(s => s.id === shortcut.id).favoris.map(f => f.id);
                }
                this.formControlObj = new FormControl(this.selectedShortcuts);
                this.formControlObj.valueChanges
                    .pipe(takeUntil(this.notifier))
                    .subscribe(value => {
                        this.selectedShortcuts = value;
                    });
                this.isLoading = false;
            });
    }

    valueChanges(event: MatCheckboxChange, shortcut: Dossier) {
        if (event.checked) {
            this.selectedShortcutsFavoris[shortcut.id] = [];
        } else {
            delete this.selectedShortcutsFavoris[shortcut.id];
        }
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    onClose(args = null) {
        this.dialogRef.close(args);
    }

    onTapShortcut(shortcut: Dossier): void {
        if (!!this.selectedShortcutsFavoris[shortcut.id]) {
            this.dossierService.favori(shortcut, this.dossier, false, this.viewContainerRef)
                .subscribe((result: DocumentHistorique[]) => {
                    if (result) { this.selectedShortcutsFavoris[shortcut.id] = result.map(r => r.id); }
                });
        }

    }

    onSubmit(): void {
        this.isSaving = true;
        if (this.formControlObj.valid) {
            this.isSaving = true;
            this.apiService.postDossierShortcut(this.dossier.id, {dossiers: this.selectedShortcutsFavoris, light: this.data.light})
                .pipe(takeUntil(this.notifier))
                .subscribe((dossier) => {
                    this.isSaving = false;
                    if (dossier) { this.onClose(dossier); }
                }, () => this.isSaving = false);
        }
    }

    trackBy(index, item) {
        return item.id;
    }

    sortBy(prop: string) {
        return this.shortcuts.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }
}
