import {Injectable, ViewContainerRef} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HomeModalService {

    constructor() {
    }

    public show(viewContainerRef: ViewContainerRef) {
        // only webapp
    }

}
