<div class="d-flex flex-column">
    <!---------- barre de navigation principale ---------->
    <app-affair-nav [dossier]="dossier" [back]="back" [backQueryParams]="backQueryParams" [title]="title" [querySearch]="querySearch" [isBusy]="isBusy" [items]="items"
                    class="d-flex flex-row justify-content-between align-items-center bg-primary-light rounded-bottom p-3 mb-3">
    </app-affair-nav>

    <!---------- animation de chargement ---------->
    <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>

    <!---------- conteneur principal ---------->
    <div *ngIf="!isBusy" class="container-fluid">
        <!---------- titre et actions de demandes de chiffrages ---------->
        <div class="d-flex flex-row justify-content-between align-items-center">
            <span class="fz-18">Demandes de chiffrages :</span>
            <div class="legend-types">
                <div class="legend-type legend-type-1">Non diffusé</div>
                <div class="legend-type legend-type-2">Diffusé</div>
                <div class="legend-type legend-type-3">Répondu</div>
            </div>
            <div class="d-flex flex-row align-items-center">
                <mat-icon *ngIf="dossier.allowTo.edit && !hasRoles(['ROLE_GUEST'])" (click)="onTapAddDossier(dossier)"
                          matTooltip="Ajouter un dossier" matTooltipPosition="above"
                          matRipple [matRippleCentered]="true" class="icon-20 ml-2">
                    create_new_folder
                </mat-icon>
                <button (click)="onTapDownloadAllDocument()" mat-stroked-button color="primary" class="btn-rounded ml-3">
                    <mat-spinner *ngIf="spinner.allDownload" class="btn-spinner"></mat-spinner>
                    <mat-icon *ngIf="!spinner.allDownload">get_app</mat-icon>
                    <span class="d-none d-lg-inline-block ml-1">Tout télécharger</span>
                </button>
            </div>
        </div>

        <!--------- Bloque comprenant les différentes demandes de chiffrages --------->
        <div class="row">
            <app-specific-item *ngFor="let specific of dossier.specificList; trackBy: trackBy;"
                               (deleteEmitter)="specificDeleteHandler($event)"
                               [viewContainerRef]="viewContainerRef" [specific]="specific" [dossier]="dossier"
                               class="col-md-6 col-lg-4 col-xl-3 mt-3">
            </app-specific-item>
        </div>
    </div>
</div>
