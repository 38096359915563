<div class="d-flex flex-row flex-fill align-items-center">
    <a mat-button class="btn-rounded has-ripple" routerLink="/home">
        <mat-icon color="primary">arrow_back_ios</mat-icon>
        <span class="fz-18 d-none d-lg-inline-block">Tableau de bord</span>
    </a>
    <button (click)="onTapView('user')" [class.active]="views.isUser" mat-icon-button color="primary"
            matTooltip="Utilisateurs" matTooltipPosition="above" class="ml-2">
        <mat-icon>people_alt</mat-icon>
    </button>
    <button (click)="onTapView('society')" [class.active]="views.isSociety" mat-icon-button color="primary"
            matTooltip="Sociétés" matTooltipPosition="above" class="ml-2">
        <mat-icon>business</mat-icon>
    </button>
    <button *ngIf="!hasRoles(['ROLE_GUEST']) && views.isUser" (click)="onTapAddUser()" mat-stroked-button color="primary" class="btn-rounded ml-2">
        <mat-icon>person_add</mat-icon>
        <span class="d-none d-lg-inline-block ml-1">Ajouter un utilisateur</span>
    </button>
    <button *ngIf="!hasRoles(['ROLE_GUEST']) && views.isSociety" (click)="onTapAddSociety()" mat-stroked-button color="primary" class="btn-rounded ml-2">
        <mat-icon>add</mat-icon>
        <span class="d-none d-lg-inline-block ml-1">Ajouter une société</span>
    </button>
    <button (click)="onTapFilter()" [class.active]="(views.isUser && isUserFilterActif) || (views.isSociety && isSocietyFilterActif)" mat-icon-button color="primary" class="ml-auto">
        <mat-icon>filter_alt</mat-icon>
    </button>
</div>
