<div class="d-flex flex-row align-items-center mt-2">
    <div class="d-flex flex-row flex-fill align-items-center bg-white rounded overflow-hidden">
        <div matRipple [matRippleDisabled]="disabled" class="has-ripple d-flex flex-row flex-fill align-items-center align-self-stretch pl-2">
            <div (click)="toggleAffichage()" class="d-flex flex-row align-items-center flex-fill cursor-pointer">
                <mat-icon>{{isShortcut ? 'drive_file_move' : 'folder'}}</mat-icon>
                <span class="d-flex mx-2">{{dossier.label}}</span>
            </div>

            <div class="d-flex flex-row justify-content-start align-items-center p-2">
                <span><i class="far fa-file mr-1"></i>{{dossier.nbDocuments}}</span>
                <span class="ml-2" [ngClass]="{'text-danger': dossier.nbDiscutions > 0}"><i class="far fa-comment mr-1"></i>{{dossier.nbDiscutions}}</span>
                <span *ngIf="!isShortcut && dossier.societies.length && dossier.allowTo.societies"
                      [matTooltipDisabled]="disabled" [matTooltip]="concatTooltip(dossier.users)"
                      class="ml-2 d-inline-flex flex-row align-items-center" matTooltipPosition="above">
                    <mat-icon class="mr-1">people</mat-icon>{{dossier.societies.length}}
                </span>
            </div>
        </div>

        <button *ngIf="!isShortcut && dossier.allowTo.edit" [disabled]="disabled" [matMenuTriggerFor]="dossierMenu" [matMenuTriggerData]="{dossier: dossier}" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
        </button>
        <button *ngIf="isShortcut && dossier.allowTo.edit" [disabled]="disabled" [matMenuTriggerFor]="shortcutMenu" [matMenuTriggerData]="{dossier: dossier}" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</div>
<input type="file" id="file_dossier_{{dossier.id}}" class="d-none" multiple (change)="handleFileInput($event.target.files)">
<div *ngIf="uploading" class="d-flex flex-row align-items-center mt-2">
    <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2 ml-4 ">
        <mat-icon *ngIf="!processing">publish</mat-icon>
        <mat-spinner *ngIf="processing"
                     class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <div class="d-flex flex-grow-1 mx-2">
            <mat-progress-bar *ngIf="!processing" mode="determinate" [value]="progress"></mat-progress-bar>
            <span *ngIf="processing">Traitement du fichier en cours ...</span>
        </div>
    </div>
</div>
<div *ngIf="!disabled && isOpen">
    <p *ngIf="!uploading && !dossier.documentHistoriques.length" class="no-result d-flex justify-content-center align-items-center text-danger">
        <mat-icon>report</mat-icon> Aucun document n'a été ajouté dans ce dossier.
    </p>
    <div class="d-flex flex-row align-items-center">
        <button *ngIf="!isShortcut && dossier.allowTo.diffuse" (click)="onTapDiffuse()" [disabled]="spinner.diffuse" mat-button
                class="btn-dossier-action mt-2">
            <mat-spinner *ngIf="spinner.diffuse" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
            <mat-icon *ngIf="!spinner.diffuse">email</mat-icon>
            Diffuser
        </button>
        <div *ngIf="!isShortcut && dossier.allowTo.view_diffuse_date" class="btn-dossier-action px-3 mt-2">Diffusé
            le {{ formatDate(dossier.diffuseDate, 'DD/MM/YYYY hh:mm') }}</div>
        <div *ngIf="!isShortcut && dossier.allowTo.diffuse_nb" class="btn-dossier-action px-3 mt-2"
             matTooltip="Réponse validé / Sociétés solicité" matTooltipPosition="above">
            {{ dossier.validatedResponses}} / {{ dossier.nbSolicitedSocities }}
        </div>
        <div *ngIf="!isShortcut && dossier.allowTo.valide_ca && dossier.specificResponse && dossier.specificResponded && !dossier.specificCAResponded">
            <button (click)="onTapValidCa()" [disabled]="spinner.valideCa" mat-button class="btn-dossier-action mt-2">
                <mat-spinner *ngIf="spinner.valideCa" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
                <mat-icon *ngIf="!spinner.valideCa" class="text-success">done_all</mat-icon>
                Valider
            </button>
            <button (click)="onTapRefuseCa()" [disabled]="spinner.refuseCa" mat-button class="btn-dossier-action mt-2">
                <mat-spinner *ngIf="spinner.refuseCa" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
                <mat-icon *ngIf="!spinner.refuseCa" class="text-danger">close</mat-icon>
                Refuser
            </button>
        </div>
        <div *ngIf="!isShortcut && dossier.allowTo.valide_ca && dossier.specificResponse && dossier.specificResponded && dossier.specificCAResponded">
            <div *ngIf="dossier.caResponse > 1" class="mat-button btn-dossier-action cursor-help px-3 mt-2"
                 [matTooltip]="'Validé le ' + formatDate(dossier.dateSpecificCAResponded)" matTooltipPosition="above">
                <span class="mat-button-wrapper">
                    <mat-icon class="text-success">check_circle</mat-icon>
                    {{ formatDate(dossier.dateSpecificCAResponded) }}
                </span>
            </div>
            <div *ngIf="dossier.caResponse <= 1" class="mat-button btn-dossier-action cursor-help px-3 mt-2"
                 [matTooltip]="'Refusé le' + formatDate(dossier.dateSpecificCAResponded)" matTooltipPosition="above">
                <span class="mat-button-wrapper">
                    <mat-icon class="text-danger">cancel</mat-icon>
                    {{ formatDate(dossier.dateSpecificCAResponded) }}
                </span>
            </div>
        </div>
        <div *ngIf="!isShortcut && dossier.allowTo.valide_ca" class="btn-dossier-action px-3 mt-2">
            Répondu le {{ formatDate(dossier.dateSpecificResponded) }}
        </div>

        <div *ngIf="!isShortcut && dossier.allowTo.valide_user">
            <div *ngIf="dossier.caResponse == 3" class="btn-dossier-action px-3 mt-2">
                Validé le {{ formatDate(dossier.dateSpecificCAResponded) }}
            </div>
            <div *ngIf="dossier.caResponse == 1" class="btn-dossier-action px-3 mt-2">
                Refusé le {{ formatDate(dossier.dateSpecificCAResponded) }}
            </div>
            <div *ngIf="dossier.caResponse != 3 && dossier.caResponse != 1" class="btn-dossier-action px-3 mt-2">
                En cours d'analyse
            </div>
        </div>
        <button *ngIf="!isShortcut && dossier.allowTo.valide_user_btn" (click)="onTapValidResponse()"
                [disabled]="spinner.valideResponse" mat-button class="btn-dossier-action mt-2">
            <mat-spinner *ngIf="spinner.valideResponse" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
            <mat-icon *ngIf="!spinner.valideResponse">done_all</mat-icon>
            Valider
        </button>
        <div *ngIf="!isShortcut && dossier.allowTo.valide_user_date" class="btn-dossier-action px-3 mt-2">
            Répondu le {{ formatDate(dossier.dateSpecificResponded) }}
        </div>
    </div>
    <app-specific-document *ngFor="let documentHistorique of dossier.documentHistoriques"
                  [documentHistorique]="documentHistorique"
                  [documentHistoriques]="dossier.documentHistoriques"
                  [selectedDocumentHistorique]="selectedDocumentHistorique"
                  [dossier]="dossier" [isShortcut]="isShortcut"
                  [viewContainerRef]="viewContainerRef"></app-specific-document>
</div>

<mat-menu #dossierMenu="matMenu" xPosition="before">
    <ng-template matMenuContent let-dossier="dossier">
        <button mat-menu-item (click)="onTapEdit()">
            <mat-icon>edit</mat-icon>
            <span>Modifier</span>
        </button>
        <label *ngIf="dossier.allowTo.document" mat-menu-item for="file_dossier_{{dossier.id}}">
            <mat-icon>file_upload</mat-icon>
            <span>Ajouter des documents</span>
        </label>
        <button *ngIf="dossier.isSpecificDossier" mat-menu-item (click)="onTapVersion()">
            <mat-icon>create_new_folder</mat-icon>
            <span>Nouvelle version</span>
        </button>
        <button *ngIf="dossier.allowTo.remove" mat-menu-item (click)="onTapDelete()">
            <mat-icon class="text-danger">delete_outline</mat-icon>
            <span>Supprimer</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #shortcutMenu="matMenu" xPosition="before">
    <ng-template matMenuContent let-dossier="dossier">
        <button mat-menu-item (click)="onTapFavori()">
            <mat-icon>star</mat-icon>
            <span>Favoris</span>
        </button>
    </ng-template>
</mat-menu>
