import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CalendarDateFormatter, CalendarEvent} from 'angular-calendar';
import {CustomDateFormatter} from '@src/app/home/calendar/CustomDateFormatter/CustomDateFormatter';
import {ApiService} from '@src/app/services/api.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {Planning, PlanningsList} from '@src/app/shared/interfaces';

@Component({
    selector: 'app-planning',
    templateUrl: 'planning.component.web.html',
    styleUrls: ['planning.component.web.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
    }]
})


export class PlanningComponent implements OnInit, OnDestroy {

    today: Date = new Date();
    todayFormattedDate: string;
    events: CalendarEvent[] = [];
    agendas: PlanningsList[] = [];
    private subscription: Subscription;

    constructor(private apiService: ApiService) {
    }

    ngOnInit(): void {
        this.todayFormattedDate = moment(this.today).locale('fr').format('dddd Do MMMM YYYY');
        this.subscription = this.apiService.getPlanning().subscribe((data: Planning) => {
            this.agendas = data.planning;
            const planning = [];
            data.planning.map((item: PlanningsList) => {
                const obj: CalendarEvent = {
                    start: new Date(item.date),
                    title: item.affair.label + '<br>' + item.detail,
                };
                planning.push(obj);
            });
            this.events = planning;
        });
    }

    getDayFromDate(date: string): string {
        return moment(date).format('D');
    }

    getMonthFromDate(date: string): string {
        return moment(date).locale('fr').format('MMM');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
