<form [formGroup]="filterForm">
    <mat-form-field appearance="outline" class="input-filter filter-search p-0">
        <mat-label>Saisir ma recherche</mat-label>
        <span matPrefix>
            <mat-icon>search</mat-icon>
        </span>
        <input matInput
                type="text"
                placeholder="Saisir ma recherche"
                formControlName="name">
    </mat-form-field>

    <mat-form-field class="input-filter filter-states p-0" appearance="outline">
        <mat-label>Corps d'état</mat-label>
        <mat-select formControlName="trade">
            <mat-option>Sélectionner un corps d'état</mat-option>
            <mat-option *ngFor="let option of optionsTrades$ | async" [value]="option.id">
                {{option.label}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="hasRoles(['ROLE_AFFAIRE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])" class="input-filter filter-users p-0" appearance="outline">
        <mat-label>Entreprise</mat-label>
        <mat-select formControlName="society">
            <mat-option>Sélectionner une entreprise</mat-option>
            <mat-option *ngFor="let option of optionsSocieties$ | async" [value]="option.id">
                {{option.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="mt-3">
        <mat-checkbox formControlName="visible" color="primary">Compte</mat-checkbox>
    </div>

    <div class="d-flex justify-content-end pt-3">
        <button (click)="onReset()" mat-flat-button color="warn">
            <mat-icon>clear</mat-icon>
            <span>Réinitialiser</span>
        </button>
    </div>
</form>
