<div *ngIf="user" class="bg-primary-light rounded p-2 my-2">
    <div class="d-flex flex-row align-items-center">
        <mat-icon color="primary">{{ user.visible ? 'person' : 'person_outline' }}</mat-icon>
        <span class="ml-1 mr-3">{{user.contact.fullName}}</span>
    </div>
    <div *ngIf="user.contact.email" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">mail_outline</mat-icon>
        <span class="ml-1 mr-3"><a href="mailto:{{user.contact.email}}" class="text-decoration-none text-reset">{{user.contact.email}}</a></span>
    </div>
    <div *ngIf="user.contact.phone" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">phone</mat-icon>
        <span class="ml-1 mr-3"><a href="tel:{{user.contact.phone}}" class="text-decoration-none text-reset">{{user.contact.phone}}</a></span>
    </div>
    <div *ngIf="user.contact.trades.length" class="mt-1 pl-1">
        <span *ngFor="let trade of user.contact.trades; let last = last;" [class.mr-2]="!last" class="chip chip-sm chip-primary">{{trade.label}}</span>
    </div>
    <div *ngIf="user.roles.length" class="mt-1 pl-1">
        <span *ngFor="let role of user.roles; let last = last;" [class.mr-2]="!last" class="chip chip-sm">{{getRole(role)}}</span>
    </div>
    <div *ngIf="user?.contact?.society" class="mt-2 pl-1">
        <button (click)="onTapSociety()" mat-stroked-button color="primary" class="btn-rounded">
            <mat-icon>business</mat-icon>
            <span class="d-none d-lg-inline-block ml-1">{{user.contact.society.name}}</span>
        </button>
    </div>
    <div class="text-muted pl-1 mt-1 fz-12">Enregistré le {{formatDate(user.contact.dateCreated)}}</div>
    <div class="text-muted pl-1 fz-12">Dernière modification le {{formatDate(user.contact.dateUpdated)}}</div>
</div>
<div *ngIf="society" class="bg-primary-light rounded p-2 my-2">
    <div class="d-flex flex-row align-items-center">
        <mat-icon color="primary">business</mat-icon>
        <span class="ml-1 mr-3">{{society.name}}</span>
    </div>
    <div class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon color="primary" class="mat-icon-16">people_alt</mat-icon>
        <span class="ml-1 mr-3">{{society.contacts.length}} Contact{{society.contacts.length > 1 ? 's' : ''}}</span>
    </div>
    <div *ngIf="society.email" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">mail_outline</mat-icon>
        <span class="ml-1 mr-3"><a href="mailto:{{society.email}}" class="text-decoration-none text-reset">{{society.email}}</a></span>
    </div>
    <div *ngIf="society.phone" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">phone</mat-icon>
        <span class="ml-1 mr-3"><a href="tel:{{society.phone}}" class="text-decoration-none text-reset">{{society.phone}}</a></span>
    </div>
    <div *ngIf="society.web" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">language</mat-icon>
        <span class="ml-1 mr-3"><a href="{{society.web}}" target="_blank" class="text-decoration-none text-reset">{{society.web}}</a></span>
    </div>
    <div *ngIf="society.address || society.postalCode || society.city || society.country" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">location_on</mat-icon>
        <span class="ml-1 mr-3">{{society.address}} {{society.postalCode}} {{society.city}} {{society.country}}</span>
    </div>

    <dl class="row no-gutters fz-14">
        <dt *ngIf="society.type" class="col-sm-3 font-weight-bold">Type</dt>
        <dd *ngIf="society.type" class="col-sm-9 ml-0">{{getType(society.type)}}</dd>
        <dt *ngIf="society.code" class="col-sm-3 font-weight-bold">N° Fournisseur</dt>
        <dd *ngIf="society.code" class="col-sm-9 ml-0">{{society.code}}</dd>
        <dt *ngIf="society.siren" class="col-sm-3 font-weight-bold">Sirem</dt>
        <dd *ngIf="society.siren" class="col-sm-9 ml-0">{{society.siren}}</dd>
        <dt *ngIf="society.tvaNumber" class="col-sm-3 font-weight-bold">TVA</dt>
        <dd *ngIf="society.tvaNumber" class="col-sm-9 ml-0">{{society.tvaNumber}}</dd>
        <dt *ngIf="society.legalEntity" class="col-sm-3 font-weight-bold">Entité Légale</dt>
        <dd *ngIf="society.legalEntity" class="col-sm-9 ml-0">{{getLegalEntity(society.legalEntity)}}</dd>
        <dt *ngIf="society.thirdParty" class="col-sm-3 font-weight-bold">Type tiers</dt>
        <dd *ngIf="society.thirdParty" class="col-sm-9 ml-0">{{getThirdParty(society.thirdParty)}}</dd>
        <dt *ngIf="society.capital" class="col-sm-3 font-weight-bold">Capital</dt>
        <dd *ngIf="society.capital" class="col-sm-9 ml-0">{{society.capital}}</dd>
    </dl>
</div>
