import {Component, Input, NgZone, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {Society} from '@src/app/model/society.model';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {PageEvent} from '@angular/material/paginator';
import {Observable, Subscription} from 'rxjs';
import {SimpleChanges, SocietiesResult} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatSidenavContent} from '@angular/material/sidenav';

@Component({
    selector: 'app-annuaire-societe',
    templateUrl: './annuaire-society.component.web.html',
    styleUrls: ['./annuaire-society.component.web.scss'],
    providers: [ActionBottomSheetService]
})
export class AnnuaireSocietyComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() viewContainerRef: ViewContainerRef;
    @Input() societiesResult$: Observable<SocietiesResult>;
    @Input() container: MatSidenavContent;

    title = 'Sociétés';
    mode = 'over';
    backdrop = true;
    societies: Society[];
    isBusy: boolean;
    search: string;
    selectedSociety: Society;
    filterSubscription: Subscription;
    societySubscription: Subscription;
    sticky: boolean;

    // MatPaginator Inputs
    length = 10;
    pageSize = 10;
    pageIndex = 0;
    pageSizeOptions: number[] = [10, 25, 50, 100];

    constructor(
        private router: Router,
        private apiService: ApiService,
        private zone: NgZone,
    ) {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.getSociety();
        this.container.elementScrolled().subscribe((event: Event) => {
            this.zone.run(() => {
                if (this.pageSize > 10) {
                    const offset = this.container.measureScrollOffset('top');
                    this.sticky = offset >= 125;
                }
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.societiesResult$ && changes.societiesResult$.currentValue) {
            this.isBusy = true;
            this.selectedSociety = null;
            this.pageIndex = 0;
            if (typeof this.societySubscription !== 'undefined') { this.societySubscription.unsubscribe(); }
            if (typeof this.filterSubscription !== 'undefined') { this.filterSubscription.unsubscribe(); }
            this.filterSubscription = changes.societiesResult$.currentValue.subscribe((societiesResult: SocietiesResult) => {
                this.length = societiesResult.total;
                this.societies = societiesResult.societies;
                this.isBusy = false;
            });
        }
    }

    pageEventHandler(pageEvent: PageEvent) {
        this.pageIndex = pageEvent.pageIndex;
        this.pageSize = pageEvent.pageSize;
        this.selectedSociety = null;
        this.getSociety(pageEvent.pageIndex + 1, pageEvent.pageSize);
    }

    getSociety(page: number = 1, itemPerPage: number = 10) {
        this.isBusy = true;
        const filter = this.storageService.societyListFilter;
        const isFilterActif = !!(filter && (filter.name || filter.user));
        this.apiService.getSocietiesFilter({filter: isFilterActif ? filter : []}, page, itemPerPage)
            .subscribe((societiesResult: SocietiesResult) => {
                this.length = societiesResult.total;
                this.societies = societiesResult.societies;
                this.isBusy = false;
            });
    }

    societyTappedHandler(society: Society) {
        this.selectedSociety = society;
    }

    societyEmittedHandler(society: Society) {
        this.societies = this.societies.map(u => u.id === society.id ? society : u);
    }

    societyDeletedHandler(society: Society) {
        this.societies = this.societies.filter(u => u.id !== society.id);
    }
}
