<div class="d-flex flex-column">
    <!---------- Barre de navigation principale ---------->
    <app-affair-nav [viewContainerRef]="viewContainerRef" [dossier]="dossier" [back]="back" [title]="isBusy || isActionBusy ? title : dossierParent.label" [querySearch]="querySearch" [isBusy]="isBusy || isActionBusy" [items]="items"
                    class="d-flex flex-row justify-content-between align-items-center bg-primary-light rounded-bottom p-3 mb-3">
    </app-affair-nav>

    <!---------- Animation de chargement ---------->
    <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>

    <!---------- Conteneur principal ---------->
     <div *ngIf="!isBusy" class="container-fluid">
         <div class="row pb-3">
             <div class="col-lg-7 mb-3">
                <!---------- Titre et actions de demandes de chiffrages ---------->
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span class="fz-18">Documents du dossier :</span>
                    <div class="d-flex flex-row align-items-center">
                        <label *ngIf="dossier.allowTo.document" for="file" class="d-inline-flex">
                            <mat-icon [class.disabled]="isActionBusy" [matRippleDisabled]="isActionBusy" matRipple [matRippleCentered]="true"
                                      [matTooltipDisabled]="isActionBusy" matTooltip="Ajouter des documents" matTooltipPosition="left"
                                      class="icon-20">
                                file_upload
                            </mat-icon>
                        </label>
                        <input type="file" id="file" multiple [disabled]="isActionBusy" (change)="!isActionBusy && handleFileInput($event.target.files)">
                        <mat-icon *ngIf="dossier.allowTo.edit && !hasRoles(['ROLE_GUEST'])" [class.disabled]="isActionBusy" (click)="!isActionBusy && onTapAddDossier(dossier)"
                                  [matTooltipDisabled]="isActionBusy" matTooltip="Ajouter un dossier" matTooltipPosition="above"
                                  matRipple [matRippleCentered]="true" [matRippleDisabled]="isActionBusy" class="icon-20 ml-2">
                            create_new_folder
                        </mat-icon>
                        <button (click)="onTapDownloadAllDocument()" [disabled]="isActionBusy" mat-stroked-button color="primary" class="btn-rounded ml-2">
                            <mat-spinner *ngIf="spinner.allDownload" class="btn-spinner"></mat-spinner>
                            <mat-icon *ngIf="!spinner.allDownload">get_app</mat-icon>
                            <span class="d-none d-lg-inline-block  ml-1">Tout télécharger</span>
                        </button>
                    </div>
                </div>

                 <div *ngIf="dossier" class="d-flex flex-column bg-primary-light rounded px-3 py-2">
                     <div class="row no-gutters flex-nowrap">
                         <div class="col-auto d-flex align-items-center">
                             <button *ngIf="dossier.specificDetail.versions.length > 1" [disabled]="isActionBusy" mat-icon-button class="version-chiffrage" [class.not-last]="!isLastVersion" [matMenuTriggerFor]="versionMenu" [matMenuTriggerData]="{versions: dossier.specificDetail.versions}">
                                 {{dossier.specificDetail.label}}
                             </button>
                             <span *ngIf="dossier.specificDetail.versions.length == 1" [class.disabled]="isActionBusy" class="version-chiffrage">
                                 {{dossier.specificDetail.label}}
                             </span>
                         </div>
                         <div *ngIf="dossier.dateLimit" class="col-auto d-flex flex-fill justify-content-center align-items-center px-1">
                             <button [disabled]="isActionBusy" (click)="onTapDateLimit()" [matTooltipDisabled]="isActionBusy" mat-button matTooltip="Modifier la date limite" matTooltipPosition="above" class="mat-button-pre-icon">
                                 <mat-icon class="mr-1" [ngClass]="{'text-danger': !isActionBusy, 'text-muted': isActionBusy}">event_busy</mat-icon>
                                 <span>{{dossier.dateLimit | amDateFormat:'DD/MM/YYYY'}}</span>
                             </button>
                         </div>
                         <div *ngIf="!dossier.dateLimit" class="col-auto d-flex flex-fill justify-content-center align-items-center px-1">
                             <button [disabled]="isActionBusy" (click)="onTapDateLimit()" [matTooltipDisabled]="isActionBusy" mat-button matTooltip="Ajouter une date limite" matTooltipPosition="above" class="mat-button-pre-icon">
                                 <mat-icon class="mr-1" [ngClass]="{'text-primary': !isActionBusy, 'text-muted': isActionBusy}">event</mat-icon>
                                 <span>Ajouter une date limite</span>
                             </button>
                         </div>
                         <div *ngIf="dossier.allowTo.edit" class="col-auto">
                             <button mat-icon-button [disabled]="isActionBusy" [matMenuTriggerFor]="dossierMenu" [matMenuTriggerData]="{dossier: dossier}">
                                 <mat-spinner *ngIf="isActionBusy" class="btn-spinner"></mat-spinner>
                                 <mat-icon *ngIf="!isActionBusy">more_vert</mat-icon>
                             </button>
                         </div>
                     </div>

                     <mat-menu #versionMenu="matMenu" xPosition="after">
                         <ng-template matMenuContent let-versions="versions">
                             <button *ngFor="let version of versions"
                                     [class.d-none]="version.id == dossier.id"
                                     [disabled]="version.id == dossier.id"
                                     mat-menu-item (click)="onTapMoreVersionDossier(version)">
                                 <mat-icon>drive_file_move</mat-icon>
                                 <span>{{version.label}}</span>
                             </button>
                         </ng-template>
                     </mat-menu>

                     <mat-menu #dossierMenu="matMenu" xPosition="before">
                         <ng-template matMenuContent let-dossier="dossier">
                             <button mat-menu-item (click)="onTapEdit(dossier)">
                                 <mat-icon>edit</mat-icon>
                                 <span>Modifier</span>
                             </button>
                             <button mat-menu-item (click)="onTapAuthorize(dossier)">
                                 <mat-icon>admin_panel_settings</mat-icon>
                                 <span>Inviter</span>
                             </button>
                             <button mat-menu-item (click)="onTapVersion(dossier)">
                                 <mat-icon>create_new_folder</mat-icon>
                                 <span>Nouvelle version</span>
                             </button>
                             <button mat-menu-item (click)="onTapShorcut(dossier)">
                                 <mat-icon>drive_file_move</mat-icon>
                                 <span>Raccourcis</span>
                             </button>
                             <button *ngIf="dossier.allowTo.remove" mat-menu-item (click)="onTapDelete(dossier)">
                                 <mat-icon class="text-danger">delete_outline</mat-icon>
                                 <span>Supprimer</span>
                             </button>
                         </ng-template>
                     </mat-menu>

                     <div *ngIf="uploading" class="d-flex flex-row align-items-center order-0 mt-2">
                         <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2 ml-4 ">
                             <mat-icon *ngIf="!processing">publish</mat-icon>
                             <mat-spinner *ngIf="processing"
                                          class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
                             <div class="d-flex flex-grow-1 mx-2">
                                 <mat-progress-bar *ngIf="!processing" mode="determinate" [value]="progress"></mat-progress-bar>
                                 <span *ngIf="processing">Traitement du fichier en cours ...</span>
                             </div>
                         </div>
                     </div>
                     <p *ngIf="!uploading && !dossier.specificDetail.documentHistoriques.length" class="order-1 no-result d-flex justify-content-center align-items-center text-danger">
                         <mat-icon>report</mat-icon> Aucun document n'a été ajouté dans ce dossier.
                     </p>
                     <app-specific-document *ngFor="let documentHistorique of sortByDocument(); trackBy: trackBy;"
                                   [preview]="previewIdDocumentHistorique == documentHistorique.id"
                                   [documentHistorique]="documentHistorique" [isShortcut]="false"
                                   [documentHistoriques]="dossier.specificDetail.documentHistoriques"
                                   [dossier]="dossier" [selectedDocumentHistorique]="selectedDocumentHistorique"
                                   [viewContainerRef]="viewContainerRef" [disabled]="isActionBusy" class="order-1"></app-specific-document>
                     <app-specific-dossier *ngFor="let subDossier of sortBy(dossier.specificDetail.dossiers, 'label'); trackBy: trackBy;"
                                  (dossierDeleted)="dossierDeletedHandler($event)" [dossier]="subDossier"
                                  [selectedDocumentHistorique]="selectedDocumentHistorique" [isShortcut]="false"
                                  [viewContainerRef]="viewContainerRef" [activeStatus]="activeStatus"
                                  [disabled]="isActionBusy" class="d-flex flex-column order-1 ml-4"></app-specific-dossier>
                     <app-specific-dossier *ngFor="let shortcut of sortBy(dossier.shortcuts, 'label'); trackBy: trackBy;" [dossier]="shortcut"
                                           [selectedDocumentHistorique]="selectedDocumentHistorique" [isShortcut]="true"
                                           [parentDossier]="dossier.specificDetail" [disabled]="isActionBusy"
                                           [viewContainerRef]="viewContainerRef" [activeStatus]="activeStatus"
                                           class="d-flex flex-column order-1 ml-4"></app-specific-dossier>
                     <div class="d-flex flex-row order-2 mt-3">
                         <div class="fz-16 font-weight-bold flex-fill">
                             Utilisateurs
                         </div>
                         <div class="legend-types">
                             <div class="legend-type legend-type-1">Non diffusé</div>
                             <div class="legend-type legend-type-2">Diffusé</div>
                             <div class="legend-type legend-type-3">Répondu</div>
                         </div>
                     </div>
                     <mat-selection-list #specificUsers [multiple]="false" class="order-3 mb-2"  (selectionChange)="onSelectSpecificUser($event)">
                         <mat-list-option *ngFor="let specificUser of sortByUser(dossier.specificDetail.users, 'fullName'); trackBy: trackByDossier;"
                                          [disabled]="isActionBusy" [value]="specificUser"
                                          [selected]="!isActionBusy && (selectedDossierSpecifUser == specificUser.user.id)"
                                          class="specific-user-list specific-user-type-{{specificUser.type}}">
                             <mat-icon mat-list-icon color="primary">person</mat-icon>
                             <div mat-line>{{specificUser.user.fullName}}</div>
                         </mat-list-option>
                     </mat-selection-list>
                 </div>
             </div>
             <div class="col-lg-5">
                 <div *ngIf="!isActionBusy && userOpen">
                     <app-specific-user (actionUser)="actionUserHandler($event)"
                                        [dossierSpecificUser]="dossierSpecificUser"
                                        [previewIdDocumentHistorique]="previewIdDocumentHistorique"
                                        [selectedIdDocumentHistorique]="selectedIdDocumentHistorique"
                                        [viewContainerRef]="viewContainerRef">
                     </app-specific-user>
                 </div>
             </div>
         </div>
    </div>
</div>
