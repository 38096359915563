import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormControl, FormArray, AbstractControl} from '@angular/forms';
import {AppComponent} from '@src/app/app.component';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {Affair, PAYS, STATES} from '@src/app/model/affair.model';
import {User} from '@src/app/model/user.model';
import {ModalService} from '@src/app/services/modal.service';
import {Location} from '@angular/common';
import {MatDrawerMode} from '@angular/material/sidenav';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
    selector: 'app-affair-form',
    templateUrl: './affair-form.component.web.html',
    styleUrls: ['./affair-form.component.web.scss'],
})
export class AffairFormComponent extends BaseComponent implements OnInit {
    title = 'Ajouter une affaire';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isLinear = true;
    isBusy: boolean;
    isUploading: boolean;
    affair: Affair;
    affairForm: FormGroup;
    chargeAffairs: User[];
    users: User[];
    guests: User[];
    states = STATES;
    pays = PAYS;

    // TODO affair service
    constructor(
        private appComponent: AppComponent,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        public viewContainerRef: ViewContainerRef,
        private modalService: ModalService,
        public location: Location,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.title = 'Modifier une affaire';
                this.sharedService.updateTitle(this.title);
            }
        });
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.isUploading = false;
        this.activatedRoute.params.subscribe((params) => {
            if (params['id']) {
                this.apiService.getAffair(params['id']).subscribe((affair) => {
                    this.isLinear = false;
                    this.affair = affair;
                    this.affair.state += '';
                    this.guests = this.affair.members.affair.map(u => u.user).filter(u => !u.visible);
                    this.createForm();
                });
            } else {
                this.affair = new Affair();
                this.createForm();
            }
        });
    }

    createForm() {
        this.isBusy = false;
        const members = this.formatArray(this.affair.members.affair.map(u => u.user).filter(u => u.visible));
        this.affair.chargeAffairs = this.formatArray(this.affair.chargeAffairs);
        this.apiService.getChargeAffairs().subscribe((caResult) => {
            this.chargeAffairs = caResult.users;
            if (!this.affair.chargeAffairs.length) {
                // tslint:disable-next-line:triple-equals
                const user = this.chargeAffairs.find(ca => ca.id == this.storageService.userId);
                this.affair.chargeAffairs.push(user);
                this.affair.chargeAffairs = this.formatArray(this.affair.chargeAffairs);
            }
            this.apiService.getUsers().subscribe((usersResult) => {
                this.users = usersResult.users.filter(u => u.visible);
                this.affairForm = new FormGroup({
                    formArray: new FormArray([
                        new FormGroup({
                            label: new FormControl(this.affair.label),
                            reference: new FormControl(this.affair.reference),
                            state: new FormControl(this.affair.state),
                            description: new FormControl(this.affair.description),
                            charge_affairs: new FormControl(this.affair.chargeAffairs),
                            members: new FormControl(members),
                            file: new FormControl(''),
                            archive: new FormControl(this.affair.archive),
                        }),
                        new FormGroup({
                            address: new FormControl(this.affair.address),
                            postalCode: new FormControl(this.affair.postalCode),
                            city: new FormControl(this.affair.city),
                            country: new FormControl(this.affair.country),
                        }),
                        new FormGroup({
                            datePose: new FormControl(this.affair.datePose),
                            dateOffer: new FormControl(this.affair.dateOffer),
                            dateReception: new FormControl(this.affair.dateReception),
                            dateChantier: new FormControl(this.affair.dateChantier),
                            dateLivraison: new FormControl(this.affair.dateLivraison),
                            dateFabrication: new FormControl(this.affair.dateFabrication),
                            dateConception: new FormControl(this.affair.dateConception),
                            dateHypothese: new FormControl(this.affair.dateHypothese),
                            dateConsultation: new FormControl(this.affair.dateConsultation),
                        }),
                    ]),
                });
            });
        });
    }

    onAddUser() {
        this.modalService.show(
            {},
            'user',
            this.viewContainerRef,
            false
        ).subscribe((result) => {
            if (result) {
                this.users.push(result);
                this.users.sort(this.compareUser);
            }
        });
    }

    compareUser(a: User, b: User) {
        if ( a.fullName.toLowerCase() < b.fullName.toLowerCase() ) {
            return -1;
        }
        if ( a.fullName.toLowerCase() > b.fullName.toLowerCase() ) {
            return 1;
        }
        return 0;
    }

    get formArray(): AbstractControl | null {
        return this.affairForm.get('formArray');
    }

    onSubmit(): void {
        if (this.affairForm.valid) {
            this.isUploading = true;
            const data = {...this.formArray.get([0]).value, ...this.formArray.get([1]).value, ...this.formArray.get([2]).value};
            data.datePose = this.formatDate(data.datePose);
            data.dateOffer = this.formatDate(data.dateOffer);
            data.dateReception = this.formatDate(data.dateReception);
            data.dateChantier = this.formatDate(data.dateChantier);
            data.dateLivraison = this.formatDate(data.dateLivraison);
            data.dateFabrication = this.formatDate(data.dateFabrication);
            data.dateConception = this.formatDate(data.dateConception);
            data.dateHypothese = this.formatDate(data.dateHypothese);
            data.dateConsultation = this.formatDate(data.dateConsultation);
            const members = this.formatArray(data.members);
            const guest = this.formatArray(this.guests);
            data.members = guest ? members.concat(guest) : members;
            data.charge_affairs = this.formatArray(data.charge_affairs);

            let formData = new FormData();
            formData = this.convertJsontoFormData(data, null, formData);

            if (this.affair.id) {
                this.apiService.putAffair(this.affair.id, formData).subscribe((affair) => {
                    this.isUploading = false;
                    if (affair) {
                        this.toastService.show(`L'affaire "${affair.label}" a été modifié`);
                        this.router.navigate(['affaires']);
                    }
                }, () => this.isUploading = false);
            } else {
                this.apiService.postAffair(formData).subscribe((affair) => {
                    if (affair) {
                        this.toastService.show(`L'affaire "${affair.label}" a été ajouté`);
                        this.router.navigate(['affaires']);
                    }
                }, () => this.isUploading = false);
            }
        }
    }
}
