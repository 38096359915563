<form [formGroup]="filterForm">
    <mat-form-field appearance="outline" class="input-filter filter-search p-0">
        <mat-label>Saisir ma recherche</mat-label>
        <span matPrefix>
            <mat-icon>search</mat-icon>
        </span>
        <input matInput
                type="text"
                placeholder="Saisir ma recherche"
                formControlName="name">
    </mat-form-field>

    <mat-form-field *ngIf="hasRoles(['ROLE_AFFAIRE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])" class="input-filter filter-users p-0" appearance="outline">
        <mat-label>Utilisateur</mat-label>
        <mat-select formControlName="user">
            <mat-option>Sélectionner un utilisateur</mat-option>
            <mat-option *ngFor="let option of optionsUsers$ | async" [value]="option.id">
                {{option.fullName}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="d-flex justify-content-end pt-3">
        <button (click)="onReset()" mat-flat-button color="warn">
            <mat-icon>clear</mat-icon>
            <span>Réinitialiser</span>
        </button>
    </div>
</form>
