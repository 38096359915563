import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
    selector: 'app-modal-alert',
    templateUrl: 'modal-alert.component.web.html',
})
export class ModalAlertComponent implements OnInit {

    title: string;
    message: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HttpErrorResponse,
        public dialogRef: MatDialogRef<ModalAlertComponent>,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        switch (this.data.status) {
            case 403:
                this.title = 'Non autorisé';
                this.message = `<p>Vous n'avez pas les autorisations pour visualiser cette page.</p>
                                <p>Vous allez être rediriger vers l'accueil en fermant cette alerte.</p>`;
                break;
            default:
                this.title = 'Une erreur est survenue';
                this.message = `<code>${this.data.status}</code> Une erreur est survenue lors de l'affichage de cette page.`;
                break;
        }
    }

    onClose(): void {
        this.router.navigate(['/']);
        this.dialogRef.close();
    }
}
