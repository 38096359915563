import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Affair} from '@src/app/model/affair.model';
import {Router} from '@angular/router';
import {AlertService} from '@src/app/services/alert.service';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';

@Component({
  selector: 'app-affair-item',
  templateUrl: './affair-item.component.html',
  styleUrls: ['./affair-item.component.scss']
})
export class AffairItemComponent implements OnInit {

  @Input() affair: Affair;
  @Output() affairDelete: EventEmitter<Affair> = new EventEmitter<Affair>();

  isActionBusy: boolean;

  constructor(
      private router: Router,
      private alertService: AlertService,
      private apiService: ApiService,
      private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.isActionBusy = false;
  }

  onTapDelete(affair: Affair) {
    this.isActionBusy = true;
    const text = `Vous allez définitivement supprimer une affaire.
        Cela supprimera aussi tous les dossiers et documents rattachés à cette affaire.
        Vous ne pourrez pas récupérer cette affaire !`;
    this.alertService.show(text).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteAffair(affair.id).subscribe(() => {
          this.isActionBusy = false;
          this.toastService.show(`L'affaire "${affair.label}" a bien été supprimé.`);
          this.affairDelete.emit(affair);
        });
      }
    });
  }

}
