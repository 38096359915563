import {Injectable} from '@angular/core';
// @ts-ignore
import {version} from '../../../package.json';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    public getVersion(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            resolve(version);
        });
    }
}
