import {Injectable} from '@angular/core';
import Swal, {SweetAlertOptions} from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    show(text: string, confirmText: string = 'Valider', cancelText: string = 'Annuler', denyText: string = null): Promise<any> {
        const options: SweetAlertOptions = {
            title: 'Êtes-vous sûr ?',
            icon: 'error',
            html: text,
            confirmButtonText: confirmText,
        };
        if (cancelText) {
            options.showCancelButton = true;
            options.cancelButtonText = cancelText;
        }
        if (denyText) {
            options.showDenyButton = true;
            options.denyButtonText = denyText;
        }
        return Swal.fire(options);
    }
}
