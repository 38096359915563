import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Feed} from '@src/app/shared/interfaces';

@Component({
    selector: 'app-feed',
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss']
})
export class FeedComponent {

    @Input() data: Feed;
    @Output() linkClicked = new EventEmitter<string[]>();
    open = true;

    toggle() {
        this.open = !this.open;
    }

    isAttachmentImage(type: string) {
        return type.match(/.(jpg|jpeg|png|gif)$/i);
    }

    onLinkTap(routerParams: any[]) {
        this.linkClicked.emit(routerParams);
    }
}
