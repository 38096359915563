import { Component, Input, OnChanges, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Affair } from '@src/app/model/affair.model';
import { SimpleChanges } from '@src/app/shared/interfaces';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-step-calendar',
  templateUrl: './step-calendar.component.html',
  styleUrls: ['./step-calendar.component.scss']
})
export class StepCalendarComponent implements OnInit, OnDestroy, OnChanges  {

    @Input() isVisible: boolean;
    @Input() viewContainerRef: ViewContainerRef;
    notifier = new Subject();
    affair: Affair;
    affairId: number;
    isBusy: boolean;
    roles: string[] = [];

    constructor() { }

    ngOnInit(): void {
        // this.isBusy = true;
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isVisible.currentValue) {
        }
    }

    convertDate(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }

    trackByFn(index, item) {
        return index;
    }

}
