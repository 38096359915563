<div *ngIf="!uploading" class="row no-gutters flex-nowrap align-items-center mt-2 overflow-hidden">
    <div class="col-auto pr-2">
        <span [class.invisible]="documentHistorique.nbDiscutions == 0"
              class="d-inline-flex badge justify-content-center align-items-center">{{documentHistorique.nbDiscutions}}</span>
    </div>
    <div class="col overflow-hidden">
        <div class="row no-gutters align-items-center rounded overflow-hidden border-left-5"
             [class.border-left-white]="!open" [class.border-left-primary]="open"
             [class.border-left-danger]="documentHistorique.nbNotifications.total > 0" [class.bg-white]="!open"
             [class.bg-primary]="open" [class.text-white]="open">
            <div class="col-auto">
                <button (click)="toggleFavoriDocument()" [disabled]="spinner.favoris" mat-icon-button>
                    <mat-spinner *ngIf="spinner.favoris" class="btn-spinner" [class.btn-spinner-white]="open"></mat-spinner>
                    <mat-icon *ngIf="documentHistorique.document.isFavoris && !spinner.favoris" class="text-warning">star</mat-icon>
                    <mat-icon *ngIf="!documentHistorique.document.isFavoris && !spinner.favoris">star_outline</mat-icon>
                </button>
            </div>
            <div (click)="onTapViewer()" matRipple matTooltip="Visualiser le document" matTooltipPosition="above"
                 class="has-ripple col overflow-hidden cursor-pointer d-flex align-self-stretch px-2">
                <div class="row no-gutters align-items-center overflow-hidden flex-fill">
                    <div class="col-xl-6 text-truncate overflow-hidden">
                        {{documentHistorique.name}}
                    </div>
                    <div class="d-none d-xl-block col-xl-6 text-right">
                        <span class="d-inline-block mr-2">{{documentHistorique.document.user.fullName}}.</span>
                        <span class="d-inline-block mr-2">{{documentHistorique.dateCreated | date: 'dd/MM/yyyy'}}</span>
                        <span class="d-inline-block mr-2">{{documentHistorique.version}}</span>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <button (click)="toggleInformationDocument()" mat-icon-button matTooltip="Ouvrir le menu action" matTooltipPosition="above">
                    <mat-icon *ngIf="!this.open">expand_more</mat-icon>
                    <mat-icon *ngIf="this.open">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="col-auto document-state pl-2">
        <mat-icon *ngIf="documentHistorique.state == 3" class="text-success">check_circle</mat-icon>
        <mat-icon *ngIf="documentHistorique.state == 2" class="text-warning">stop_circle</mat-icon>
        <mat-icon *ngIf="documentHistorique.state == 1 || documentHistorique.state == 4" class="text-danger">
            stop_circle
        </mat-icon>
    </div>
</div>
<div *ngIf="uploading" class="d-flex flex-row align-items-center mt-2">
    <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2 ml-4 ">
        <mat-icon *ngIf="!processing">publish</mat-icon>
        <mat-spinner *ngIf="processing" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <div class="d-flex flex-grow-1 mx-2">
            <mat-progress-bar *ngIf="!processing" mode="determinate" [value]="progress"></mat-progress-bar>
            <span *ngIf="processing">Traitement du fichier en cours ...</span>
        </div>
    </div>
</div>
<div *ngIf="open" class="d-flex flex-row flex-wrap align-items-center ml-4">
    <button *ngIf="dossier.dateLimit" mat-button class="btn-document-action">
        <mat-icon>visibility</mat-icon>
        {{ formatDate(dossier.dateLimit) }}</button>
    <button *ngIf="documentHistorique.state == 3 && documentHistorique.allowTo.validate"
            [matMenuTriggerFor]="subMenuDocument" mat-button class="btn-document-action">
        <mat-spinner *ngIf="spinner.state" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.state" class="text-success mr-2">check_circle</mat-icon>
        Validé
        <mat-icon class="ml-2">keyboard_arrow_down</mat-icon>
    </button>
    <button *ngIf="documentHistorique.state == 2 && documentHistorique.allowTo.validate"
            [matMenuTriggerFor]="subMenuDocument" mat-button class="btn-document-action">
        <mat-spinner *ngIf="spinner.state" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.state" class="text-warning mr-2">stop_circle</mat-icon>
        En cours
        <mat-icon class="ml-2">keyboard_arrow_down</mat-icon>
    </button>
    <button *ngIf="(documentHistorique.state == 1 || documentHistorique.state == 4) && documentHistorique.allowTo.validate"
            [matMenuTriggerFor]="subMenuDocument" mat-button class="btn-document-action">
        <mat-spinner *ngIf="spinner.state" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.state" class="text-danger mr-2">stop_circle</mat-icon>
        À faire
        <mat-icon class="ml-2">keyboard_arrow_down</mat-icon>
    </button>
    <button *ngIf="documentHistorique.document.nbVersions > 1" (click)="toggleVersion()" mat-button
            class="btn-document-action">
        <mat-icon>timeline</mat-icon>
        Versions
    </button>
    <button *ngIf="!documentHistorique.document.isSuivis" (click)="toggleSuivis()" mat-button
            class="btn-document-action">
        <mat-spinner *ngIf="spinner.follow" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.follow">visibility</mat-icon>
        Suivre
    </button>
    <button *ngIf="documentHistorique.document.isSuivis" (click)="toggleSuivis()" mat-button
            class="btn-document-action">
        <mat-spinner *ngIf="spinner.follow" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.follow">visibility_off</mat-icon>
        Ne plus suivre
    </button>
    <!-- ajouter à ce bouton un autocomplete sur les emails présent dans l'API -->
    <button (click)="onTapShare()" mat-button class="btn-document-action">
        <mat-icon>share</mat-icon>
        Partager
    </button>
    <label *ngIf="documentHistorique.allowTo.edit" matRipple class="btn-document-action mat-button cursor-pointer overflow-hidden"
           for="fileDoc"><span class="mat-button-wrapper"><mat-icon>file_upload</mat-icon> Modifier</span></label>
    <input *ngIf="documentHistorique.allowTo.edit" class="d-none" type="file" id="fileDoc"
           (change)="handleFileInput($event.target.files)">
    <button *ngIf="documentHistorique.allowTo.edit" (click)="onTapRename()" mat-button class="btn-document-action">
        <mat-icon>create</mat-icon>
        Renommer
    </button>
    <button *ngIf="documentHistorique.allowTo.delete" (click)="onDelete()" mat-button
            class="btn-document-action">
        <mat-spinner *ngIf="spinner.delete" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.delete" class="text-danger">delete_forever</mat-icon>
        Supprimer
    </button>
    <mat-menu #subMenuDocument="matMenu" xPosition="before">
        <button *ngIf="documentHistorique.state != 3" (click)="onTapValid()" mat-menu-item>
            <mat-icon class="text-success">redo</mat-icon>
            Valider
        </button>
        <button *ngIf="documentHistorique.state != 2" (click)="onTapWork()" mat-menu-item>
            <mat-icon class="text-warning">redo</mat-icon>
            En cours
        </button>
        <button *ngIf="documentHistorique.state != 4 && documentHistorique.state != 1" (click)="onTapDo()"
                mat-menu-item>
            <mat-icon class="text-danger">redo</mat-icon>
            À faire
        </button>
    </mat-menu>
    <button (click)="onTapDownload()" mat-button class="btn-document-action">
        <mat-spinner *ngIf="spinner.download" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.download">get_app</mat-icon>
        Télécharger
    </button>
    <!--    <button (click)="onTapViewer()" mat-button class="btn-document-action"><mat-icon>visibility</mat-icon> Visualiser</button>-->
</div>
<div *ngIf="version && open && documentHistorique?.document.versions" class="d-flex flex-column ml-4">
    <div *ngFor="let versionDocumentHistorique of documentHistorique.document.versions"
         class="d-flex flex-row align-items-center ">
        <div *ngIf="versionDocumentHistorique.id"
             class="d-flex flex-row align-items-center flex-fill  rounded px-2 mt-2 ml-4 bg-white">
            <div class="d-flex justify-content-between flex-fill mx-2 cursor-pointer">
                <div class="text-truncate w-50">{{versionDocumentHistorique.name}}</div>
                <div class="d-none d-xl-block ml-auto mr-4">{{versionDocumentHistorique.user.fullName}}.</div>
                <div class="d-none d-xl-block mr-4">{{versionDocumentHistorique.dateCreated | date: 'dd/MM/yyyy'}}</div>
                <div class="d-none d-xl-flex flex-row align-content-center mr-2">{{versionDocumentHistorique.version}}</div>
            </div>
            <button (click)="onTapDownload(versionDocumentHistorique)" mat-icon-button
                    class="ml-auto align-self-end cursor-pointer">
                <mat-icon>get_app</mat-icon>
            </button>
        </div>
    </div>
</div>
