<!---------- Menu filtres ---------->

<!-- cf. affair/form/modal-society.component.web.html/ts -->
<form [formGroup]="filterForm">
        <!-- input text de recherche d'affaires -->
        <mat-form-field appearance="outline" class="input-filter filter-search p-0">
            <mat-label>Affaire</mat-label>
            <span matPrefix>
                <mat-icon>search</mat-icon>
            </span>
            <input matInput
                    type="text"
                    placeholder="Rechercher une affaire"
                    formControlName="search">
        </mat-form-field>

        <!-- input select pour les états des affaires -->
        <mat-form-field class="input-filter filter-states p-0" appearance="outline">
            <mat-label>État</mat-label>
            <mat-select formControlName="state">
                <mat-option>Sélectionner un état</mat-option>
                <mat-option *ngFor="let option of optionsStates" [value]="option.key">
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- input select pour les étapes des affaires -->
        <mat-form-field class="input-filter filter-steps p-0" appearance="outline">
            <mat-label>Étape</mat-label>
            <mat-select formControlName="step">
                <mat-option>Sélectionner une étape</mat-option>
                <mat-option *ngFor="let option of optionsSteps" [value]="option.key">
                    {{option.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- input select pour les utilisateurs des affaires -->
        <mat-form-field  *ngIf="hasRoles(['ROLE_AFFAIRE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])" class="input-filter filter-users p-0" appearance="outline">
            <mat-label>Utilisateur</mat-label>
            <mat-select formControlName="user">
                <mat-option>Sélectionner un utilisateur</mat-option>
                <mat-option *ngFor="let option of optionsUsers$ | async" [value]="option.id">
                    {{option.fullName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="d-flex justify-content-end pt-3">
            <button (click)="onReset()" mat-flat-button color="warn">
                <mat-icon>clear</mat-icon>
                <span>Réinitialiser</span>
            </button>
        </div>

</form>
