<h1 mat-dialog-title>
    <span class="d-inline-block mt-1" cdkFocusRegionstart>Activités</span>
    <button (click)="onClose()"  mat-icon-button color="warn" class="float-right">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="!isModalLoaded" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="isModalLoaded">
        <div class="activity-input d-flex flex-fill flex-row align-items-center ">
            <div class="activity-input-avatar">
                <span>{{ initiale }}</span>
            </div>
            <div class="d-flex flex-grow-1">
                <mat-form-field appearance="outline" class="mat-input-search pb-0 w-100 no-margin">
                    <mat-label>Votre message</mat-label>
                    <input matInput name="message" type="text" [disabled]="spinner.message" (keyup.enter)="onSubmitMessage()" [(ngModel)]="message" autocorrect="false">
                </mat-form-field>
            </div>
            <div>
                <mat-spinner *ngIf="spinner.message" class="btn-spinner btn-spinner-lg"></mat-spinner>
                <mat-icon *ngIf="!spinner.message" (click)="onSubmitMessage()" class="icon-30 text-primary cursor-pointer active">send</mat-icon>
            </div>
        </div>
    </div>

    <app-activity *ngFor="let activity of activityList$ | async" [activity]="activity"></app-activity>
</div>
