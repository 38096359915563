import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalShareComponent} from '@src/app/affair/form/modal-share.component';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {ActionResult} from '@src/app/shared/interfaces';
import {DocumentService} from '@src/app/services/document.service';

@Component({
    selector: 'app-viewer',
    templateUrl: './viewer.component.web.html',
    styleUrls: ['./viewer.component.web.scss'],
})
export class ViewerComponent implements OnInit {

    title = 'Dossier';
    mode = 'over';
    backdrop = true;
    url: SafeUrl;
    documentHistorique: DocumentHistorique;
    documentHistoriques: DocumentHistorique[];
    isNavPossible: boolean;

    constructor(
        public dialogRef: MatDialogRef<ModalShareComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private documentService: DocumentService,
        private sanitizer: DomSanitizer,
    ) {
    }

    ngOnInit(): void {
        this.isNavPossible = false;
        this.documentHistorique = this.data.documentHistorique;
        this.documentHistoriques = this.data.documentHistoriques;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentHistorique.embedLink);
        this.isNavPossible = this.documentHistorique.id !== this.documentHistorique.previousLink;
    }

    close() {
        this.url = '';
    }

    updateDocumentHistorique(id: number) {
        // tslint:disable-next-line:triple-equals
        this.documentHistorique = this.documentHistoriques.find(dh => dh.id == id);
        if (this.documentHistorique.nbNotifications.total > 0) {
            this.documentService.isReaded(this.documentHistorique)
                .subscribe((result: ActionResult) => {
                    if (result) { this.documentHistorique.nbNotifications.total = 0; }
                });
        }
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentHistorique.embedLink);
    }

}
