import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Activity} from '@src/app/shared/interfaces';

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

    @Input() activity: Activity;
    @Output() linkClicked = new EventEmitter<string[]>();
    open = true;

    ngOnInit() {
    }

    toggle() {
        this.open = !this.open;
    }

    onLinkTap(routerParams: any[]) {
        this.linkClicked.emit(routerParams);
    }
}
