import {Injectable, ViewContainerRef} from '@angular/core';
import {ApiService} from '@src/app/services/api.service';
import {tap} from 'rxjs/operators';
import {Dossier, DossierSpecificDetail, DossierSpecificList, DossierSpecifUser} from '@src/app/model/dossier.model';
import {Observable} from 'rxjs';
import {ToastService} from '@src/app/services/toast.service';
import {ModalService} from '@src/app/services/modal.service';
import {AlertService} from '@src/app/services/alert.service';
import {ActionResult, BridgeModalStorage, ActionVersionResult, ActionStateResult} from '@src/app/shared/interfaces';
import {DownloadService} from '@src/app/services/download.service';
import {DocumentHistorique} from '@src/app/model/document-historique.model';

@Injectable({
    providedIn: 'root'
})
export class DossierService {

    constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private alertService: AlertService,
        private downloadService: DownloadService,
    ) {
    }

    get(id: number): Observable<Dossier> {
        return this.apiService.getDossier(id);
    }

    edit(dossier: Dossier|DossierSpecificList, isSpecificList: boolean = false, vcRef: ViewContainerRef): Observable<Dossier> {
        return this.modalService.show(
            {dossier: dossier, isSpecificList: isSpecificList},
            'dossier',
            vcRef
        ).pipe(
            tap((result: Dossier) => {
                if (result && isSpecificList) { this.toastService.show(`Le dossier "${dossier.label}" a été modifié.`); }
                if (result && !isSpecificList) { this.toastService.show(`Le dossier "${result.label}" a été modifié.`); }
            })
        );
    }

    add(dossier: Dossier, isSpecificList: boolean = false, vcRef: ViewContainerRef): Observable<Dossier> {
        return this.modalService.show(
            {parentDossier: dossier, isSpecificList: isSpecificList},
            'dossier',
            vcRef
        ).pipe(
            tap((result: Dossier) => {
                if (result && !isSpecificList) { this.toastService.show(`Le dossier "${result.label}" a été crée.`); }
            })
        );
    }

    addFile(dossier: Dossier, vcRef: ViewContainerRef): Observable<Dossier> {
        return this.modalService.show(
            {dossier: dossier},
            'dossier-file',
            vcRef
        // ).pipe(
        //     tap((result: Dossier) => {
        //         if (result) { this.toastService.show(`Les fichiers du dossier "${result.label}" a été ajouté.`); }
        //     })
        );
    }

    shortcut(dossier: Dossier, isSpecificList: boolean = false, vcRef: ViewContainerRef): Observable<Dossier> {
        return this.modalService.show(
            {dossier: dossier, light: isSpecificList},
            'dossier-shortcut',
            vcRef
        ).pipe(
            tap((result: Dossier) => {
                // tslint:disable-next-line:max-line-length
                if (result && !isSpecificList) { this.toastService.show(`Les raccourcis du dossier "${result.label}" ont été mis à jour.`); }
            })
        );
    }

    shortcutRefresh(parentDossierId: number): Observable<Dossier> {
        return this.apiService.getDossierShortcutRefreshFavoris(parentDossierId);
    }

    // tslint:disable-next-line:max-line-length
    favori(dossier: Dossier, parentDossier: Dossier|DossierSpecificDetail, isOrphan: boolean = false, vcRef: ViewContainerRef): Observable<ActionResult|DocumentHistorique[]> {
        return this.modalService.show(
            {shortcut: dossier, version: parentDossier, isOrphan: isOrphan},
            'dossier-favori',
            vcRef
        ).pipe(
            tap((result: ActionResult) => {
                if (result && isOrphan) { this.toastService.show(`Les favoris du raccourci "${dossier.label}" ont été mis à jour.`); }
            })
        );
    }

    authorize(
        dossier: Dossier|DossierSpecificList,
        parent: Dossier|DossierSpecificList = null,
        returnList: boolean = false,
        isEdit: boolean = false,
        vcRef: ViewContainerRef,
    ): Observable<Dossier|BridgeModalStorage> {
        return this.modalService.show(
            {dossier: dossier, parentDossier: parent, returnList: returnList},
            'authorize',
            vcRef,
            false
        ).pipe(
            tap((result: Dossier|BridgeModalStorage) => {
                if (result && returnList && !isEdit && !(result instanceof BridgeModalStorage)) { this.toastService.show(`Le dossier "${dossier.label}" a été crée.`); }
                if (result && returnList && isEdit && !(result instanceof BridgeModalStorage)) { this.toastService.show(`Le dossier "${dossier.label}" a été mis à jour.`); }
                if (result && !returnList && !(result instanceof BridgeModalStorage)) { this.toastService.show(`Le dossier "${result.label}" a été mis à jour.`); }
            })
        );
    }

    specificUser(
        specificUser: DossierSpecifUser,
        previewIdDocumentHistorique: number,
        selectedIdDocumentHistorique: number,
        viewContainerRef: ViewContainerRef
    ): Observable<DossierSpecifUser> {
        return new Observable<DossierSpecifUser>(subscriber => {
            subscriber.next();
        });
    }

    diffuse(dossier: Dossier, vcRef: ViewContainerRef): Observable<Dossier> {
        return this.modalService.show(
            {dossier: dossier},
            'diffuse',
            vcRef,
            false
        ).pipe(
            tap((result: Dossier) => {
                if (result) { this.toastService.show(`Le dossier "${result.label}" a été diffusé aux utilisateurs.`); }
            })
        );
    }

    validResponse(dossier: Dossier): Observable<Dossier> {
        return this.apiService.validResponse(dossier.id, 3)
            .pipe(
                tap((result: Dossier) => {
                    if (result) { this.toastService.show(`Le dossier "${result.label}" a été validé.`); }
                })
            );
    }

    validCAResponse(dossier: Dossier): Observable<Dossier> {
        return this.apiService.validCAResponse(dossier.id, 3)
            .pipe(
                tap((result: Dossier) => {
                    if (result) { this.toastService.show(`Le dossier "${result.label}" a été validé.`); }
                })
            );
    }

    refuseCAResponse(dossier: Dossier): Observable<Dossier> {
        return this.apiService.validCAResponse(dossier.id, 1)
            .pipe(
                tap((result: Dossier) => {
                    if (result) { this.toastService.show(`Le dossier "${result.label}" a été refusé.`); }
                })
            );
    }

    state(dossier: Dossier, state: number, isStep: boolean = false): Observable<ActionStateResult> {
        let toast: string;
        switch (state) {
            case 1:
                toast = `Le dossier "${dossier.label}" a été déplacé dans "À faire".`;
                break;
            case 2:
                toast = `Le dossier "${dossier.label}" a été déplacé dans "En cours".`;
                break;
            case 3:
                toast = `Le dossier "${dossier.label}" a été déplacé dans "Validé".`;
                break;
        }
        return this.apiService.updateStateDossier(dossier.id, state, isStep)
            .pipe(
                tap((result: ActionStateResult) => {
                    if (result) { this.toastService.show(toast); }
                })
            );
    }

    validAll(dossier: Dossier): Observable<Dossier> {
        return this.apiService.updateAllStateDocument(dossier.id, 3)
            .pipe(
                tap((result: Dossier) => {
                    // tslint:disable-next-line:max-line-length
                    if (result) { this.toastService.show(`Les fichiers du dossier "${dossier.label}" ont été déplacé dans "Validé".`); }
                })
            );
    }

    version(dossier: Dossier|DossierSpecificList): Observable<ActionVersionResult> {
        return this.apiService.addVersionDossier(dossier.id)
            .pipe(
                tap((result: ActionVersionResult) => {
                    if (result) { this.toastService.show(`La nouvelle version "${result.version.label}" a été créer.`); }
                })
            );
    }

    dateLimite(dossier: Dossier, vcRef: ViewContainerRef): Observable<{dateLimit: string}> {
        return this.modalService.show(
            {dossier: dossier},
            'dossier-date-limite',
            vcRef
        ).pipe(
            tap((result: {dateLimit: string}) => {
                // tslint:disable-next-line:max-line-length
                if (result && result.dateLimit) { this.toastService.show(`La date limite du dossier "${dossier.label}" a été mis à jour.`); }
                if (result && !result.dateLimit) { this.toastService.show(`La date limite du dossier "${dossier.label}" a été retiré.`); }
            })
        );
    }

    delete(dossier: Dossier|DossierSpecificList): Observable<ActionResult> {
        return new Observable<ActionResult>(subscriber => {
            const text = 'Vous allez définitivement supprimer un dossier.\n' +
                'Cela supprimera aussi tous les sous-dossiers et documents rattachés à ce dossier.\n' +
                'Vous ne pourrez pas récupérer ce dossier !';
            this.alertService.show(text).then((alert) => {
                if (alert.isConfirmed) {
                    this.apiService.deleteDossier(dossier.id)
                        .subscribe((result: ActionResult) => {
                            if (result.success) {
                                this.toastService.show(`Le dossier "${dossier.label}" a été supprimé.`);
                                subscriber.next(result);
                            }
                        }, () => subscriber.next(null));
                } else {
                    subscriber.next(null);
                }
            });
        });
    }

    upload(dossier: Dossier|DossierSpecificDetail, files: any): Observable<any> {
        const formData: FormData = new FormData();
        if (files.length > 1) {
            for (const file of files) {
                formData.append('files[]', file, file.name);
            }
        } else {
            formData.append('files[]', files[0], files[0].name);
        }
        return this.apiService.uploadDocuments(dossier.id, formData);
    }

    download(dossier: Dossier, parent: Dossier = null): Promise<any> {
        this.toastService.show(`Préparation des documents du dossier "${dossier.label}" pour le téléchargement.`);
        return this.downloadService.getFiles(dossier, parent).then(() => {
            let name: string;
            if (parent) {
                name = parent.affair.label + '_' + parent.step.label + '_documents.zip';
            } else {
                name = dossier.affair.label + '_' + dossier.step.label + '_documents.zip';
            }
        });
    }

    more(dossier: Dossier, type: string, vcRef: ViewContainerRef, menuType: string = 'dossier'): Observable<any> {
        return new Observable<any>(subscriber => {
            subscriber.next();
        });
    }

    // tslint:disable-next-line:max-line-length
    moreSpecific(dossier: DossierSpecificDetail|DossierSpecificList, type: string, vcRef: ViewContainerRef): Observable<any> {
        return new Observable<any>(subscriber => {
            subscriber.next();
        });
    }
}
