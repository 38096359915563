<div *ngIf="isGrid" class="d-flex flex-column bg-primary-light position-relative rounded overflow-hidden">
    <div *ngIf="affair.nbDiscutions > 0" class="list-affair-notification">
        <i class="far fa-comment"></i> {{affair.nbDiscutions}}
    </div>
    <!-- header du bloque affair -->
    <div class="d-flex justify-content-between align-items-start ">
        <a [routerLink]="isActionBusy ? null : ['/affaire', affair.id]" matRipple [matRippleDisabled]="isActionBusy"
           class="d-flex flex-row flex-fill justify-content-between pl-3 pt-3 pb-2 has-ripple has-ripple-dark text-decoration-none text-reset">
            <span class="fz-18 font-weight-bold" [class.text-muted]="isActionBusy">{{affair.label}}</span>
            <span class="d-flex align-items-start pr-2">
                <span class="d-inline-flex rounded fz-12 bg-white px-2 py-1 ml-2" [class.text-muted]="isActionBusy">{{getStateLabel(affair.state)}}</span>
            </span>
        </a>
        <button *ngIf="affair.isChargeAffair" [disabled]="isActionBusy" mat-icon-button [matMenuTriggerFor]="editMenu" [matMenuTriggerData]="{affair: affair}"
                class="align-self-start mt-2">
            <mat-spinner *ngIf="isActionBusy" class="btn-spinner"></mat-spinner>
            <mat-icon *ngIf="!isActionBusy">more_vert</mat-icon>
        </button>
    </div>

    <a [routerLink]="isActionBusy ? null : ['/affaire', affair.id]" matRipple [matRippleDisabled]="isActionBusy"
       class="d-flex flex-row flex-wrap text-left cursor-pointer px-3 pt-2 pb-3 has-ripple has-ripple-dark text-decoration-none text-reset">
        <div class="d-flex flex-column pr-3">
            <img *ngIf="affair.imageUrl" [src]="affair.imageUrl" width="120" height="auto" alt="{{affair.label}}" class="mb-2 align-self-center">
            <div *ngIf="affair.stepLabel.step" [class.text-muted]="isActionBusy" class="bg-white text-center rounded mt-auto list-affair-step-list p-2">{{affair.stepLabel.step.label}}</div>
            <div *ngIf="affair.stepLabel.avancement" class="affair-grid-progress">
                <div *ngIf="affair.stepLabel.avancement[3].pourcent > 0" class="bg-green"
                     [style]="'width: ' + affair.stepLabel.avancement[3].pourcent + '%'"></div>
                <div *ngIf="affair.stepLabel.avancement[2].pourcent > 0" class="bg-yellow"
                     [style]="'width: ' + affair.stepLabel.avancement[2].pourcent + '%'"></div>
                <div *ngIf="affair.stepLabel.avancement[1].pourcent > 0" class="bg-red"
                     [style]="'width: ' + affair.stepLabel.avancement[1].pourcent + '%'"></div>
            </div>
        </div>
        <div class="d-flex flex-column" [class.text-muted]="isActionBusy">
            <div>• {{affair.reference}}</div>
            <div class="mb-2">{{affair.postalCode}}</div>
            <ul class="list-unstyled mt-auto mb-0 fz-12">
                <li *ngIf="affair.dateOffer">Offre : {{ convertDate(affair.dateOffer) }}</li>
                <li *ngIf="affair.datePose">Pose : {{ convertDate(affair.datePose) }}</li>
            </ul>
        </div>
    </a>
</div>

<div *ngIf="!isGrid" class="d-flex flex-row bg-primary-light position-relative rounded px-2">
    <div *ngIf="affair.nbDiscutions > 0" class="list-affair-notification list-affair-notification-list">
        <i class="far fa-comment"></i> {{affair.nbDiscutions}}
    </div>
    <a [routerLink]="isActionBusy ? null : ['/affaire', affair.id]" [class.disabled]="isActionBusy" class="d-flex flex-row flex-fill text-left cursor-pointer py-2 text-decoration-none text-reset">
        <div class="d-flex flex-column justify-content-center mr-3 list-affair-img-container">
            <img *ngIf="affair.imageUrl" [src]="affair.imageUrl" width="auto" height="80" alt="{{affair.label}}" class="list-affair-img-list">
        </div>
        <div class="d-flex flex-fill flex-column justify-content-center" [class.text-muted]="isActionBusy">
            <div class="fz-18 font-weight-bold">{{affair.label}}</div>
            <div>• {{affair.reference}}</div>
            <div class="mb-2">{{affair.postalCode}}</div>
        </div>
        <div class="d-flex align-items-center">
            <span class="d-inline-block rounded fz-12 bg-white px-2 py-1 ml-2" [class.text-muted]="isActionBusy">{{getStateLabel(affair.state)}}</span>
        </div>
        <div class="d-flex flex-column justify-content-center px-3" [class.text-muted]="isActionBusy">
            <ul class="list-unstyled fz-12">
                <li *ngIf="affair.dateOffer">Offre : {{ convertDate(affair.dateOffer) }}</li>
                <li *ngIf="affair.datePose">Pose : {{ convertDate(affair.datePose) }}</li>
            </ul>
        </div>
        <div class="d-flex flex-column justify-content-center px-3">
            <div *ngIf="affair.stepLabel.step"
                 class="bg-white text-center rounded list-affair-step-list p-2" [class.text-muted]="isActionBusy">{{affair.stepLabel.step.label}}</div>
            <div *ngIf="affair.stepLabel.avancement" class="affair-grid-progress">
                <div *ngIf="affair.stepLabel.avancement[3].pourcent > 0" class="bg-green"
                     [style]="'width: ' + affair.stepLabel.avancement[3].pourcent + '%'"></div>
                <div *ngIf="affair.stepLabel.avancement[2].pourcent > 0" class="bg-yellow"
                     [style]="'width: ' + affair.stepLabel.avancement[2].pourcent + '%'"></div>
                <div *ngIf="affair.stepLabel.avancement[1].pourcent > 0" class="bg-red"
                     [style]="'width: ' + affair.stepLabel.avancement[1].pourcent + '%'"></div>
            </div>
        </div>
    </a>
    <div *ngIf="affair.isChargeAffair" class="d-flex flex-column justify-content-center py-2">
        <button mat-icon-button [disabled]="isActionBusy" [matMenuTriggerFor]="editMenu" [matMenuTriggerData]="{affair: affair}"
                class="align-self-start">
            <mat-spinner *ngIf="isActionBusy" class="btn-spinner"></mat-spinner>
            <mat-icon *ngIf="!isActionBusy">more_vert</mat-icon>
        </button>
    </div>
</div>

<mat-menu #editMenu="matMenu" xPosition="before">
    <ng-template matMenuContent let-affair="affair">
        <a [routerLink]="['/affair-form', affair.id]" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Éditer</span>
        </a>
        <button (click)="onTapDelete(affair)" mat-menu-item color="warn">
            <mat-icon class="text-danger">delete_outline</mat-icon>
            <span>Supprimer</span>
        </button>
    </ng-template>
</mat-menu>
