import {Component, ElementRef, Input, NgZone, OnChanges, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {User} from '@src/app/model/user.model';
import {SimpleChanges, UsersResult} from '@src/app/shared/interfaces';
import {PageEvent} from '@angular/material/paginator';
import {Observable, Subscription} from 'rxjs';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatSidenavContent} from '@angular/material/sidenav';

@Component({
    selector: 'app-annuaire-user',
    templateUrl: './annuaire-user.component.web.html',
    styleUrls: ['./annuaire-user.component.web.scss'],
})
export class AnnuaireUserComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() viewContainerRef: ViewContainerRef;
    @Input() usersResult$: Observable<UsersResult>;
    @Input() container: MatSidenavContent;

    @ViewChild('stickyMenu') menuElement: ElementRef;

    title = 'Utilisateurs';
    mode = 'over';
    backdrop = true;
    users: User[];
    isBusy: boolean;
    search: string;
    selectedUser: User;
    filterSubscription: Subscription;
    userSubscription: Subscription;
    sticky: boolean;

    // MatPaginator Inputs
    length = 50;
    pageSize = 50;
    pageIndex = 0;
    pageSizeOptions: number[] = [10, 25, 50, 100];

    constructor(
        private router: Router,
        private apiService: ApiService,
        private zone: NgZone,
    ) {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.getUser();
        this.container.elementScrolled().subscribe((event: Event) => {
            this.zone.run(() => {
                if (this.pageSize > 10) {
                    const offset = this.container.measureScrollOffset('top');
                    this.sticky = offset >= 125;
                }
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.usersResult$ && changes.usersResult$.currentValue) {
            this.isBusy = true;
            this.selectedUser = null;
            this.pageIndex = 0;
            if (typeof this.userSubscription !== 'undefined') { this.userSubscription.unsubscribe(); }
            if (typeof this.filterSubscription !== 'undefined') { this.filterSubscription.unsubscribe(); }
            this.filterSubscription = changes.usersResult$.currentValue.subscribe((usersResult: UsersResult) => {
                this.length = usersResult.total;
                this.users = usersResult.users;
                this.isBusy = false;
            });
        }
    }

    pageEventHandler(pageEvent: PageEvent) {
        this.pageIndex = pageEvent.pageIndex;
        this.pageSize = pageEvent.pageSize;
        this.selectedUser = null;
        this.getUser(pageEvent.pageIndex + 1, pageEvent.pageSize);
    }

    getUser(page: number = 1, itemPerPage: number = 50) {
        this.isBusy = true;
        const filter = this.storageService.userListFilter;
        const isFilterActif = !!(filter && (filter.name || filter.society || filter.trade || filter.visible));
        this.userSubscription = this.apiService.getUsersAnnuaire({filter: isFilterActif ? filter : []}, page, itemPerPage)
            .subscribe((usersResult: UsersResult) => {
                this.length = usersResult.total;
                this.users = usersResult.users;
                this.isBusy = false;
            });
    }

    userTappedHandler(user: User) {
        this.selectedUser = user;
    }

    userEmittedHandler(user: User) {
        this.users = this.users.map(u => u.id === user.id ? user : u);
    }

    userDeletedHandler(user: User) {
        this.users = this.users.filter(u => u.id !== user.id);
    }
}
