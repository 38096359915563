<div class="bg-white d-flex flex-row justify-content-between align-items-center position-relative shadow-sm px-3"
     (keyup.arrowLeft)="updateDocumentHistorique(documentHistorique.previousLink)"
     (keyup.arrowRight)="updateDocumentHistorique(documentHistorique.nextLink)" >
    <h3>{{documentHistorique.name}}</h3>
    <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
</div>
<div class="position-relative h-100"
     (keyup.arrowLeft)="updateDocumentHistorique(documentHistorique.previousLink)"
     (keyup.arrowRight)="updateDocumentHistorique(documentHistorique.nextLink)">
    <iframe *ngIf="url" [src]="url" frameborder="0" ></iframe>

    <button *ngIf="isNavPossible" class="btn-prev-img shadow-sm" (click)="updateDocumentHistorique(documentHistorique.previousLink)">
        <mat-icon class="text-white p-2 cursor-pointer">chevron_left</mat-icon>
    </button>
    <button *ngIf="isNavPossible" class="btn-next-img shadow-sm" (click)="updateDocumentHistorique(documentHistorique.nextLink)">
        <mat-icon class="text-white p-2 cursor-pointer">chevron_right</mat-icon>
    </button>
</div>
<!--<iframe src="https://drive.google.com/file/d/1VW-D9c7hSJ7uoqs4dPVBiGU2Xc12_1Qm/view" width="100%" height="100%" frameborder="0"></iframe>-->
