import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {User} from '@src/app/model/user.model';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {AlertService} from '@src/app/services/alert.service';
import {ApiService} from '@src/app/services/api.service';
import {ExternalAppService} from '@src/app/services/external-app.service';
import {ModalService} from '@src/app/services/modal.service';
import {ToastService} from '@src/app/services/toast.service';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
    selector: 'app-annuaire-user-item',
    templateUrl: './annuaire-user-item.component.html',
    styleUrls: ['./annuaire-user-item.component.scss'],
    providers: [ActionBottomSheetService]
})
export class AnnuaireUserItemComponent extends BaseComponent implements OnInit {

    @Input() viewContainerRef: ViewContainerRef;
    @Input() user: User;
    @Output() userTapped = new EventEmitter<User>();
    @Output() userEmitted = new EventEmitter<User>();
    @Output() userDeleted = new EventEmitter<User>();

    isActionBusy: boolean;

    constructor(
        private alertService: AlertService,
        private externalAppService: ExternalAppService,
        private modalService: ModalService,
        private toastService: ToastService,
        private apiService: ApiService,
        private toggleBottomSheetService: ActionBottomSheetService,
    ) {
        super();
    }

    ngOnInit(): void {
    }

    onTapUser(): void {
        this.userTapped.emit(this.user);
    }

    onTapPhone() {
        this.externalAppService.phone(this.user.contact.phone);
    }

    onTapEmail() {
        this.externalAppService.email(this.user.contact.email);
    }

    onTapEditToUser() {
        this.modalService.show(
            {user: this.user},
            'user',
            null,
            false
        ).subscribe((result: User) => {
            if (result) {
                this.user = result;
                this.userEmitted.emit(result);
            }
        });
    }

    onTapEdit() {
        this.modalService.show(
            {user: this.user},
            this.user.visible ? 'user' : 'guest',
            null,
            false
        ).subscribe((result: User) => {
            if (result) {
                this.user = result;
                this.userEmitted.emit(result);
            }
        });
    }

    onTapEditModal() {
        this.modalService.show(
            {
                id: this.user.id,
                type: this.user.visible ? 'user-edit' : 'guest-edit',
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.user = result;
                this.userEmitted.emit(result);
            }
        });
    }

    onTapEditToUserModal() {
        this.modalService.show(
            {
                id: this.user.id,
                type: 'user-edit',
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.user = result;
                this.userEmitted.emit(result);
            }
        });
    }

    onTapDelete() {
        this.isActionBusy = true;
        const text = `Vous allez définitivement supprimer un utilisateur.
        Vous ne pourrez pas récupérer cet utilisateur !`;
        this.alertService.show(text).then((result) => {
            if (result.isConfirmed) {
                this.apiService.deleteUser(this.user.id).subscribe(() => {
                    this.isActionBusy = false;
                    this.toastService.show(`L'utilisateur "${this.user.fullName}" a bien été supprimer.`);
                    this.userDeleted.emit(this.user);
                }, () => this.isActionBusy = false);
            }
        });
    }

    onTapMore() {
        this.toggleBottomSheetService.showBottomSheet(
            'annuaire-user',
            this.user,
            {},
            this.viewContainerRef
        ).subscribe(result => {
            if (result && result.action) {
                setTimeout(() => {
                    switch (result.action) {
                        case 'onTapPhone':
                            this.onTapPhone();
                            break;
                        case 'onTapEmail':
                            this.onTapEmail();
                            break;
                        case 'onTapEdit':
                            this.onTapEditModal();
                            break;
                        case 'onTapEditToUser':
                            this.onTapEditToUserModal();
                            break;
                        case 'onTapDelete':
                            this.onTapDelete();
                            break;
                    }
                }, 300);
            }
        });
    }
}
