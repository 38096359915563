<div class="bg-primary-light rounded px-3 py-2">
    <div class="row no-gutters flex-nowrap pb-2">
        <div class="col-auto d-flex align-items-center">
            <button *ngIf="specific.versions.length > 1" [disabled]="isActionBusy"
                    [matMenuTriggerFor]="versionMenu" [matMenuTriggerData]="{specific: specific}"
                    mat-icon-button class="version-chiffrage">
                {{specific.version}}
            </button>
            <span *ngIf="specific.versions.length == 1" [class.disabled]="isActionBusy" class="version-chiffrage">
                {{specific.version}}
            </span>
        </div>
        <div class="col-auto d-flex flex-fill justify-content-center align-items-center px-1">
            <a [disabled]="isActionBusy" [routerLink]="['/specific-detail', specific.versions[specific.versions.length - 1].id]"
               mat-button matTooltip="Détails de la demande" matTooltipPosition="above" class="mat-button-pre-icon">
                <mat-icon class="mr-1" [ngClass]="{'text-primary': !isActionBusy, 'text-muted': isActionBusy}">remove_red_eye</mat-icon>
                <span class="fz-16 text-wrap">{{specific.label}}</span>
            </a>
        </div>
        <div class="col-auto">
            <button *ngIf="dossier.isChargeAffair" [disabled]="isActionBusy"  mat-icon-button
                    [matMenuTriggerFor]="specificMenu" [matMenuTriggerData]="{specific: specific, dossier: dossier}">
                <mat-spinner *ngIf="isActionBusy" class="btn-spinner"></mat-spinner>
                <mat-icon *ngIf="!isActionBusy">more_vert</mat-icon>
            </button>
        </div>
    </div>

    <div *ngFor="let specificUser of specific.users; trackBy: trackBy" class="row no-gutters flex-nowrap align-items-center mb-1">
        <div class="col-auto pr-2">
            <span [class.invisible]="specificUser.dossier.nbNotifications.total == 0" class="d-inline-flex badge justify-content-center align-items-center">
                {{specificUser.dossier.nbNotifications.total}}
            </span>
        </div>
        <div class="col text-truncate">
            <a [disabled]="isActionBusy" [routerLink]="['/specific-detail', specific.versions[specific.versions.length - 1].id, specificUser.user.id]" mat-flat-button [color]="userTypes[specificUser.type]" class="w-100 text-left">{{specificUser.user.fullName}}</a>
        </div>
    </div>
</div>

<mat-menu #versionMenu="matMenu" xPosition="after">
    <ng-template matMenuContent let-specific="specific">
        <a *ngFor="let version of specific.versions" mat-menu-item [routerLink]="['/specific-detail', version.id]">
            <mat-icon>drive_file_move</mat-icon>
            <span>{{version.label}}</span>
        </a>
    </ng-template>
</mat-menu>

<mat-menu #specificMenu="matMenu">
    <ng-template matMenuContent let-specific="specific" let-dossier="dossier">
        <button mat-menu-item (click)="onTapEdit(specific)">
            <mat-icon>edit</mat-icon>
            <span>Modifier</span>
        </button>
        <button mat-menu-item (click)="onTapAuthorize(specific)">
            <mat-icon>admin_panel_settings</mat-icon>
            <span>Inviter</span>
        </button>
        <button mat-menu-item (click)="onTapVersion(specific)">
            <mat-icon>create_new_folder</mat-icon>
            <span>Nouvelle version</span>
        </button>
        <button *ngIf="dossier.isChargeAffair" mat-menu-item (click)="onTapDelete(specific)">
            <mat-icon class="text-danger">delete_outline</mat-icon>
            <span>Supprimer</span>
        </button>
    </ng-template>
</mat-menu>
