import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {Router} from '@angular/router';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {AlertService} from '@src/app/services/alert.service';
import {ExternalAppService} from '@src/app/services/external-app.service';
import {ModalService} from '@src/app/services/modal.service';
import {Society} from '@src/app/model/society.model';

@Component({
    selector: 'app-annuaire-society-item',
    templateUrl: './annuaire-society-item.component.html',
    styleUrls: ['./annuaire-society-item.component.scss'],
    providers: [ActionBottomSheetService]
})
export class AnnuaireSocietyItemComponent extends BaseComponent implements OnInit {

    @Input() viewContainerRef: ViewContainerRef;
    @Input() society: Society;
    @Output() societyTapped = new EventEmitter<Society>();
    @Output() societyEmitted = new EventEmitter<Society>();
    @Output() societyDeleted = new EventEmitter<Society>();

    isActionBusy: boolean;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private alertService: AlertService,
        private toggleBottomSheetService: ActionBottomSheetService,
        private externalAppService: ExternalAppService,
        private modalService: ModalService,
    ) {
        super();
    }

    ngOnInit(): void {
    }

    onTapSociety(): void {
        this.societyTapped.emit(this.society);
    }

    onTapPhone() {
        this.externalAppService.phone(this.society.phone);
    }

    onTapEmail() {
        this.externalAppService.email(this.society.email);
    }

    onTapEdit() {
        this.modalService.show(
            {society: this.society},
            'society',
            null,
            false
        ).subscribe((result: Society) => {
            if (result) {
                this.society = result;
                this.societyEmitted.emit(result);
            }
        });
    }

    onTapEditModal() {
        this.modalService.show(
            {
                id: this.society.id,
                type: 'society-edit',
            },
            'base-modal',
            this.viewContainerRef,
            true
        ).subscribe((result) => {
            if (result) {
                this.society = result;
                this.societyEmitted.emit(result);
            }
        });
    }

    onTapDelete() {
        this.isActionBusy = true;
        const test = `Vous allez définitivement supprimer une entreprise.
        Cela supprimera aussi tous les contacts rattachés à cette entreprise.
        Vous ne pourrez pas récupérer cette entreprise !`;
        this.alertService.show(test).then((result) => {
            if (result.isConfirmed) {
                this.apiService.deleteSociety(this.society.id).subscribe(() => {
                    this.isActionBusy = false;
                    this.toastService.show(`L'entreprise "${this.society.name}" a bien été supprimer.`);
                    this.societyDeleted.emit(this.society);
                }, () => this.isActionBusy = false);
            }
        });
    }

    onTapMore() {
        this.toggleBottomSheetService.showBottomSheet(
            'annuaire-society',
            this.society,
            {},
            this.viewContainerRef
        ).subscribe(result => {
            if (result && result.action) {
                setTimeout(() => {
                    switch (result.action) {
                        case 'onTapPhone':
                            this.onTapPhone();
                            break;
                        case 'onTapEmail':
                            this.onTapEmail();
                            break;
                        case 'onTapEdit':
                            this.onTapEditModal();
                            break;
                        case 'onTapDelete':
                            this.onTapDelete();
                            break;
                    }
                }, 300);
            }
        });
    }
}
