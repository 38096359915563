import {Component, OnInit, OnDestroy, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {User} from '@src/app/model/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FormService} from '@src/app/services/form.service';

@Component({
    selector: 'app-guest-modal-view-content',
    templateUrl: 'guest-modal-view-content.component.web.html',
})
export class GuestModalViewContentComponent implements OnInit, OnDestroy {

    title = 'Ajouter un invité';
    notifier = new Subject();
    isBusy: boolean;
    isSaving = false;
    user: User;
    userForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<GuestModalViewContentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {user: User},
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastService: ToastService,
        private formService: FormService,
    ) {
        if (this.data.user?.id) { this.title = 'Modifier un invité'; }
    }

    ngOnInit(): void {
        this.isBusy = true;
        if (this.data.user?.id) {
            this.apiService.getUser(this.data.user.id)
                .pipe(takeUntil(this.notifier))
                .subscribe((user) => {
                    this.user = user;
                    this.createForm();
                });
        } else {
            this.user = new User();
            this.createForm();
        }
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    onClose(args: User = null) {
        this.formService.user = args;
        this.dialogRef.close(args);
    }

    createForm() {
        this.isBusy = false;
        this.userForm = new FormGroup({
            name: new FormControl(this.user.contact.name),
            surname: new FormControl(this.user.contact.surname),
            email: new FormControl(this.user.contact.email),
            phone: new FormControl(this.user.contact.phone),
            libre: new FormControl(this.user.contact.libre),
            hasUser: new FormControl(1),
        });
    }

    onSubmit(): void {
        if (this.userForm.valid) {
            this.isSaving = true;
            const data = this.userForm.value;
            if (this.data.user?.id) {
                delete data.hasUser;
                this.apiService.putGuest(data, this.user.contact.id)
                    .pipe(takeUntil(this.notifier))
                    .subscribe((user: User) => {
                        this.isSaving = false;
                        if (user) {
                            this.toastService.show(`L'invité "${user.fullName}" a été modifié.`);
                            this.onClose(user);
                        }
                    }, () => this.isSaving = false);
            } else {
                this.apiService.postGuest(data)
                    .pipe(takeUntil(this.notifier))
                    .subscribe((user: User) => {
                        this.isSaving = false;
                        if (user) {
                            this.toastService.show(`L'invité "${user.fullName}" a été ajouté.`);
                            this.onClose(user);
                        }
                    }, () => this.isSaving = false);
            }
        }
    }

    private formatArray(array) {
        if (array) {
            return array.map(i => i.hasOwnProperty('id') ? i.id : i);
        }
        return null;
    }
}
