<div class="d-flex flex-row justify-content-start align-items-center text-left bg-primary-light rounded my-2 pl-3 py-1">
    <div (click)="onTapSociety()" class="d-flex flex-row justify-content-start align-items-center cursor-pointer w-100">
        <div class="d-flex flex-row align-items-center">
            <mat-icon>business</mat-icon>
            <span class="ml-1 mr-3">{{society.name}}</span>
            <span class="chip__blue fz-12">
                {{society.contacts.length}} Contact{{society.contacts.length > 1 ? 's' : ''}}
            </span>
        </div>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menuSociety" [matMenuTriggerData]="{society: society}"
            matTooltip="Menu édition" matTooltipPosition="above" class="ml-auto">
        <mat-icon>more_vert</mat-icon>
    </button>
</div>

<mat-menu #menuSociety="matMenu" xPosition="before">
    <ng-template matMenuContent let-society="society">
        <a *ngIf="society.phone" mat-menu-item href="tel:{{society.phone}}">
            <mat-icon>phone</mat-icon>
            <span>{{society.phone}}</span>
        </a>
        <a *ngIf="society.email" mat-menu-item href="mailto:{{society.email}}">
            <mat-icon>mail_outline</mat-icon>
            <span>{{society.email}}</span>
        </a>
        <button mat-menu-item (click)="onTapEdit()">
            <mat-icon>edit</mat-icon>
            <span>Modifier</span>
        </button>
        <button mat-menu-item (click)="onTapDelete()">
            <mat-icon class="text-danger">delete_outline</mat-icon>
            <span>Supprimer</span>
        </button>
    </ng-template>
</mat-menu>
