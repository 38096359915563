import {Injectable} from '@angular/core';
import {Dossier} from '@src/app/model/dossier.model';
import {DocumentHistorique} from '@src/app/model/document-historique.model';

@Injectable({
    providedIn: 'root'
})
export class UploadImageService {

    constructor() {
    }

    public uploadFile(type: string): Promise<any> {
        return new Promise<any>((resolve, reject) => resolve(null));
    }

    public uploadMedia(type: string): Promise<any> {
        return new Promise<any>((resolve, reject) => resolve(null));
    }

    public uploadCamera(type: string): Promise<any> {
        return new Promise<any>((resolve, reject) => resolve(null));
    }

    public uploadRequest(data: Dossier, event): Promise<any> {
        return new Promise<any>((resolve, reject) => resolve(null));
    }

    public updateRequest(data: DocumentHistorique, event): Promise<any> {
        return new Promise<any>((resolve, reject) => resolve(null));
    }

    public uploadImage(id, callback) {
        // only webapp
    }

    public updateImage(id, callback) {
        // only webapp
    }
}
