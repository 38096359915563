<div fxFlex fxLayout="column" class="bg-white">
    <mat-paginator *ngIf="users" [length]="length"
            [pageSize]="pageSize" [pageIndex]="pageIndex"
            [pageSizeOptions]="pageSizeOptions"
            [showFirstLastButtons]="true"
            (page)="pageEventHandler($event)">
    </mat-paginator>

    <div fxFlex fxLayoutAlign="center center" *ngIf="isBusy" class="p-5">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <!-- nouvelle liste -->
    <div class="container-fluid">
        <div *ngIf="!isBusy" class="row">
            <div class="{{selectedUser ? 'col-lg-7' : 'col-lg-12'}}">
                <app-annuaire-user-item *ngFor="let user of users" [user]="user" [viewContainerRef]="viewContainerRef"
                                        (userDeleted)="userDeletedHandler($event)" (userEmitted)="userEmittedHandler($event)"
                                        (userTapped)="userTappedHandler($event)"></app-annuaire-user-item>
            </div>
            <div class="col-lg-5">
                <app-annuaire-user-detail #stickyMenu [user]="selectedUser" [viewContainerRef]="viewContainerRef" [class.sticky]="sticky"></app-annuaire-user-detail>
            </div>
        </div>
    </div>

    <mat-paginator *ngIf="users" [length]="length"
            [pageSize]="pageSize" [pageIndex]="pageIndex"
            [pageSizeOptions]="pageSizeOptions"
            [showFirstLastButtons]="true"
            (page)="pageEventHandler($event)">
    </mat-paginator>
</div>
