<form fxFlex fxLayout="column" fxLayoutAlign="center center" class="example-form">
    <img src="/assets/YFERE_LogoCouleurTexte.png" alt="YFERE" height="100px" class="mb-3">
    <div *ngIf="isAuthenticating" class="p-5">
        <mat-progress-spinner mode="indeterminate" class="m-auto"></mat-progress-spinner>
    </div>
    <div *ngIf="!isAuthenticating">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Adresse email</mat-label>
            <input matInput name="email" type="email" [disabled]="isAuthenticating" [(ngModel)]="user.email"
                   autocorrect="false" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
        </mat-form-field>
    </div>
    <div *ngIf="!isAuthenticating">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Mot de passe</mat-label>
            <input matInput name="password" type="password" [disabled]="isAuthenticating" [(ngModel)]="user.password"
                   required>
        </mat-form-field>
    </div>
    <div *ngIf="!isAuthenticating" class="text-center">
        <button mat-raised-button color="primary" type="submit" (click)="submit()">Connexion</button>
    </div>
    <div *ngIf="!isAuthenticating" class="text-center mt-3">
        <a mat-button color="primary" routerLink="/reset-password">
            <mat-icon>lock</mat-icon> Mot de passe oublié
        </a>
    </div>
</form>
