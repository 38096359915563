import {Injectable} from '@angular/core';
import {AffairFilter, FilterAuthorize} from '@src/app/shared/interfaces';
import {User} from '@src/app/model/user.model';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {Dossier} from '@src/app/model/dossier.model';
import {Affair} from '@src/app/model/affair.model';
import {Society} from '@src/app/model/society.model';
import {Contact} from '@src/app/model/contact.model';

@Injectable({
    providedIn: 'root'
})
export class FormService {
    private _affair: Affair;
    private _user: User;
    private _contact: Contact;
    private _society: Society;
    private _selected: User[]|Dossier[]|DocumentHistorique[]|any[]|any;
    private _options: User[]|Dossier[]|DocumentHistorique[]|any[];
    private _filter: FilterAuthorize;
    private _affairFilter: AffairFilter;
    private _shortcuts: any;

    public remove(names: string[]): void {
        for (const name of names) {
            this[name] = null;
        }
    }

    get affair(): Affair {
        return this._affair;
    }

    set affair(value: Affair) {
        this._affair = value;
    }

    get user(): User {
        return this._user;
    }

    set user(value: User) {
        this._user = value;
    }

    get contact(): Contact {
        return this._contact;
    }

    set contact(value: Contact) {
        this._contact = value;
    }

    get society(): Society {
        return this._society;
    }

    set society(value: Society) {
        this._society = value;
    }

    get selected(): User[] | Dossier[] | DocumentHistorique[] | any[] | any {
        return this._selected;
    }

    set selected(value: User[] | Dossier[] | DocumentHistorique[] | any[] | any) {
        this._selected = value;
    }

    get options(): User[] | Dossier[] | DocumentHistorique[] | any[] {
        return this._options;
    }

    set options(value: User[] | Dossier[] | DocumentHistorique[] | any[]) {
        this._options = value;
    }

    get filter(): FilterAuthorize {
        return this._filter;
    }

    set filter(value: FilterAuthorize) {
        this._filter = value;
    }

    get affairFilter(): AffairFilter {
        return this._affairFilter;
    }

    set affairFilter(value: AffairFilter) {
        this._affairFilter = value;
    }

    get shortcuts(): any {
        return this._shortcuts;
    }

    set shortcuts(value: any) {
        this._shortcuts = value;
    }
}
