<div [ngSwitch]="data.type">
    <mat-card *ngSwitchCase="'ADD_FILE'" class="mat-elevation-z0 feed-item">
        <mat-card-header>
            <div class="feed-item__avatar" mat-card-avatar>
                <span>{{ data.user.initiale }}</span>
            </div>
            <mat-card-title>{{ data.user.fullName }}.</mat-card-title>
            <mat-card-subtitle>{{ data.datetime | date:'dd/MM/yyyy - H:mm' }}</mat-card-subtitle>
            <div fxFlex></div>
            <button mat-icon-button (click)="toggle()">
                <mat-icon *ngIf="!open">arrow_drop_down</mat-icon>
                <mat-icon *ngIf="open">arrow_drop_up</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content *ngIf="open">
            <img *ngIf="isAttachmentImage(data.documentHistorique.type)" [src]="data.documentHistorique.path"
                 [alt]="data.documentHistorique.label"/>
            <p *ngIf="!isAttachmentImage(data.documentHistorique.type)" class="feed-item__attachment-link">
                <i class="material-icons">insert_drive_file</i>
                <a [href]="data.documentHistorique.path" download>{{ data.documentHistorique.label }}</a>
            </p>
            <p>
                ajouté à l'affaire&nbsp;<a [routerLink]="['/affaire', data.affair.id]">{{ data.affair.label }}</a>
            </p>
            <p>
                <i class="material-icons">subdirectory_arrow_right</i>
                <a [routerLink]="['/affair-dossier', data.dossier.id]">{{ data.dossier.label }}</a>
            </p>
            <p *ngIf="data.comment" class="mat-caption feed-item__chat-message">
                <i class="material-icons">chat_bubble_outline</i>{{ data.comment }}
            </p>
        </mat-card-content>
    </mat-card>

    <mat-card *ngSwitchDefault class="mat-elevation-z0 feed-item">
        <mat-card-header>
            <div class="feed-item__avatar" mat-card-avatar>
                <span>{{ data.user.initiale }}</span>
            </div>
            <mat-card-title>{{ data.user.fullName }}.</mat-card-title>
            <mat-card-subtitle>{{ data.datetime | date:'dd/MM/yyyy - H:mm' }}</mat-card-subtitle>
            <div fxFlex></div>
            <button mat-icon-button (click)="toggle()">
                <mat-icon *ngIf="!open">arrow_drop_down</mat-icon>
                <mat-icon *ngIf="open">arrow_drop_up</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content *ngIf="open">
            <p>
                <a [routerLink]="['/affaire', data.affair.id]">Consultation</a>&nbsp;&nbsp;
                <span *ngIf="data.type.includes('VALID')"
                      class="feed-item__status feed-item__status--success">Validée</span>
                <span *ngIf="data.type.includes('REFUSE')"
                      class="feed-item__status feed-item__status--error">Refusée</span>
            </p>
            <p *ngIf="data.type.includes('SOCIETY')">
                <i class="material-icons">subdirectory_arrow_right</i>
                <a [routerLink]="['/affair-dossier', data.dossier.id]">{{ data.dossier.label }}</a>
            </p>
        </mat-card-content>
    </mat-card>
</div>
