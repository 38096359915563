<div class="vw-100">
    <mat-toolbar *ngIf="connected" color="primary">
        <button *ngIf="!hasRoles(['ROLE_GUEST'])" mat-icon-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <a routerLink="/home" class="ml-2">
            <img src="/assets/YFERE_LogoBlanc.png" alt="YFERE" height="40px">
        </a>
        <span class="toolbar-spacer"></span>
        <span>{{title}}</span>
        <span class="toolbar-spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="navMenu" class="btn-toolbar-initiale">
            <span>{{initiale}}</span>
        </button>
        <mat-menu #navMenu="matMenu">
            <ng-template matMenuContent>
                <button *ngIf="!hasRoles(['ROLE_GUEST'])" mat-menu-item routerLink="/account">
                    <mat-icon>account_circle</mat-icon>
                    <span>Profil</span>
                </button>
                <button *ngIf="!hasRoles(['ROLE_GUEST'])" disabled mat-menu-item>
                    <mat-icon>settings</mat-icon>
                    <span>Administration</span>
                </button>
                <button mat-menu-item (click)="onDisconnectTap()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Déconnexion</span>
                </button>
            </ng-template>
        </mat-menu>
    </mat-toolbar>
    <mat-sidenav-container autosize [hasBackdrop]="backdrop" fxFill>
        <mat-sidenav #drawer class="main-sidebar" fxLayout="column" [mode]="mode" [opened]="connected && !backdrop">
            <div class="mb-4" fxLayoutAlign="center center">
                <a fxFlexFill mat-flat-button color="primary" routerLink="/home">
                    <mat-icon>dashboard</mat-icon>
                    Tableau de bord</a>
            </div>
            <nav class="mb-4 main-nav">
                <div class="nav-title">Affaires</div>
                <ul class="nav-list">
                    <li matRipple class="has-ripple overflow-hidden nav-item">
                        <a fxLayout="row" fxLayoutAlign="start center" routerLink="/affaires"
                           routerLinkActive="active" (click)="drawer.close()">
                            <mat-icon color="primary">work</mat-icon>
                            Affaires
                        </a>
                    </li>
                    <li *ngIf="hasRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_AFFAIRE', 'ROLE_SOCIETY'])"
                        matRipple class="has-ripple overflow-hidden nav-item">
                        <a fxLayout="row" fxLayoutAlign="start center" routerLink="/annuaire/user" routerLinkActive="active"
                            (click)="drawer.close()">
                            <mat-icon color="primary">import_contacts</mat-icon>
                            Annuaires
                        </a>
                    </li>
                </ul>
            </nav>
            <nav class="mb-4 secondary-nav">
                <div class="nav-title">Services métiers</div>
                <ul class="nav-list">
                    <li class="nav-item disabled">
                        <a fxLayout="row" fxLayoutAlign="start center" disabled>
                            <mat-icon color="primary">settings</mat-icon>
                            Bureau d'étude
                        </a>
                    </li>
                    <li class="nav-item disabled">
                        <a fxLayout="row" fxLayoutAlign="start center" disabled>
                            <mat-icon color="primary">settings</mat-icon>
                            Fabrication
                        </a>
                    </li>
                    <li class="nav-item disabled">
                        <a fxLayout="row" fxLayoutAlign="start center" disabled>
                            <mat-icon color="primary">settings</mat-icon>
                            Logistique
                        </a>
                    </li>
                    <li class="nav-item disabled">
                        <a fxLayout="row" fxLayoutAlign="start center" disabled>
                            <mat-icon color="primary">settings</mat-icon>
                            Pose
                        </a>
                    </li>
                </ul>
            </nav>
            <div class="d-flex align-content-end justify-content-between align-items-end mt-auto">
                <img src="/assets/YFERE_LogoSeulTrame.png" alt="YFERE" height="40px" class="ml-2">
                <span>v. {{version}}</span>
            </div>
        </mat-sidenav>
        <div class="sidenav-content" fxLayout="column" fxLayoutAlign="start stretch">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-container>
</div>
