import {AffairStep} from '@src/app/model/affair-step.model';
import {Society} from '@src/app/model/society.model';
import {User} from '@src/app/model/user.model';
import {Trade} from '@src/app/model/trade.model';

export class AffairMember {
    roles: string[];
    status: number;
    user: User;
}

export class Affair {
    id?: number;
    label?: string;
    reference?: string;
    state?: any;
    allowTo?: {
        consult: boolean;
        drag: boolean;
    };
    description?: string;
    address?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    dates?: [{
        titre: string;
        date: string;
    }];
    dateCreated?: string;
    dateUpdated?: string;
    datePose?: string;
    dateOffer?: string;
    dateConsultation?: string;
    dateHypothese?: string;
    dateConception?: string;
    dateFabrication?: string;
    dateLivraison?: string;
    dateChantier?: string;
    dateReception?: string;
    archive?: boolean;
    stepLabel?: {
        step: {
            label: string;
        },
        avancement: {
            3: {
                pourcent: number,
                color: string
            },
            2: {
                pourcent: number,
                color: string
            },
            1: {
                pourcent: number,
                color: string
            }
        }
    };
    imageUrl?: string;
    path?: string;
    affairSteps?: AffairStep[];
    currentStep?: {
        id: number;
        label: string;
    };
    chargeAffairs?: User[];
    users?: User[];
    members?: {
        affair: AffairMember[];
        projet: AffairMember[];
    };
    societies?: Society[];
    trades?: Trade[];
    nbNotifications?: {
        new: number;
        update: number;
        total: number;
    };
    nbDiscutions?: number;
    isChargeAffair?: boolean;

    constructor(
        label?: string,
        reference?: string,
        state?: any,
        description?: string,
        chargeAffairs?: [],
        users?: [],
        address?: string,
        postalCode?: string,
        city?: string,
        country?: string,
        datePose?: string,
        dateOffer?: string,
        dateReception?: string,
        dateChantier?: string,
        dateLivraison?: string,
        dateFabrication?: string,
        dateConception?: string,
        dateHypothese?: string,
        dateConsultation?: string,
        archive?: boolean,
    ) {
        this.label = label || '';
        this.reference = reference || '';
        this.state = state || '';
        this.description = description || '';
        this.chargeAffairs = chargeAffairs || [];
        this.users = users || [];
        this.address = address || '';
        this.postalCode = postalCode || '';
        this.city = city || '';
        this.country = country || 'France';
        this.datePose = datePose || null;
        this.dateOffer = dateOffer || null;
        this.dateReception = dateReception || null;
        this.dateChantier = dateChantier || null;
        this.dateLivraison = dateLivraison || null;
        this.dateFabrication = dateFabrication || null;
        this.dateConception = dateConception || null;
        this.dateHypothese = dateHypothese || null;
        this.dateConsultation = dateConsultation || null;
        this.archive = archive || false;
        this.members = {affair: [], projet: []};
    }
}

export declare type Affairs = Affair[];

export interface AffairResponse {
    totalCount: number;
    incompleteResults: boolean;
    affairs: Affair[];
}

export const STATES = [
    {key: '1', label: 'Projet'},
    {key: '2', label: 'Chantier'},
    {key: '3', label: 'Clôturé'},
    {key: '4', label: 'Perdu'},
];

export const STEPS = [
    {key: '1', label: 'Consultation'},
    {key: '2', label: 'Validation hypothèse'},
    {key: '3', label: 'Conception'},
    {key: '4', label: 'Fabrication'},
    {key: '5', label: 'Livraison'},
    {key: '6', label: 'Chantier'},
    {key: '7', label: 'Réception chantier'},
];

export const PAYS = [
    'France',
    'Allemagne',
    'Andorre',
    'Autriche',
    'Belgique',
    'Danemark',
    'Espagne',
    'Finlande',
    'Irlande',
    'Islande',
    'Italie',
    'Liechtenstein',
    'Luxembourg',
    'Malte',
    'Monaco',
    'Norvège',
    'Pays-Bas',
    'Portugal',
    'Royaume-Uni',
    'Saint-Marin',
    'Suisse',
    'Suède',
    'Vatican'
];
