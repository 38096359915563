import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {routes} from '@src/app/app.routes';
import {AffairFormComponent} from '@src/app/affair/form/affair-form.component';
import {IsSignedInGuard} from '@src/app/shared/is-signed-in.guard';

routes.push({
    path: 'affair-form',
    component: AffairFormComponent,
    canActivate: [IsSignedInGuard],
});
routes.push({
    path: 'affair-form/:id',
    component: AffairFormComponent,
    canActivate: [IsSignedInGuard],
});

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
