import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScrollViewService {

    public centerOnSelected(stepScrollView, selectedIndex: number) {
        // Nothing here
    }
}
