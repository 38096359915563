<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">{{title}}</span>
    <button (click)="onClose()" mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div class="text-danger" [innerHTML]="message"></div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onClose()" cdkFocusInitial>Ok</button>
</div>
