<h1 mat-dialog-title >
    <span class="d-inline-block mt-1">Ajouter des raccourcis</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <mat-list>
            <mat-list-item *ngFor="let shortcut of sortBy('label'); trackBy: trackBy;" color="primary">
                <div mat-line class="d-flex align-items-center">
                    <mat-checkbox matRipple class="d-inline-flex flex-fill align-items-center pr-2 align-self-stretch rounded-sm" [checked]="!!selectedShortcutsFavoris[shortcut.id]" [disableRipple]="true" labelPosition="before" color="primary" (change)="valueChanges($event, shortcut)">
                        <span>{{shortcut.label}}</span> <span class="d-inline-flex align-items-center fz-14"  style="min-width: 55px"><mat-icon class="mr-1 text-primary">insert_drive_file</mat-icon>{{shortcut.nbDocuments}}</span>
                    </mat-checkbox>
                    <button mat-icon-button [disabled]="shortcut.nbDocuments == 0 || !!!selectedShortcutsFavoris[shortcut.id]" (click)="onTapShortcut(shortcut)">
                        <mat-icon>sort</mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div *ngIf="!isLoading" mat-dialog-actions class="justify-content-end pb-3">
    <button *ngIf="data.light" [disabled]="isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        Suivant
        <mat-icon *ngIf="!isSaving">navigate_next</mat-icon>
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
    </button>
    <button *ngIf="!data.light" [disabled]="isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">save</mat-icon>
        Enregistrer
    </button>
</div>
