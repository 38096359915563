import {Component, Inject, OnInit} from '@angular/core';
import {Activity} from '@src/app/shared/interfaces';
import {Observable} from 'rxjs';
import {ApiService} from '@src/app/services/api.service';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {StorageService} from '@src/app/services/storage.service';
import {tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-modal-activity',
    templateUrl: './modal-activity.component.web.html',
    styleUrls: ['./modal-activity.component.web.scss']
})
export class ModalActivityComponent implements OnInit {

    public activityList$: Observable<Activity[]> = null;
    public documentHistorique: DocumentHistorique;
    public initiale: string;
    public message: string;
    public isSending = false;
    public isModalLoaded = false;
    public spinner = {
        message: false,
    };

    constructor(
        public dialogRef: MatDialogRef<ModalActivityComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService,
        private storageService: StorageService,
    ) {
    }

    ngOnInit() {
        this.activityList$ = this.data.activityList$.pipe(
            tap(() => this.isModalLoaded = true)
        );
        this.documentHistorique = this.data.documentHistorique;
        this.initiale = this.storageService.initiale;
    }

    onClose(args = null) {
        this.dialogRef.close(args);
    }

    onSubmitMessage() {
        if (this.message) {
            this.isSending = true;
            this.apiService.postActivityMessage(
                this.documentHistorique.document.id,
                {message: this.message}
            ).subscribe((result) => {
                this.message = '';
                this.activityList$ = this.apiService.getActivityList(this.documentHistorique.document.id);
                this.isSending = false;
            });
        }
    }
}
