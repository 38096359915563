import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ExternalAppService {

    public phone(phoneNumber: string) {
    }

    public email(emailAddress: string) {
    }
}
