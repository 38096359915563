<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">Message de {{data.dossier.diffuseDate ? 're-diffusion' : 'diffusion'}}</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
        <form>
            <mat-form-field appearance="outline" class="input-full-width">
                <mat-label>Message</mat-label>
                <textarea matInput name="message" rows="10" [(ngModel)]="message"></textarea>
            </mat-form-field>
        </form>

        <div class="text-center">
            <button [disabled]="isSaving" mat-raised-button color="primary" (click)="onSubmit()">
                <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
                <mat-icon *ngIf="!isSaving">send</mat-icon>
                Envoyer
            </button>
        </div>
    </div>
</div>
