<h1 mat-dialog-title >
    <span class="d-inline-block mt-1">Equipe Affaire</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <div *ngIf="!members.length" class="py-2 px-3 fz-14 text-danger">
            L'équipe affaire n'est pas encore composée.
        </div>
        <div *ngFor="let member of members; let last = last;" [class.border-bottom]="!last" class="row no-gutters py-2">
            <div class="col-md-6 d-flex align-items-center fz-14 text-truncate pr-3">
                <mat-icon class="mr-1">person</mat-icon>
                <span>{{member.user.fullName}}</span>
            </div>
            <div class="col-md-6 d-flex align-items-center fz-12 text-truncate pl-3">
                <mat-icon class="mr-1">business</mat-icon>
                <span>{{member.user.labelSociety}}</span>
            </div>
            <div class="col-md-12 mt-1">
                <span *ngFor="let userTrade of member.user.trades; let last = last;" class="chip fz-10 text-nowrap" [class.mr-2]="!last">
                    {{userTrade.label}}
                </span>
            </div>
        </div>
    </div>
</div>
