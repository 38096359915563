import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Affair} from '@src/app/model/affair.model';
import * as moment from 'moment';

@Component({
    selector: 'app-affair-side-nav',
    templateUrl: './affair-side-nav.component.html',
    styleUrls: ['./affair-side-nav.component.scss']
})
export class AffairSideNavComponent {

    @Input() data: Affair;
    @Output() linkClicked = new EventEmitter<string[]>();
    open = true;

    toggle() {
        this.open = !this.open;
    }

    isAttachmentImage(type: string) {
        return type.match(/.(jpg|jpeg|png|gif)$/i);
    }

    onLinkTap(routerParams: any[]) {
        this.linkClicked.emit(routerParams);
    }

    convertDate(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }
}
