import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private snackBar: MatSnackBar) {
    }

    /**
     * @param message string
     * @param position string "top" ou "bottom"
     * @param duration string "short" 2s ou "long" 3.5s
     */
    public show(message: string, position: MatSnackBarVerticalPosition = 'top', duration: string = 'long') {
        this.snackBar.open(message, 'X', {
            duration: (duration === 'long' ? 3500 : 2000),
            verticalPosition: position,
        });
    }
}
