import {Affair} from '@src/app/model/affair.model';
import {Contact} from '@src/app/model/contact.model';
import {Trade} from '@src/app/model/trade.model';
import {RoleType} from '@src/app/shared/interfaces';

export class User {
    id?: number;
    email?: string;
    password?: string;
    fullName?: string;
    roles?: any[];
    token?: string;
    dateCreated?: string;
    initiale?: string;
    affairs?: Affair[];
    affairIds?: [];
    contact?: Contact;
    trades?: Trade[];
    labelSociety?: string;
    visible?: boolean;

    constructor(
        id?: number,
        email?: string,
        affairs?: [],
        roles?: any[],
        contact?: Contact,
    ) {
        this.id = id || null;
        this.email = email || '';
        this.affairs = affairs || [];
        this.roles = roles || [];
        this.contact = contact || new Contact();
    }
}

export const ROLES: {key: RoleType; label: string}[] = [
    {key: 'ROLE_USER', label: 'Utilisateur'},
    {key: 'ROLE_CLIENT', label: 'Client'},
    {key: 'ROLE_METIER', label: 'Utilisateur métier'},
    {key: 'ROLE_AFFAIRE', label: 'Chargé d\'affaire'},
    {key: 'ROLE_SOCIETY', label: 'Administrateur de la société'},
    {key: 'ROLE_ADMIN', label: 'Administrateur'},
    {key: 'ROLE_SUPER_ADMIN', label: 'Super Administrateur'},
];
