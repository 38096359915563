import {Injectable, ViewContainerRef} from '@angular/core';
import {ApiService} from '@src/app/services/api.service';
import {ModalService} from '@src/app/services/modal.service';
import {ToastService} from '@src/app/services/toast.service';
import {AlertService} from '@src/app/services/alert.service';
import {DownloadService} from '@src/app/services/download.service';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ActionResult} from '@src/app/shared/interfaces';
import {Dossier} from '@src/app/model/dossier.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private alertService: AlertService,
        private downloadService: DownloadService,
    ) {
    }

    // TODO move to activity service
    activity(documentHistorique: DocumentHistorique, vcRef: ViewContainerRef): void {
        const activityList$ = this.apiService.getActivityList(documentHistorique.document.id);
        this.modalService.show(
            {documentHistorique: documentHistorique, activityList$: activityList$},
            'activity',
            vcRef
        );
    }

    viewer(documentHistorique: DocumentHistorique, documentHistoriques: DocumentHistorique[], vcRef: ViewContainerRef): void {
        this.modalService.show({
            documentHistorique: documentHistorique,
            documentHistoriques: documentHistoriques
        }, 'viewer', vcRef, true);
    }

    isReaded(documentHistorique: DocumentHistorique): Observable<ActionResult> {
        return this.apiService.putDocumentRead(documentHistorique.document.id);
    }

    favoris(documentHistorique: DocumentHistorique): Observable<ActionResult> {
        return this.apiService.addFavorisDocument(documentHistorique.document.id)
            .pipe(
                tap((result: ActionResult) => {
                    if (result.success) { this.toastService.show(`Le document "${documentHistorique.name}" a été ajouté à vos favoris.`); }
                })
            );
    }

    unfavoris(documentHistorique: DocumentHistorique): Observable<ActionResult> {
        return this.apiService.deleteFavorisDocument(documentHistorique.document.id)
            .pipe(
                tap((result: ActionResult) => {
                    // tslint:disable-next-line:max-line-length
                    if (result.success) { this.toastService.show(`Le document "${documentHistorique.name}" a été supprimé de vos favoris.`); }
                })
            );
    }

    follow(documentHistorique: DocumentHistorique): Observable<ActionResult> {
        return this.apiService.addSuivisDocument(documentHistorique.document.id)
            .pipe(
                tap((result: ActionResult) => {
                    if (result.success) { this.toastService.show(`Le document "${documentHistorique.name}" a été ajouté à votre suivi.`); }
                })
            );
    }

    unfollow(documentHistorique: DocumentHistorique): Observable<ActionResult> {
        return this.apiService.deleteSuivisDocument(documentHistorique.document.id)
            .pipe(
                tap((result: ActionResult) => {
                    if (result.success) { this.toastService.show(`Le document "${documentHistorique.name}" a été retiré de votre suivi.`); }
                })
            );
    }

    state(documentHistorique: DocumentHistorique, state: number): Observable<ActionResult> {
        let toast: string;
        switch (state) {
            case 1:
                toast = `Le document "${documentHistorique.name}" a été déplacé dans "À faire".`;
                break;
            case 2:
                toast = `Le document "${documentHistorique.name}" a  été déplacé dans "En cours".`;
                break;
            case 3:
                toast = `Le document "${documentHistorique.name}" a été déplacé dans "Validé".`;
                break;
        }
        return this.apiService.updateStateDocument(documentHistorique.id, state)
            .pipe(
                tap((result: ActionResult) => {
                    if (result) { this.toastService.show(toast); }
                })
            );
    }

    share(documentHistorique: DocumentHistorique, vcRef: ViewContainerRef): void {
        this.modalService.show(documentHistorique, 'share', vcRef);
    }

    rename(documentHistorique: DocumentHistorique, vcRef: ViewContainerRef): void {
        this.modalService.show(documentHistorique, 'rename', vcRef);
    }

    // TODO Regarder pour un retour pour test du toast ou transformé en Oservable
    download(documentHistorique: DocumentHistorique): Promise<void> {
        return this.downloadService.getFile(documentHistorique).then(() => {
            this.toastService.show(`Le téléchargement du document "${documentHistorique.name}" va commencer.`);
        });
    }

    upload(documentHistorique: DocumentHistorique, formData: FormData): Observable<any> {
        return this.apiService.uploadDocument(documentHistorique.id, formData);
    }

    uploadMultiple(documentHistorique: DocumentHistorique, formData: FormData): Observable<any> {
        return this.apiService.uploadDocuments(documentHistorique.id, formData);
    }

    update(documentHistorique: DocumentHistorique, formData: FormData): Observable<any> {
        return this.apiService.updateDocument(documentHistorique.id, formData);
    }

    delete(documentHistorique: DocumentHistorique): Observable<ActionResult> {
        return new Observable<ActionResult>(subscriber => {
            const text = 'Vous allez définitivement supprimer un document.\n' +
                'Vous ne pourrez pas récupérer ce document !';
            this.alertService.show(text).then((alert) => {
                if (alert.isConfirmed) {
                    this.apiService.deleteDocument(documentHistorique.id)
                        .subscribe((result: ActionResult) => {
                            if (result.success) {
                                this.toastService.show(`Le document "${documentHistorique.name}" a bien été supprimé.`);
                                subscriber.next(result);
                            }
                        }, () => subscriber.error());
                } else {
                    subscriber.next(null);
                }
            });
        });
    }

    moreAction(documentHistorique: DocumentHistorique, vcRef: ViewContainerRef): Observable<any> {
        return new Observable<any>(subscriber => {
            subscriber.next();
        });
    }

    moreActionShortcut(documentHistorique: DocumentHistorique, vcRef: ViewContainerRef): Observable<any> {
        return new Observable<any>(subscriber => {
            subscriber.next();
        });
    }

    moreFile(dossier: Dossier, type: string, vcRef: ViewContainerRef): Observable<any> {
        return new Observable<any>(subscriber => {
            subscriber.next();
        });
    }

    moreSpecificFile(dossier: Dossier, type: string, vcRef: ViewContainerRef): Observable<any> {
        return new Observable<any>(subscriber => {
            subscriber.next();
        });
    }

    waitingFile(data: DocumentHistorique|Dossier, event: string, type: string, vcRef: ViewContainerRef): Observable<any> {
        return new Observable<any>(subscriber => {
            subscriber.next();
        });
    }
}
