import {Component, OnInit, Input, ViewContainerRef, Output, EventEmitter, OnChanges} from '@angular/core';
import {Dossier, DossierSpecifUser} from '@src/app/model/dossier.model';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {takeUntil} from 'rxjs/operators';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SimpleChanges} from '@src/app/shared/interfaces';
import {DossierService} from '@src/app/services/dossier.service';
import {DocumentService} from '@src/app/services/document.service';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
    selector: 'app-specific-user',
    templateUrl: './specific-user.component.html',
    styleUrls: ['./specific-user.component.scss'],
})
export class SpecificUserComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() dossierSpecificUser: DossierSpecifUser;
    @Input() viewContainerRef: ViewContainerRef;
    @Input() previewIdDocumentHistorique: number;
    @Input() selectedIdDocumentHistorique: number;
    @Output() actionUser = new EventEmitter<{dossier: number; type: string}>();
    dossier: Dossier;
    dossierReponse: Dossier;
    isBusy: boolean;
    // TODO Refactor avec upload & pareil avec response
    uploading = false;
    processing = false;
    progress = 0;
    // upload = {
    //     isUploading: false,
    //     isProcessing: false,
    //     progress: 0,
    // };
    reponseProgress = 0;
    reponseUploading = false;
    reponseProcessing = false;
    spinner = {
        diffuse: false,
        valideCa: false,
        refuseCa: false,
        valideResponse: false,
        allDownload: false,
    };
    selectedDocumentHistorique: DocumentHistorique;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dossierService: DossierService,
        private documentService: DocumentService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.selectedDocumentHistorique = null;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getDossier(changes.dossierSpecificUser.currentValue.dossier.id);
    }

    getDossier(id: number, isReponseAction: boolean = false) {
        this.isBusy = true;
        this.dossierService.get(id)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                this.dossier = dossier;
                this.dossierReponse = this.dossier.dossiers.find(d => d.label === 'Réponse');
                this.isBusy = false;
                if (!isReponseAction) {
                    this.progress = 0;
                    this.uploading = false;
                    this.processing = false;
                } else {
                    this.reponseProgress = 0;
                    this.reponseUploading = false;
                    this.reponseProcessing = false;
                }
                this.dossier.documentHistoriques.map(dh => {
                    if (parseInt(dh.id + '', 10) === parseInt(this.selectedIdDocumentHistorique + '', 10)) {
                        this.documentClickedHandler({documentHistorique: dh, isOpen: true});
                    }
                });
                this.dossierReponse.documentHistoriques.map(dh => {
                    if (parseInt(dh.id + '', 10) === parseInt(this.selectedIdDocumentHistorique + '', 10)) {
                        this.documentClickedHandler({documentHistorique: dh, isOpen: true});
                    }
                });
            });
    }

    // TODO Voir pour mettre dans Utils ou Dossier & Document Services
    handleFileInput(files, dossier: Dossier) {
        const isReponseAction = dossier.id !== this.dossier.id;
        if (!isReponseAction) {
            this.progress = 0;
            this.uploading = true;
            this.processing = false;
        } else {
            this.reponseProgress = 0;
            this.reponseUploading = true;
            this.reponseProcessing = false;
        }

        this.dossierService.upload(dossier, files)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        if (dossier.id === this.dossier.id) {
                            this.processing = true;
                        } else {
                            this.reponseProcessing = true;
                        }
                        break;
                    case HttpEventType.ResponseHeader:
                        break;
                    case HttpEventType.UploadProgress:
                        if (dossier.id === this.dossier.id) {
                            this.progress = Math.round(event.loaded / event.total * 100);
                        } else {
                            this.reponseProgress = Math.round(event.loaded / event.total * 100);
                        }
                        break;
                    case HttpEventType.Response:
                        this.getDossier(this.dossierSpecificUser.dossier.id, isReponseAction);
                        break;
                }
            }, () => {
                if (!isReponseAction) {
                    this.progress = 0;
                    this.uploading = false;
                    this.processing = false;
                } else {
                    this.reponseProgress = 0;
                    this.reponseUploading = false;
                    this.reponseProcessing = false;
                }
            });
    }

    onTapViewer(documentHistorique: DocumentHistorique, isDossierResponse: boolean = false): void {
        // TODO MAJ nbNotifications ou déplacer ça dans le viewer (Bien mieux ^^)
        // tslint:disable-next-line:max-line-length
        const documentHistoriques = isDossierResponse ? this.dossier.dossiers[0].documentHistoriques : this.dossier.documentHistoriques;
        this.documentService.viewer(documentHistorique, documentHistoriques, this.viewContainerRef);
    }

    onTapDiffuse(dossier: Dossier): void {
        this.spinner.diffuse = true;
        this.dossierService.diffuse(dossier, this.viewContainerRef)
            .subscribe((result: Dossier) => {
                if (result) {
                    this.dossier = result;
                    this.actionUser.emit({dossier: this.dossierSpecificUser.dossier.id, type: '2'});
                }
                this.spinner.diffuse = false;
            }, () => this.spinner.diffuse = false);
    }

    onTapValidCa(dossier: Dossier): void {
        this.spinner.valideCa = true;
        this.dossierService.validCAResponse(dossier)
            .subscribe((result: Dossier) => {
                this.spinner.valideCa = false;
                this.dossier = result;
            }, () => this.spinner.valideCa = false);
    }

    onTapRefuseCa(dossier: Dossier): void {
        this.spinner.refuseCa = true;
        this.dossierService.validCAResponse(dossier)
            .subscribe((result: Dossier) => {
                this.spinner.refuseCa = false;
                this.dossier = result;
            }, () => this.spinner.refuseCa = false);
    }

    onTapValidResponse(dossier: Dossier): void {
        this.spinner.valideResponse = true;
        this.dossierService.validResponse(dossier)
            .subscribe((result: Dossier) => {
                this.spinner.valideResponse = false;
                this.dossierReponse = result;
                this.dossier.dateSpecificResponded = result.dateSpecificResponded;
                this.actionUser.emit({dossier: this.dossierSpecificUser.dossier.id, type: '3'});
            }, () => this.spinner.valideResponse = false);
    }

    documentClickedHandler(value): void {
        this.selectedDocumentHistorique = value.isOpen ? value.documentHistorique : null;
    }

    notificationUpdatedHandler(value): void {
        if (value && this.dossier.nbNotifications.total > 0) {
            this.dossier.nbNotifications.total = this.dossier.nbNotifications.total - 1;
        }
    }

    onTapDownloadAllDocument(dossier: Dossier): void {
        this.spinner.allDownload = true;
        this.dossierService.download(dossier, dossier).then(() => {
            this.spinner.allDownload = false;
        }, () => this.spinner.allDownload = false);
    }
}
