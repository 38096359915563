import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ApiService} from '@src/app/services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {Dossier} from '@src/app/model/dossier.model';
import {MatDrawerMode} from '@angular/material/sidenav';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent extends BaseComponent implements OnInit {

    public title = 'Tags de fichier';
    public mode: MatDrawerMode = 'over';
    public backdrop = true;
    public dossier: Dossier;
    public tags = [];
    public newTag = '';
    public id: number;
    public fileName: string;
    public documentId: number;
    public stepTags = false;
    public isBusy = true;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.fileName = params['fileName'];
            this.documentId = params['documentId'];
        });

        this.apiService.getDossier(this.id).subscribe((dossier) => {
            this.isBusy = false;
            this.dossier = dossier;
        });
    }

    convertDate(dateCreated: string) {
        return moment(dateCreated).format('DD/MM/YYYY');
    }

    setNewTagValue(value: string) {
        this.newTag = value;
    }

    goBack() {
        this.router.navigate(['affair-dossier', this.id]);
    }

    toggleTags() {
        this.stepTags = !this.stepTags;
    }

    addNewTagToDocument() {
        if (this.tags.indexOf(this.newTag)) {
            this.tags.push(this.newTag);
        }
        this.newTag = '';
    }

    removeTag(index) {
        this.tags.splice(index, 1);
    }

    sendTags() {
        this.isBusy = true;
        this.apiService.setTagsToFile(this.documentId, this.tags).subscribe((data) => {
            this.goBack();
        });
    }
}
