<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">{{title}}</span>
    <button (click)="onClose()" mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isBusy">
        <form *ngIf="societyForm" class="container-fluid page-container py-3" [formGroup]="societyForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput type="text" formControlName="name" required>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Nom alternatif</mat-label>
                        <input matInput type="text" formControlName="alternativeName">
                    </mat-form-field>
                </div>

                <div class="col-md-7">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Code fournisseur</mat-label>
                        <input matInput type="text" formControlName="code">
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="type" required>
                            <mat-option value="" disabled>Choisir un type</mat-option>
                            <mat-option *ngFor="let type of types" [value]="type.key">{{type.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" formControlName="email" required>
                    </mat-form-field>
                </div>

                <div class="col-md-5">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Téléphone</mat-label>
                        <input matInput type="tel" formControlName="phone">
                    </mat-form-field>
                </div>
                <div class="col-md-7">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Site web</mat-label>
                        <input matInput type="url" formControlName="web">
                    </mat-form-field>
                </div>

<!--                <div class="col-md-12">-->
<!--                    <mat-form-field appearance="outline" class="input-full-width">-->
<!--                        <mat-label>Corps d'état</mat-label>-->
<!--                        <mat-select multiple formControlName="trades">-->
<!--                            <mat-option value="" disabled>Choisir un type</mat-option>-->
<!--                            <mat-option *ngFor="let trade of trades" [value]="trade.id">{{trade.label}}</mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->

                <div class="col-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Adresse</mat-label>
                        <input matInput type="text" formControlName="address">
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Code postal</mat-label>
                        <input matInput type="text" formControlName="postalCode">
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Ville</mat-label>
                        <input matInput type="text" formControlName="city">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Pays</mat-label>
                        <input matInput type="text" formControlName="country">
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>SIREN, SIRET, ...</mat-label>
                        <input matInput type="text" formControlName="siren">
                    </mat-form-field>
                </div>

                <div class="col-md-4 pt-3">
                    <mat-checkbox formControlName="tva" color="primary">Assujetti à la TVA</mat-checkbox>
                </div>
                <div class="col-md-8">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Numéro de TVA</mat-label>
                        <input matInput type="text" formControlName="tvaNumber">
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Type d'entité légale</mat-label>
                        <mat-select formControlName="legalEntity">
                            <mat-option value="" disabled>Choisir une entité légale</mat-option>
                            <mat-option *ngFor="let entitie of entities" [value]="entitie.key">{{entitie.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Type de tiers</mat-label>
                        <mat-select formControlName="thirdParty">
                            <mat-option value="" disabled>Choisir un type de tiers</mat-option>
                            <mat-option *ngFor="let thirdPartie of thirdParties" [value]="thirdPartie.key">{{thirdPartie.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Capital</mat-label>
                        <input matInput type="text" formControlName="capital">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="!isBusy" mat-dialog-actions class="justify-content-end pb-3">
    <button *ngIf="society.id" (click)="onSubmit()" [disabled]="!societyForm.valid || isSaving" mat-raised-button color="primary" type="submit">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">save</mat-icon>
        Modifier
    </button>
    <button *ngIf="!society.id" (click)="onSubmit()" [disabled]="!societyForm.valid || isSaving" mat-raised-button color="primary" type="submit">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">add</mat-icon>
        Ajouter
    </button>
</div>
