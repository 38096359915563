import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@src/app/services/auth.service';
import {Observable} from 'rxjs';
import {StorageService} from '@src/app/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class IsSignedInGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router,
        private storageService: StorageService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.auth.authenticated()) {
            return true;
        } else {
            // Token périmé
            if (this.storageService.token) {
                return this.auth.autoLogin();
            } else {
                this.router.navigate(['login']);
                return false;
            }
        }
    }
}
