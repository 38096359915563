import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {Dossier, DossierSpecificDetail} from '@src/app/model/dossier.model';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {takeUntil} from 'rxjs/operators';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {ActionResult, ActionVersionResult, Context} from '@src/app/shared/interfaces';
import {BaseDossierComponent} from '@src/app/shared/base-dossier.component';
import {UploadImageService} from '@src/app/services/upload-image.service';
import {DocumentService} from '@src/app/services/document.service';
import {ApiService} from '@src/app/services/api.service';

// TODO refactor method qui pointe sur lui même et utilisé this.dossier
@Component({
    selector: 'app-specific-dossier',
    templateUrl: './specific-dossier.component.html',
    styleUrls: ['./specific-dossier.component.scss'],
    providers: [ActionBottomSheetService]
})
export class SpecificDossierComponent extends BaseDossierComponent {

    @Input() parentDossier: DossierSpecificDetail;
    @Input() isShortcut: boolean;
    @Input() selectedDocumentHistorique: DocumentHistorique;
    @Input() activeStatus: number;
    @Input() disabled: boolean;
    isOpen = false;
    isRefresh = false;
    // TODO Refactor avec upload
    uploading = false;
    processing = false;
    progress = 0;
    // upload = {
    //     isUploading: false,
    //     isProcessing: false,
    //     progress: 0,
    // };

    constructor(
        private router: Router,
        private apiService: ApiService,
        private uploadImageService: UploadImageService,
        private documentService: DocumentService,
    ) {
        super();
    }

    toggleAffichage() {
        this.isOpen = !this.isOpen;
    }

    // TODO Utile ?
    onTapVersion() {
        this.dossierService.version(this.dossier)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: ActionVersionResult) => {
                if (result.success) { this.router.navigate(['affair-dossier', result.version.id]); }
            });
    }

    onTapFavori() {
        this.dossierService.favori(this.dossier, this.parentDossier, true, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((actionResult: ActionResult) => {
                if (actionResult.success) {
                    this.isRefresh = true;
                    this.dossierService.shortcutRefresh(this.parentDossier.id)
                        .pipe(takeUntil(this.notifier))
                        .subscribe((dossier: Dossier) => {
                            this.dossier.documentHistoriques = dossier.shortcuts.find(s => s.id === this.dossier.id).documentHistoriques;
                            this.isRefresh = false;
                        });
                }
            });
    }

    // TODO Voir pour mettre dans BaseDossier
    handleFileInput(files) {
        this.progress = 0;
        this.uploading = true;
        this.processing = false;

        this.dossierService.upload(this.dossier, files)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.processing = true;
                        break;
                    case HttpEventType.ResponseHeader:
                        // TODO voir si utile
                        break;
                    case HttpEventType.UploadProgress:
                        this.progress = Math.round(event.loaded / event.total * 100);
                        break;
                    case HttpEventType.Response:
                        this.dossierService.get(this.dossier.id)
                            .pipe(takeUntil(this.notifier))
                            .subscribe((dossier) => {
                                this.dossier = dossier;
                                this.progress = 0;
                                this.uploading = false;
                            });
                        break;
                }
            }, () => {
                this.progress = 0;
                this.uploading = false;
                this.processing = false;
            });
    }

    // onTapDiffuse() {
    //     this.spinner.diffuse = true;
    //     this.dossierService.diffuse(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((dossier: Dossier) => {
    //             this.spinner.diffuse = false;
    //             this.dossier = dossier;
    //         }, () => this.spinner.diffuse = false);
    // }

    // onTapValidResponse() {
    //     this.spinner.valideResponse = true;
    //     this.dossierService.validResponse(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((dossier: Dossier) => {
    //             this.spinner.valideResponse = false;
    //             this.dossier = dossier;
    //         }, () => this.spinner.valideResponse = false);
    // }

    // onTapValidCa() {
    //     this.spinner.valideCa = true;
    //     this.dossierService.validCAResponse(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((dossier: Dossier) => {
    //             this.spinner.valideCa = false;
    //             this.dossier = dossier;
    //         }, () => this.spinner.valideCa = false);
    // }

    // onTapRefuseCa() {
    //     this.spinner.refuseCa = true;
    //     this.dossierService.refuseCAResponse(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((dossier: Dossier) => {
    //             this.spinner.refuseCa = false;
    //             this.dossier = dossier;
    //         }, () => this.spinner.refuseCa = false);
    // }

    // onTapEdit() {
    //     this.dossierService.edit(this.dossier)
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((dossier: Dossier) => {
    //             if (dossier) { this.dossier = dossier; }
    //         });
    // }

    // TODO On peux utilisé onTapEditDossier partout, mais refaire le cheminement de modal coté appli
    // onTapEditDossierModal(dossier: Dossier): void {
    //     this.modalService.show(
    //         {id: dossier.id, type: 'dossier-edit'},
    //         'base-modal',
    //         this.viewContainerRef,
    //         true
    //     )
    //         .pipe(takeUntil(this.notifier))
    //         .subscribe((result) => {
    //             if (result) { this.dossier = result; }
    //         });
    // }

    // onTapDelete() {
    //     this.dossierService.delete(this.dossier).subscribe(() => {
    //         this.dossierDeleted.emit(this.dossier);
    //     });
    // }

    private uploadFile(event) {
        this.documentService.waitingFile(this.dossier, event, 'upload', this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => {
                if (result) {
                    this.apiService.getDossier(this.dossier.id);
                }
            });
    }

    private onTapAddFile() {
        this.uploadImageService.uploadFile('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(event);
            }, 600);
        });
    }

    private onTapAddMedia() {
        this.uploadImageService.uploadMedia('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(event);
            }, 600);
        });
    }

    private onTapAddCamera() {
        this.uploadImageService.uploadCamera('upload').then((event) => {
            setTimeout(() => {
                this.uploadFile(event);
            }, 600);
        });
    }

    onTapMore(type: string) {
        this.dossierService.more(this.dossier, type, this.viewContainerRef, 'specific')
            .pipe(takeUntil(this.notifier))
            .subscribe((context: Context) => {
                if (context && context.action) {
                    setTimeout(() => {
                        this[context.action]();
                    }, 600);
                }
            });
    }
}
