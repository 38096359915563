import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '@src/app/services/api.service';
import {Affair} from '@src/app/model/affair.model';
import {Feed, Message, Notify} from '@src/app/shared/interfaces';
import {takeUntil, tap} from 'rxjs/operators';
import {ModalService} from '@src/app/services/modal.service';
import {MatDrawerMode} from '@angular/material/sidenav';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {
    title = 'Tableau de bord';
    mode: MatDrawerMode = 'side';
    backdrop = false;
    isProjetBusy: boolean;
    isChantierBusy: boolean;
    chantiers: Affair[] = [];
    projets: Affair[] = [];
    infos: {
        new: number,
        affair: number,
        notif: number,
        messages: number,
    };
    feedList$: Observable<Feed[]>;
    affairList$: Observable<Affair[]>;
    messagesList$: Observable<Message[]>;
    notificationList$: Observable<Notify[]>;
    fullName: string;
    sideNavType: string = null;
    isSideNavLoaded = false;

    constructor(
        private apiService: ApiService,
        private router: Router,
        private modalService: ModalService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit(): void {
        this.isProjetBusy = this.isChantierBusy = true;
        this.fullName = this.storageService.fullName;
        this.apiService.getUserInfosDashboard()
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => {
                this.infos = result;
            });
        this.apiService.getProjet()
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => {
                this.projets = result.affairs;
                this.isProjetBusy = false;
            });
        this.apiService.getChantier()
            .pipe(takeUntil(this.notifier))
            .subscribe((result) => {
                this.chantiers = result.affairs;
                this.isChantierBusy = false;
            });
        this.refreshInterval = setInterval(() => {
            this.apiService.getUserInfosDashboard()
                .pipe(takeUntil(this.notifier))
                .subscribe((result) => {
                    this.infos = result;
                });
        }, 120000);
    }

    goToStep(id: number) {
        this.router.navigate(['affaire', id]);
    }

    /* WEB method Only */

    onFeedSideNavOpenedChange($isOpened: boolean) {
        // if ($isOpened) {
        //     this.feedList$ = this.apiService.getFeedList();
        // }
    }

    onFeedSideNavOpen() {
        this.isSideNavLoaded = false;
        this.sideNavType = 'feed';
        this.feedList$ = this.apiService.getFeedList().pipe(
            tap(() => this.isSideNavLoaded = true)
        );
    }

    onAffairSideNavOpen() {
        this.isSideNavLoaded = false;
        this.sideNavType = 'affair';
        this.affairList$ = this.apiService.getAffairList().pipe(
            tap(() => this.isSideNavLoaded = true)
        );
    }

    onMessagesSideNavOpen() {
        this.isSideNavLoaded = false;
        this.sideNavType = 'messages';
        this.messagesList$ = this.apiService.getMessageList().pipe(
            tap(() => this.isSideNavLoaded = true)
        );
    }

    onNotificationSideNavOpen() {
        this.isSideNavLoaded = false;
        this.sideNavType = 'notification';
        this.notificationList$ = this.apiService.getNotificationList().pipe(
            tap(() => this.isSideNavLoaded = true)
        );
    }

    /* TNS method Only */

    onTapActuality(): void {
        this.modalService.show(this.apiService.getFeedList(), 'feed', this.viewContainerRef)
            .subscribe((routerParams: string[]) =>
                setTimeout(() => this.router.navigate(routerParams), 50)
            );
    }

    onTapModalAffair(): void {
        this.modalService.show(this.apiService.getAffairList(), 'affair', this.viewContainerRef)
            .subscribe((routerParams: string[]) =>
                setTimeout(() => this.router.navigate(routerParams), 50)
            );
    }

    onTapModalMessages(): void {
        this.modalService.show(this.apiService.getMessageList(), 'messages', this.viewContainerRef)
            .subscribe((routerParams: string[]) =>
                setTimeout(() => this.router.navigate(routerParams), 50)
            );
    }

    onTapModalNotification(): void {
        this.modalService.show(this.apiService.getNotificationList(), 'notification', this.viewContainerRef)
            .subscribe((routerParams: string[]) =>
                setTimeout(() => this.router.navigate(routerParams), 50)
            );
    }

    navigateToSearchPage() {
        this.router.navigate(['/rechercher']);
    }

    projetDeleteHandler(affair: Affair): void {
        this.projets = this.projets.filter(a => a.id !== affair.id);
    }

    chantierDeleteHandler(affair: Affair): void {
        this.chantiers = this.chantiers.filter(a => a.id !== affair.id);
    }
}
