<div *ngIf="society" class="bg-primary-light rounded p-2 my-2">
    <div class="d-flex flex-row align-items-center">
        <mat-icon color="primary">business</mat-icon>
        <span class="ml-1 mr-3">{{society.name}}</span>
    </div>
    <div *ngIf="society.email" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">mail_outline</mat-icon>
        <span class="ml-1 mr-3"><a href="mailto:{{society.email}}" class="text-decoration-none text-reset">{{society.email}}</a></span>
    </div>
    <div *ngIf="society.phone" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">phone</mat-icon>
        <span class="ml-1 mr-3"><a href="tel:{{society.phone}}" class="text-decoration-none text-reset">{{society.phone}}</a></span>
    </div>
    <div *ngIf="society.web" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">language</mat-icon>
        <span class="ml-1 mr-3"><a href="{{society.web}}" target="_blank" class="text-decoration-none text-reset">{{society.web}}</a></span>
    </div>
    <div *ngIf="society.address || society.postalCode || society.city || society.country" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">location_on</mat-icon>
        <span class="ml-1 mr-3">{{society.address}} {{society.postalCode}} {{society.city}} {{society.country}}</span>
    </div>

    <dl class="row no-gutters fz-14">
        <dt *ngIf="society.type" class="col-sm-3 font-weight-bold">Type</dt>
        <dd *ngIf="society.type" class="col-sm-9 ml-0">{{getType(society.type)}}</dd>
        <dt *ngIf="society.code" class="col-sm-3 font-weight-bold">N° Fournisseur</dt>
        <dd *ngIf="society.code" class="col-sm-9 ml-0">{{society.code}}</dd>
        <dt *ngIf="society.siren" class="col-sm-3 font-weight-bold">Sirem</dt>
        <dd *ngIf="society.siren" class="col-sm-9 ml-0">{{society.siren}}</dd>
        <dt *ngIf="society.tvaNumber" class="col-sm-3 font-weight-bold">TVA</dt>
        <dd *ngIf="society.tvaNumber" class="col-sm-9 ml-0">{{society.tvaNumber}}</dd>
        <dt *ngIf="society.legalEntity" class="col-sm-3 font-weight-bold">Entité Légale</dt>
        <dd *ngIf="society.legalEntity" class="col-sm-9 ml-0">{{getLegalEntity(society.legalEntity)}}</dd>
        <dt *ngIf="society.thirdParty" class="col-sm-3 font-weight-bold">Type tiers</dt>
        <dd *ngIf="society.thirdParty" class="col-sm-9 ml-0">{{getThirdParty(society.thirdParty)}}</dd>
        <dt *ngIf="society.capital" class="col-sm-3 font-weight-bold">Capital</dt>
        <dd *ngIf="society.capital" class="col-sm-9 ml-0">{{society.capital}}</dd>
    </dl>

    <div *ngFor="let contact of society.contacts" (click)="onTapContact(contact)"
         class="d-flex flex-row justify-content-start align-items-center text-left bg-white rounded-sm my-1 py-1 px-2 cursor-pointer">
        <div class="d-flex flex-row align-items-center fz-14">
            <mat-icon class="mat-icon-16">person</mat-icon>
            <span class="ml-1 mr-3">{{contact.fullName}}</span>
        </div>
    </div>
</div>
<div *ngIf="contact" class="bg-primary-light rounded p-2 my-2">
    <div class="d-flex flex-row align-items-center">
        <mat-icon color="primary">person</mat-icon>
        <span class="ml-1 mr-3">{{contact.fullName}}</span>
    </div>
    <div *ngIf="contact.email" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">mail_outline</mat-icon>
        <span class="ml-1 mr-3"><a href="mailto:{{contact.email}}" class="text-decoration-none text-reset">{{contact.email}}</a></span>
    </div>
    <div *ngIf="contact.phone" class="d-flex flex-row align-items-center pl-1 mt-1 fz-14">
        <mat-icon class="mat-icon-16" color="primary">phone</mat-icon>
        <span class="ml-1 mr-3"><a href="tel:{{contact.phone}}" class="text-decoration-none text-reset">{{contact.phone}}</a></span>
    </div>
    <div *ngIf="contact.trades.length" class="mt-1 pl-1">
        <span *ngFor="let trade of contact.trades; let last = last;" [class.mr-2]="!last" class="chip chip-sm chip-primary">{{trade.label}}</span>
    </div>
    <div class="text-muted pl-1 mt-1 fz-12">Enregistré le {{formatDate(contact.dateCreated)}}</div>
    <div class="text-muted pl-1 fz-12">Dernière modification le {{formatDate(contact.dateUpdated)}}</div>
</div>
