<h2 class="text-primary font-weight-normal">Mon planning - <span>{{todayFormattedDate}}</span></h2>
<div class="row">
    <div class="col-md-6">
        <app-calendar [today]="today" [events]="events"></app-calendar>
    </div>
    <div class="col-md-6" style="max-height: 400px; overflow: auto">
        <div *ngFor="let agenda of agendas" class="d-flex flex-row bg-primary-light mb-2 agenda-list-item">
            <div class="d-flex flex-column justify-content-center align-items-center agenda-list-item-date text-truncate m-r-5">
                <div>{{getDayFromDate(agenda.date)}}</div>
                <div>{{getMonthFromDate(agenda.date)}}</div>
            </div>
            <div class="text-truncate">
                <div class="agenda-list-item-action text-truncate">{{agenda.detail}}</div>
                <div class="agenda-list-item-affair d-flex flex-row cursor-pointer"
                     [routerLink]="['/affaire', agenda.affair.id]">{{agenda.affair.label}}
                    / {{agenda.step.label}}</div>
            </div>
        </div>
    </div>
</div>
