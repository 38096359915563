import * as moment from 'moment';
import {User} from '@src/app/model/user.model';
import {Subject} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {StorageService} from '@src/app/services/storage.service';
import {RoleType} from '@src/app/shared/interfaces';
import {AppInjector} from '@src/app/app.module';
import {SharedService} from '@src/app/services/shared.service';
import {FileInput} from 'ngx-material-file-input';

@Component({template: ''})
export class BaseComponent implements OnDestroy {

    notifier: Subject<void> = new Subject<void>();
    refreshInterval: any;
    storageService: StorageService;
    roles: RoleType[] = [];
    sharedService: SharedService;

    constructor() {
        this.storageService = AppInjector.get(StorageService);
        this.sharedService = AppInjector.get(SharedService);
        if (!this.roles.length) {
            this.roles = this.storageService.roles;
        }
        this.sharedService.roles.subscribe(roles => {
            this.roles = roles;
        });
    }

    ngOnDestroy(): void {
        clearInterval(this.refreshInterval);
        this.notifier.next();
        this.notifier.complete();
    }

    hasRoles(roles: RoleType[]): boolean {
        if (Array.isArray(this.roles) && this.roles.length) {
            return roles.some((role) => this.roles.includes(role));
        }
        return false;
    }

    formatDate(date: string, format: string = 'DD/MM/YYYY'): string|null {
        if (date) {
            moment.isMoment(date);
            if (typeof date === 'object') {
                return moment(date).format(format);
            } else {
                if (date.length > 10) {
                    return moment(date).format(format);
                } else {
                    const aDate = date.split('-');
                    return `${aDate[2]}/${aDate[1]}/${aDate[0]}`;
                }
            }
        }
        return null;
    }

    formatArray(array) {
        if (array) {  return array.map(i => i.hasOwnProperty('id') ? i.id : i); }
        return null;
    }

    formatStrings(object) {
        for (const property in object) {
            if (object.hasOwnProperty(property)) {
                object[property] = object[property] === '' ? null : object[property];
            }
        }
        return object;
    }

    convertJsontoFormData(jsonObject: Object, parentKey, carryFormData: FormData): FormData {
        const formData = carryFormData || new FormData();
        let index = 0;

        // tslint:disable-next-line:forin
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
                    let propName = parentKey || key;
                    if (parentKey && this.isObject(jsonObject)) {
                        propName = parentKey + '[' + key + ']';
                    }
                    if (parentKey && Array.isArray(jsonObject)) {
                        propName = parentKey + '[' + index + ']';
                    }
                    if (jsonObject[key] instanceof FileInput) {
                        formData.append(propName, jsonObject[key]._files[0], jsonObject[key]._files[0].name);
                    } else if (jsonObject[key] instanceof File) {
                        formData.append(propName, jsonObject[key]);
                    } else if (jsonObject[key] instanceof FileList) {
                        for (let j = 0; j < jsonObject[key].length; j++) {
                            formData.append(propName + '[' + j + ']', jsonObject[key].item(j));
                        }
                    } else if (Array.isArray(jsonObject[key]) || this.isObject(jsonObject[key])) {
                        this.convertJsontoFormData(jsonObject[key], propName, formData);
                    } else if (typeof jsonObject[key] === 'boolean') {
                        formData.append(propName, +jsonObject[key] ? '1' : '0');
                    } else {
                        formData.append(propName, jsonObject[key]);
                    }
                }
            }
            index++;
        }
        return formData;
    }

    isObject(val) {
        return !Array.isArray(val) && typeof val === 'object' && !!val;
    }

    trackBy(index, item): number {
        return item.id;
    }

    sortBy(objects: any[], prop: string): any {
        return objects.sort((a, b) => a[prop].toLowerCase() > b[prop].toLowerCase() ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    concatTooltip(users: User[]): string {
        return users.map(s => s.fullName).join(', ');
    }
}
