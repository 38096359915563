import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {Society} from '@src/app/model/society.model';
import {Affair} from '@src/app/model/affair.model';
import {User} from '@src/app/model/user.model';
import {dossierCode} from '@src/app/shared/interfaces';

export class SpecificVersion {
    id: number;
    label: string;
}

export class DossierSpecifUser {
    dossier: Dossier;
    user: User;
    type: string;
}

export class DossierSpecificList {
    id: number;
    label: string;
    version: string;
    versions: SpecificVersion[];
    users: DossierSpecifUser[];
}

export class DossierSpecificDetail {
    id: number;
    label: string;
    documentHistoriques: DocumentHistorique[];
    dossiers: Dossier[];
    versions: SpecificVersion[];
    users: DossierSpecifUser[];
}

export class Dossier {
    id: number;
    label: string;
    description: string;
    typeSpecific: any;
    code: dossierCode;
    step: {
        id: number;
        label?: string;
    };
    affair: Affair;
    dateLimit: string;
    state: number;
    documentHistoriques: DocumentHistorique[];
    societies: Society[];
    users: User[];
    dossiers: Dossier[];
    shortcuts: Dossier[];
    favoris: DocumentHistorique[];
    nbDocuments: number;
    nbNotifications: {
        new?: number;
        update?: number;
        total: number;
    };
    nbDiscutions: number;
    isChargeAffair?: boolean;
    parentDossiers: [{
        id: number;
        label: string;
    }];
    parentId?: number;
    allowTo?: {
        diffuse: boolean;
        view_diffuse_date: boolean;
        diffuse_nb: boolean;
        valide_ca: boolean;
        valide_user: boolean;
        valide_user_btn: boolean;
        valide_user_date: boolean;
        societies: boolean;
        document: boolean;
        edit: boolean;
        remove: boolean;
    };
    specificList?: DossierSpecificList[];
    specificDetail?: DossierSpecificDetail;
    specificResponse: number;
    specificResponded: number;
    specificCAResponded: number;
    caResponse: number;
    dateSpecificResponded: string;
    dateSpecificCAResponded: string;
    validatedResponses: number;
    nbSolicitedSocities: number;
    diffuseDate: string;
    isSpecificDossier: boolean;

    constructor(
        label?: string,
        typeSpecific?: any,
        state?: number,
        dateLimit?: string,
    ) {
        this.label = label || '';
        this.typeSpecific = typeSpecific || '';
        this.state = state || 0;
        this.dateLimit = dateLimit || null;
    }
}

export const DOSSIER_TYPES = [
    {key: '', label: 'Aucun'},
    {key: '1', label: 'Chiffrage'},
    {key: '2', label: 'Hypothèse'},
];

export const USER_TYPES = {
    '1': 'warn',
    '2': 'accent',
    '3': 'primary',
};

export const DOSSIER_STATUS = {
    3: {
        label: 'Validé',
        class: 'green'
    },
    2: {
        label: 'En cours',
        class: 'yellow'
    },
    1: {
        label: 'À faire',
        class: 'red'
    },
};
