import * as moment from 'moment';
import {ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';

export function isArray(val): boolean {
    const toString = ({}).toString;
    return toString.call(val) === '[object Array]';
}

export function isObject(val): boolean {
    return !isArray(val) && typeof val === 'object' && !!val;
}

export function objectToFormData(jsonObject: Object, parentKey, carryFormData: FormData): FormData {
    const formData = carryFormData || new FormData();
    let index = 0;

    // tslint:disable-next-line:forin
    for (const key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
                let propName = parentKey || key;
                if (parentKey && isObject(jsonObject)) {
                    propName = parentKey + '[' + key + ']';
                }
                if (parentKey && isArray(jsonObject)) {
                    propName = parentKey + '[' + index + ']';
                }
                if (jsonObject[key] instanceof File) {
                    formData.append(propName, jsonObject[key]);
                } else if (isArray(jsonObject[key]) || isObject(jsonObject[key])) {
                    objectToFormData(jsonObject[key], propName, formData);
                } else if (typeof jsonObject[key] === 'boolean') {
                    formData.append(propName, +jsonObject[key] ? '1' : '0');
                } else {
                    formData.append(propName, jsonObject[key]);
                }
            }
        }
        index++;
    }
    return formData;
}

export function objectToString(object: any): string {
    if (object.hasOwnProperty('fullName')) {
        return object.fullName;
    } else if (object.hasOwnProperty('fullname')) {
        return object.fullname;
    }  else if (object.hasOwnProperty('label')) {
        return object.label;
    } else if (object.hasOwnProperty('name')) {
        return object.name;
    } else {
        return object;
    }
}

export function objectArrayToString(array: any[]): string {
    if (array) {
        return array.map((a) => objectToString(a)).join(', ');
    }
    return null;
}

export function objectToId(object: any): string|number {
    if (typeof object !== 'undefined' && object) {
        if (object.hasOwnProperty('id')) {
            return object.id;
        } else if (object.hasOwnProperty('key')) {
            return object.key;
        } else {
            return object;
        }
    }
    return null;
}

export function objectArrayToIdArray(array: any[]): (string|number)[]|null {
    if (array) {
        return array.map(a => objectToId(a));
    }
    return null;
}

export function formatDate(date: string, format: string = 'DD/MM/YYYY'): string|null {
    if (date) {
        moment.isMoment(date);
        if (typeof date === 'object') {
            return moment(date).format(format);
        } else {
            if (date.length > 10) {
                return moment(date).format(format);
            } else {
                const aDate = date.split('-');
                return `${aDate[2]}/${aDate[1]}/${aDate[0]}`;
            }
        }
    }
    return null;
}

export class ErrorStateMatcher implements ShowOnDirtyErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

        return (invalidCtrl || invalidParent);
    }
}

export function trackBy(index, item): number {
    return item.id;
}

export function convertDate(date: string, format: string = 'DD/MM/YYYY'): string {
    return moment(date).format(format);
}

export function convertDateHeure(date: string, format: string = 'DD/MM/YYYY hh:mm'): string {
    return moment(date).format(format);
}
