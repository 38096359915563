import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_RIPPLE_GLOBAL_OPTIONS, MatCommonModule, RippleGlobalOptions} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from '@angular/material/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {AppRoutingModule} from '@src/app/app-routing.module';
import {AppComponent} from '@src/app/app.component';
import {LoginComponent} from '@src/app/user/login/login.component';
import {HomeComponent} from '@src/app/home/home.component';
import {AffairListComponent} from '@src/app/affair/list/affair-list.component';
import {AffairStepComponent} from '@src/app/affair/step/affair-step.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DrawerService} from '@src/app/services/drawer.service';
import {StorageService} from '@src/app/services/storage.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {IsSignedInGuard} from '@src/app/shared/is-signed-in.guard';
import {AuthService} from '@src/app/services/auth.service';
import {ApiService} from '@src/app/services/api.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AnnuaireComponent} from '@src/app/annuaire/annuaire.component';
import {AnnuaireSocietyComponent} from '@src/app/annuaire/society/annuaire-society.component';
import {AnnuaireUserComponent} from '@src/app/annuaire/user/annuaire-user.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTabsModule} from '@angular/material/tabs';
import {ScrollViewService} from '@src/app/services/scroll-view.service';
import {AffairDossierComponent} from '@src/app/affair/affair-dossier/affair-dossier.component';
import {SpecificComponent} from '@src/app/affair/specific/specific.component';
import {SpecificDetailComponent} from '@src/app/affair/specific-detail/specific-detail.component';
import {SpecificUserComponent} from '@src/app/affair/specific-detail/specific-user/specific-user.component';
import {MatCardModule} from '@angular/material/card';
import {FeedComponent} from '@src/app/home/feed/feed.component';
import {HomeModalService} from '@src/app/services/home-modal.service';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {UploadImageService} from '@src/app/services/upload-image.service';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {PlanningComponent} from '@src/app/home/planning/planning.component';
import {CalendarComponent} from '@src/app/home/calendar/calendar.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ToastService} from '@src/app/services/toast.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {ModalDossierComponent} from '@src/app/affair/form/modal-dossier.component';
import {SearchComponent} from '@src/app/search/search.component';
import {UploadFileComponent} from '@src/app/affair/upload-file/upload-file.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MomentDateModule} from '@angular/material-moment-adapter';
import {ActivityComponent} from '@src/app/affair/affair-dossier/activity/activity.component';
import {DocumentComponent} from '@src/app/affair/affair-dossier/document/document.component';
import {MomentModule} from 'ngx-moment';
import * as moment from 'moment';
import {DownloadService} from '@src/app/services/download.service';
import {CommonModule} from '@angular/common';
import {AffairSideNavComponent} from '@src/app/home/affair/affair-side-nav.component';
import {MessageSideNavComponent} from '@src/app/home/message/message-side-nav.component';
import {NotifySideNavComponent} from '@src/app/home/notify/notify-side-nav.component';
import {NgxPermissionsModule} from 'ngx-permissions';

moment.locale('fr');
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {AffairFormComponent} from '@src/app/affair/form/affair-form.component';
import {MatStepperModule} from '@angular/material/stepper';
import {ModalAuthorizeComponent} from '@src/app/affair/form/modal-authorize.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ModalShareComponent} from '@src/app/affair/form/modal-share.component';
import {MatChipsModule} from '@angular/material/chips';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {ModalService} from '@src/app/services/modal.service';
import {AlertService} from '@src/app/services/alert.service';
import {ViewerComponent} from '@src/app/affair/affair-dossier/viewer/viewer.component';
import {AccountComponent} from '@src/app/user/account/account.component';
import {DossierComponent} from '@src/app/affair/affair-dossier/dossier/dossier.component';
import {ModalRenameComponent} from '@src/app/affair/form/modal-rename.component';
import {GuestComponent} from '@src/app/guest/guest.component';
import {UserModalViewContentComponent} from '@src/app/form/user-modal-view-content.component';
import {GuestModalViewContentComponent} from '@src/app/form/guest-modal-view-content.component';
import {SpecificDocumentComponent} from '@src/app/affair/specific-detail/specific-document/specific-document.component';
import {SpecificDossierComponent} from '@src/app/affair/specific-detail/specific-dossier/specific-dossier.component';
import {ModalActivityComponent} from '@src/app/affair/affair-dossier/activity/modal-activity.component';
import {DossierService} from '@src/app/services/dossier.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ModalFileComponent} from '@src/app/affair/form/modal-file-component';
import {ModalShortcutComponent} from '@src/app/affair/form/modal-shortcut-component';
import {AffairFilterComponent} from '@src/app/affair/list/filter/affair-filter.component';
import {ModalFavoriComponent} from '@src/app/affair/form/modal-favori-component';
import {StepGlobalComponent} from '@src/app/affair/step/step-global/step-global.component';
import {StepEtapeComponent} from '@src/app/affair/step/step-etape/step-etape.component';
import {StepCalendarComponent} from '@src/app/affair/step/step-calendar/step-calendar.component';
import {ModalTeamAffairComponent} from '@src/app/affair/form/modal-team-affair.component';
import {SpecificItemComponent} from '@src/app/affair/specific/specific-item/specific-item.component';
import {AffairNavComponent} from '@src/app/affair/affair-nav/affair-nav.component';
import {ResetPasswordComponent} from '@src/app/user/reset-password/reset-password.component';
import {ResetPasswordConfirmComponent} from '@src/app/user/reset-password-confirm/reset-password-confirm.component';
import {AnnuaireNavComponent} from '@src/app/annuaire/annuaire-nav/annuaire-nav.component';
import {EtapeDossierComponent} from '@src/app/affair/step/step-etape/etape-dossier/etape-dossier.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ModalDateLimiteComponent} from '@src/app/affair/form/modal-date-limite.component';
import {DocumentService} from '@src/app/services/document.service';
import {ListItemComponent} from '@src/app/affair/list/list-item/list-item.component';
import {SharedService} from '@src/app/services/shared.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {BaseDossierComponent} from '@src/app/shared/base-dossier.component';
import {ModalDiffuseComponent} from '@src/app/affair/form/modal-diffuse.component';
import {ModalAlertComponent} from '@src/app/affair/form/modal-alert.component';
import {AffairItemComponent} from '@src/app/home/affair/affair-item/affair-item.component';
import {MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
import {AnnuaireUserDetailComponent} from '@src/app/annuaire/user/detail/annuaire-user-detail.component';
import {AnnuaireUserFilterComponent} from '@src/app/annuaire/user/filter/annuaire-user-filter.component';
import {AnnuaireSocietyFilterComponent} from '@src/app/annuaire/society/filter/annuaire-society-filter.component';
import {AnnuaireSocietyDetailComponent} from '@src/app/annuaire/society/detail/annuaire-society-detail.component';
import {AnnuaireUserItemComponent} from '@src/app/annuaire/user/item/annuaire-user-item.component';
import {AnnuaireSocietyItemComponent} from '@src/app/annuaire/society/item/annuaire-society-item.component';
import {ModalSocietyComponent} from '@src/app/affair/form/modal-society.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

const globalRippleConfig: RippleGlobalOptions = {
    disabled: false,
    animation: {
        enterDuration: 30000,
        exitDuration: 0
    }
};
const paginatorIntl = new MatPaginatorIntl();
paginatorIntl.itemsPerPageLabel = 'Éléments par page';
paginatorIntl.nextPageLabel = 'Page suivante';
paginatorIntl.previousPageLabel = 'Page précédente';
paginatorIntl.firstPageLabel = 'Première page';
paginatorIntl.lastPageLabel = 'Dernière page';
paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    return (startIndex + 1) + ' - ' + endIndex + ' sur ' + length;
};

export let AppInjector: Injector;

@NgModule({
    declarations: [
        AppComponent,
        BaseComponent,
        BaseDossierComponent,
        LoginComponent,
        HomeComponent,
        AffairListComponent,
        AffairStepComponent,
        AffairDossierComponent,
        SpecificComponent,
        SpecificDetailComponent,
        SpecificUserComponent,
        SpecificDocumentComponent,
        SpecificDossierComponent,
        AnnuaireComponent,
        AnnuaireSocietyComponent,
        AnnuaireUserComponent,
        CalendarComponent,
        PlanningComponent,
        FeedComponent,
        ModalDossierComponent,
        UploadFileComponent,
        SearchComponent,
        ActivityComponent,
        DocumentComponent,
        AffairFormComponent,
        ModalAuthorizeComponent,
        ModalShareComponent,
        ModalRenameComponent,
        AffairSideNavComponent,
        MessageSideNavComponent,
        NotifySideNavComponent,
        ViewerComponent,
        AccountComponent,
        DossierComponent,
        GuestComponent,
        UserModalViewContentComponent,
        GuestModalViewContentComponent,
        ModalActivityComponent,
        ModalFileComponent,
        ModalShortcutComponent,
        AffairFilterComponent,
        ModalFavoriComponent,
        StepGlobalComponent,
        StepEtapeComponent,
        StepCalendarComponent,
        ModalTeamAffairComponent,
        SpecificItemComponent,
        AffairNavComponent,
        ResetPasswordComponent,
        ResetPasswordConfirmComponent,
        AnnuaireNavComponent,
        EtapeDossierComponent,
        ModalDateLimiteComponent,
        ListItemComponent,
        ModalDiffuseComponent,
        ModalAlertComponent,
        AffairItemComponent,
        AnnuaireUserDetailComponent,
        AnnuaireSocietyDetailComponent,
        AnnuaireUserFilterComponent,
        AnnuaireSocietyFilterComponent,
        AnnuaireUserItemComponent,
        AnnuaireSocietyItemComponent,
        ModalSocietyComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        MatCommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatRippleModule,
        MatSidenavModule,
        FlexLayoutModule,
        MatMenuModule,
        MatDividerModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatTabsModule,
        MatCardModule,
        CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatDialogModule,
        MatRadioModule,
        MatDatepickerModule,
        MomentDateModule,
        MomentModule,
        SweetAlert2Module,
        MatStepperModule,
        MatTooltipModule,
        MatChipsModule,
        MaterialFileInputModule,
        NgxPermissionsModule.forRoot(),
        MatAutocompleteModule,
        MatExpansionModule,
        DragDropModule,
        MatPaginatorModule,
    ],
    entryComponents: [
        ModalDossierComponent,
        ModalAuthorizeComponent,
        UserModalViewContentComponent,
        GuestModalViewContentComponent,
    ],
    providers: [
        DrawerService,
        ScrollViewService,
        StorageService,
        IsSignedInGuard,
        AuthService,
        ApiService,
        ActionBottomSheetService,
        HomeModalService,
        UploadImageService,
        ToastService,
        ModalService,
        AlertService,
        DownloadService,
        DossierService,
        DocumentService,
        SharedService,
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MatPaginatorIntl, useValue: paginatorIntl}
        // {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    ],
    exports: [
        CalendarComponent,
        PlanningComponent,
        DocumentComponent,
        SpecificDocumentComponent,
        AnnuaireUserDetailComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
