import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ModalAlertComponent} from '@src/app/affair/form/modal-alert.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {

    constructor(public dialog: MatDialog) {}

    public show(title: string, data: string[]): Promise<string> {
        return new Promise<string>(((resolve, reject) => {
            resolve('');
        }));
    }

    public alert(error: HttpErrorResponse): void {
        const config: MatDialogConfig = {
            minWidth: '320px',
            maxWidth: '90vw',
            data: error,
            disableClose: true,
        };

        this.dialog.open(ModalAlertComponent, config);
    }
}
