import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '@src/app/services/api.service';
import {ToastService} from '@src/app/services/toast.service';
import {Trade} from '@src/app/model/trade.model';
import {ENTITIES, Society, THIRD_PARTIES, TYPES} from '@src/app/model/society.model';
import {User} from '@src/app/model/user.model';
import {objectToId} from '@src/app/shared/utils';

@Component({
    selector: 'app-modal-society',
    templateUrl: 'modal-society.component.web.html',
})
export class ModalSocietyComponent implements OnInit {

    title = 'Ajouter une société';
    isBusy: boolean;
    isSaving = false;
    society: Society;
    types = TYPES;
    thirdParties = THIRD_PARTIES;
    entities = ENTITIES;
    societyForm: FormGroup;
    trades: Trade[];
    tradesProvider = {
        key: 'id',
        label: 'label',
        items: []
    };

    constructor(
        public dialogRef: MatDialogRef<ModalSocietyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {society: Society},
        private apiService: ApiService,
        private toastService: ToastService,
    ) {
        if (this.data.society?.id) { this.title = 'Modifier une société'; }
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.apiService.getTrades().subscribe((trades) => {
            this.trades = trades.trades;
            this.tradesProvider.items = this.trades;
        });
        if (this.data.society?.id) {
            this.apiService.getSociety(this.data.society.id).subscribe((society) => {
                this.society = society;
                this.createForm();
            });
        } else {
            this.society = new Society();
            this.createForm();
        }
    }

    onClose(args: User = null) {
        this.dialogRef.close(args);
    }

    createForm() {
        this.isBusy = false;
        this.societyForm = new FormGroup({
            name: new FormControl(this.society.name),
            code: new FormControl(this.society.code),
            alternativeName: new FormControl(this.society.alternativeName),
            type: new FormControl(this.society.type),
            address: new FormControl(this.society.address),
            postalCode: new FormControl(this.society.postalCode),
            city: new FormControl(this.society.city),
            country: new FormControl(this.society.country),
            email: new FormControl(this.society.email),
            web: new FormControl(this.society.web),
            phone: new FormControl(this.society.phone),
            siren: new FormControl(this.society.siren),
            tva: new FormControl(this.society.tva),
            tvaNumber: new FormControl(this.society.tvaNumber),
            thirdParty: new FormControl(this.society.thirdParty),
            legalEntity: new FormControl(this.society.legalEntity),
            capital: new FormControl(this.society.capital),
        });
    }

    onSubmit(): void {
        if (this.societyForm.valid) {
            this.isSaving = true;
            const data = this.formatStrings(this.societyForm.value);
            delete data.trades;
            data.type = objectToId(data.type);
            data.thirdParty = objectToId(data.thirdParty);
            data.legalEntity = objectToId(data.legalEntity);

            if (this.society.id) {
                this.apiService.putSociety(this.society.id, data).subscribe((society) => {
                    this.isSaving = false;
                    if (society) {
                        this.toastService.show(`La société "${society.name}" a été modifié.`);
                        this.dialogRef.close(society);
                    }
                }, () => this.isSaving = false);
            } else {
                this.apiService.postSociety(data).subscribe((society) => {
                    this.isSaving = false;
                    if (society) {
                        this.toastService.show(`La société "${society.name}" a été ajouté.`);
                        this.dialogRef.close(society);
                    }
                }, () => this.isSaving = false);
            }
        }
    }

    private formatArray(array) {
        if (array) {
            return array.map(i => i.hasOwnProperty('id') ? i.id : i);
        }
        return null;
    }

    formatStrings(object) {
        for (const property in object) {
            if (object.hasOwnProperty(property)) {
                object[property] = object[property] === '' ? null : object[property];
            }
        }
        return object;
    }
}
