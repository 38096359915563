import {Component, EventEmitter, Input, OnDestroy, Output, ViewContainerRef} from '@angular/core';
import {BaseComponent} from '@src/app/shared/base.component';
import {Dossier} from '@src/app/model/dossier.model';
import {DossierService} from '@src/app/services/dossier.service';
import {AppInjector} from '@src/app/app.module';
import {takeUntil} from 'rxjs/operators';
import {ActionResult, ActionStateResult, Context} from '@src/app/shared/interfaces';

@Component({template: ''})
export class BaseDossierComponent extends BaseComponent implements OnDestroy {

    @Input() dossier: Dossier;
    @Input() viewContainerRef: ViewContainerRef;
    @Output() dossierDeleted: EventEmitter<Dossier> = new EventEmitter<Dossier>();

    dossierService: DossierService;
    spinner = {
        diffuse: false,
        valideCa: false,
        refuseCa: false,
        valideResponse: false,
    };

    constructor() {
        super();
        this.dossierService = AppInjector.get(DossierService);
    }

    onTapDiffuse() {
        this.spinner.diffuse = true;
        this.dossierService.diffuse(this.dossier, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                this.spinner.diffuse = false;
                this.dossier = dossier;
            }, () => this.spinner.diffuse = false);
    }

    onTapValidResponse() {
        this.spinner.valideResponse = true;
        this.dossierService.validResponse(this.dossier)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                this.spinner.valideResponse = false;
                this.dossier = dossier;
            }, () => this.spinner.valideResponse = false);
    }

    onTapValidCa() {
        this.spinner.valideCa = true;
        this.dossierService.validCAResponse(this.dossier)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                this.spinner.valideCa = false;
                this.dossier = dossier;
            }, () => this.spinner.valideCa = false);
    }

    onTapRefuseCa() {
        this.spinner.refuseCa = true;
        this.dossierService.refuseCAResponse(this.dossier)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                this.spinner.refuseCa = false;
                this.dossier = dossier;
            }, () => this.spinner.refuseCa = false);
    }

    onTapDo(): void {
        this.dossierService.state(this.dossier, 1)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: ActionStateResult) => {
                if (result.success) { this.dossier.state = 1; }
            });
    }

    onTapWork(): void {
        this.dossierService.state(this.dossier, 2)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: ActionStateResult) => {
                if (result.success) { this.dossier.state = 2; }
            });
    }

    onTapValid(): void {
        this.dossierService.state(this.dossier, 3)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: ActionStateResult) => {
                if (result.success) { this.dossier.state = 3; }
            });
    }

    onTapEdit(): void {
        this.dossierService.edit(this.dossier, false, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                if (dossier) { this.dossier = dossier; }
            });
    }

    onTapAuthorize(): void {
        this.dossierService.authorize(this.dossier, null, false, false, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((dossier: Dossier) => {
                if (dossier) { this.dossier = dossier; }
            });
    }

    onTapDelete(): void {
        this.dossierService.delete(this.dossier)
            .pipe(takeUntil(this.notifier))
            .subscribe((result: ActionResult) => {
                if (result.success) { this.dossierDeleted.emit(this.dossier); }
            });
    }

    onTapMore(type: string): void {
        this.dossierService.more(this.dossier, type, this.viewContainerRef)
            .pipe(takeUntil(this.notifier))
            .subscribe((context: Context) => {
                if (context && context.action) {
                    setTimeout(() => { this[context.action](); }, 300);
                }
            });
    }
}
