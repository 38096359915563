import {Component, OnInit} from '@angular/core';
import {AppComponent} from '@src/app/app.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@src/app/services/auth.service';
import {ActionResult} from '@src/app/shared/interfaces';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.web.html',
  styleUrls: ['./reset-password-confirm.component.web.scss']
})
export class ResetPasswordConfirmComponent extends BaseComponent implements OnInit {

  title = 'Saisir un nouveau mot de passe';
  mode: MatDrawerMode = 'over';
  backdrop = true;
  isBusy = false;
  token: string;
  password: any = {
    first: '',
    second: '',
  };

  constructor(
      private appComponent: AppComponent,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private authService: AuthService,
  ) {
    super();
    this.sharedService.updateTitle(this.title);
    this.sharedService.updateMode(this.mode);
    this.sharedService.updateBackdrop(this.backdrop);
  }

  ngOnInit(): void {
    this.activatedRoute.params
        .pipe(takeUntil(this.notifier))
        .subscribe(params => {
          this.token = params['token'];
        });
  }

  submit() {
    this.isBusy = true;
    this.authService.resetPasswordConfirm(this.token, this.password)
        .pipe(takeUntil(this.notifier))
        .subscribe((result: ActionResult) => {
          if (result.success) {
            this.router.navigate(['/login']);
          }
          this.isBusy = false;
        }, () => this.isBusy = false);
  }

}
