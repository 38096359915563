import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {MatDrawerMode} from '@angular/material/sidenav';
import {RoleType} from '@src/app/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    backdrop: Observable<boolean>;
    backdropObserver: Observer<boolean>;
    connected: Observable<boolean>;
    connectedObserver: Observer<boolean>;
    initiale: Observable<string>;
    initialeObserver: Observer<string>;
    mode: Observable<MatDrawerMode>;
    modeObserver: Observer<MatDrawerMode>;
    roles: Observable<RoleType[]>;
    rolesObserver: Observer<RoleType[]>;
    title: Observable<string>;
    titleObserver: Observer<string>;

    constructor() {
        this.backdrop = new Observable<boolean>(subscriber => {
            this.backdropObserver = subscriber;
        });
        this.connected = new Observable<boolean>(subscriber => {
            this.connectedObserver = subscriber;
        });
        this.initiale = new Observable<string>(subscriber => {
            this.initialeObserver = subscriber;
        });
        this.mode = new Observable<MatDrawerMode>(subscriber => {
            this.modeObserver = subscriber;
        });
        this.roles = new Observable<RoleType[]>(subscriber => {
            this.rolesObserver = subscriber;
        });
        this.title = new Observable<string>(subscriber => {
           this.titleObserver = subscriber;
        });
    }

    updateBackdrop(backdrop: boolean): void {
        this.backdropObserver.next(backdrop);
    }

    updateConnected(connected: boolean): void {
        this.connectedObserver.next(connected);
    }

    updateInitiale(initiale: string): void {
        this.initialeObserver.next(initiale);
    }

    updateMode(mode: MatDrawerMode): void {
        this.modeObserver.next(mode);
    }

    updateRoles(roles: RoleType[]): void {
        this.rolesObserver.next(roles);
    }

    updateTitle(title: string): void {
        this.titleObserver.next(title);
    }
}
