<!--<p>upload-file works!</p>-->
<div class="row px-3">
    <div class="col-md-9">
        <div class="d-flex flex-column bg-primary-light rounded-bottom p-3 mb-3">
            <h2>Ajouter un fichier</h2>
            <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded px-2 ml-2">
                <div class="d-flex mx-2 p-2">{{ fileName }}</div>
            </div>
        </div>
        <div class="d-flex flex-column bg-primary-light rounded p-3">
            <div *ngIf="dossier" class="d-flex flex-column bg-primary-light rounded p-3 ">

                <h2>Emplacement préconisé</h2>
                <div class="breadcrumb text-primary d-flex flex-row align-items-center">
                    <mat-icon color="primary">work</mat-icon>
                    > <span class="breadcrumb-item">{{dossier.affair.label}}</span>
                    <span *ngFor="let parentDossier of dossier.parentDossiers">
                        > <span class="breadcrumb-item">{{parentDossier.label}}</span>
                    </span>
                </div>
                <p>Éléments existants dans ce dossier :</p>
                <div *ngFor="let documentHistorique of dossier.documentHistoriques ">
                    <div *ngIf="documentHistorique.id != documentId" class="d-flex flex-row align-items-center mt-2">
                        <div class="d-flex flex-row  justify-content-between align-items-center flex-grow-1 bg-white rounded px-2">
                            <div class="d-flex col-4 mx-2 p-2">{{documentHistorique.name}}</div>
                            <div class="d-flex mx-2 p-2">{{ convertDate(documentHistorique.document.dateCreated)}}</div>
                            <div class="d-flex mx-2 p-2">{{documentHistorique.document.user.fullName}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" col-md-3 d-flex  flex-column bd-highlight mb-3">
        <div class="bd-highlight">
            <h2>Tags sur ce fichier</h2>
        </div>
        <div *ngFor="let tag of tags; let i = index" [attr.data-index]="i" class="bd-highlight tags">
            <span class="d-flex flex-row align-items-center mt-2">
                {{tag}}
                <mat-icon (click)="removeTag(i)">clear</mat-icon>
            </span>
        </div>
        <div class="mt-auto p-2 bd-highlight add-new-tag">
            <span class="d-flex flex-row align-items-center mt-2">
                <mat-icon (click)="addNewTagToDocument()">add</mat-icon>
                <input type="text" (keyup.enter)="addNewTagToDocument()" (change)="setNewTagValue($event.target.value)"
                       [ngModel]=newTag
                       placeholder="Ajouter un tag"/>
            </span>
        </div>
    </div>
    <div class="col-md-9 mt-4 buttons d-flex bd-highlight">
        <div class="col-md-7 d-flex justify-content-end">
            <button (click)="goBack()" mat-flat-button class="text-primary cancel cursor-pointer">Annuler</button>
        </div>
        <div class="col-md-5 d-flex justify-content-end">
            <button (click)="sendTags()" mat-flat-button class="accept cursor-pointer">Valider</button>
        </div>
    </div>
</div>

