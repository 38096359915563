import {Document} from '@src/app/model/document.model';
import {User} from '@src/app/model/user.model';
import {Dossier} from '@src/app/model/dossier.model';
import {Affair} from '@src/app/model/affair.model';
import {Step} from '@src/app/model/affair-step.model';

export class SpecificDocumentHistorique {
    version: {
        id: number;
        label: string;
    };
    user?: User;
}

export class DocumentHistorique {
    id: number;
    document: Document;
    dossier: Dossier;
    affair: Affair;
    step: Step;
    version: string;
    name: string;
    shorcut?: boolean;
    link: string;
    icon: string;
    downloadLink: string;
    iconLink: string;
    thumbnailLink: string;
    embedLink: string;
    previousLink: number;
    nextLink: number;
    webViewLink: string;
    state: number;
    dateCreated: string;
    externalObject: string;
    externalType: string;
    externalRevision: string;
    mimeType: string;
    type: string;
    nbNotifications: {
        total: number;
        new?: number;
        update?: number;
    };
    user: User;
    nbDiscutions: number;
    allowTo: {
        edit: number,
        delete: number,
        validate: number,
    };
    specificDetail?: SpecificDocumentHistorique;
    highlight: string[];
}
