import {SimpleChange} from '@angular/core';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {Affair} from '@src/app/model/affair.model';
import {Society} from '@src/app/model/society.model';
import {Trade} from '@src/app/model/trade.model';
import {User} from '@src/app/model/user.model';
import {Dossier} from '@src/app/model/dossier.model';

export interface RestResult {
    page: number;
    total: number;
}

export interface SimpleResponseObject {
    id: number;
    label: string;
}

export interface SimpleResponseObjectId {
    id: number;
}

export interface FeedResult extends RestResult {
    actualite: Feed[];
}

export interface Feed {
    user: FeedUser;
    affair: FeedAffair;
    step: FeedStep;
    type: FeedType;
    datetime: string;
    dossier: FeedFile;
    documentHistorique: FeedAttachment;
    comment?: string;
}

export interface FeedUser {
    id: number;
    initiale: string;
    fullName: string;
}

export interface FeedAffair {
    id: number;
    label: string;
}

export interface FeedStep {
    label: string;
}

export type FeedType = 'ADD_FILE' | 'VALID' | 'REFUSE' | 'REFUSE_SOCIETY' | 'VALID_SOCIETY';

export type RoleType = 'ROLE_USER' | 'ROLE_CLIENT' | 'ROLE_METIER' | 'ROLE_AFFAIRE' | 'ROLE_SOCIETY' | 'ROLE_ADMIN' | 'ROLE_SUPER_ADMIN' | 'ROLE_GUEST';

export type dossierCode =
    'CCTP_1'
    | 'PLAN_1'
    | 'PHOTO_1'
    | 'CHIFFRAGE_1'
    | 'ANALYSE_1'
    | 'DEVIS_1'
    | 'CCTP_2'
    | 'PLAN_2'
    | 'PHOTO_2'
    | 'HYPOTHESE_2'
    | 'CHARGE_3'
    | 'CALCUL_3'
    | 'ENSEMBLE_3'
    | 'PLAN_3'
    | 'PRODUCTION_3'
    | 'POSE_3'
    | 'OUVRAGE_3'
    | 'MODIFICATION_3'
    | 'FABRICATION_4'
    | 'ACHAT_4'
    | 'FACONNAGE_4'
    | 'QUINCAILLERIE_4'
    | 'TRAITEMENT_4'
    | 'COMMANDER_4'
    | 'PLANNING_4'
    | 'PROTOCOLE_5'
    | 'BORDEREAU_5'
    | 'COMMANDE_5'
    | 'POSE_6'
    | 'PPSPS_6'
    | 'MODIFICATION_6'
    | 'DECLARATION_6'
    | 'RECEPTION_7'
    | 'BATIMENT_7'
    | 'DOE_7';

export type ActivityType =
    'ADD_FILE'
    | 'UPDATE_FILE'
    | 'VALID'
    | 'REFUSE'
    | 'REFUSE_SOCIETY'
    | 'VALID_SOCIETY'
    | 'ADD_FAVORIS'
    | 'REMOVE_FAVORIS'
    | 'COMMENT'
    | 'ADD_SUIVIS'
    | 'REMOVE_SUIVIS'
    | 'FILE_VALID'
    | 'FILE_WORK'
    | 'FILE_DO';

export interface FeedFile {
    id: number;
    label: string;
}

export interface FeedAttachment {
    id: number;
    label: string;
    path: string;
    type: string;
    version: string;
}

export interface ActivityResult extends RestResult {
    activites: Activity[];
}

export interface Activity {
    id: number;
    user: FeedUser;
    type: ActivityType;
    datetime: string;
    message: string;
}

export interface SimpleChanges {
    [propName: string]: SimpleChange;
}

export interface BottomSheetActionOptions {
    state?: number;
    type?: string;
}

export interface Context {
    label: {
        text: string;
        class: string;
    };
    icon: {
        text: string;
        class: string;
    };
    action: string|number;
}

export interface ClicEmitter {
    documentHistorique: DocumentHistorique;
    isOpen: boolean;
}

export class AffairsResult {
    page: number;
    total: number;
    affairs: [Affair];
}

export class SocietiesResult {
    page: number;
    total: number;
    societies: [Society];
}

export class TradesResult {
    page: number;
    total: number;
    trades: [Trade];
}

export class UsersResult {
    page: number;
    total: number;
    users: [User];
}

export class NotifyResult {
    page: number;
    total: number;
    notifys: [Notify];
}

export class Notify {
    id: number;
    affair: Affair;
    dossier: Dossier;
    document: Document;
    read: boolean;
    state: number;
    type: string;
    dateCreated: string;
    dateUpdated: string;
}

export class MessageResult {
    page: number;
    total: number;
    discutions: [Message];
}

export class Message {
    id: number;
    document: DocumentHistorique;
    user: User;
    affair: Affair;
    dossier: Dossier;
    message: string;
    datetime: string;
}

export class Planning {
    page: number;
    total: number;
    planning: PlanningsList[];
}

export class PlanningsList {
    id: number;
    affair: {
        id: number;
        label: string;
    };
    step: {
        id: number;
        label: string;
    };
    detail: string;
    date: string;
}

export class ActionResult {
    error?: boolean;
    success?: boolean;
}

export class ActionUserResult extends ActionResult {
    user?: boolean;
}

export class ActionVersionResult extends ActionResult {
    version?: {
        id: number,
        label: string;
    };
}

export class ActionStateResult extends ActionResult {
    avancement?: {
        3: {
            pourcent: number,
            color: string
        },
        2: {
            pourcent: number,
            color: string
        },
        1: {
            pourcent: number,
            color: string
        }
    };
}

export class BridgeModalStorage {
    from?: string;
    to?: string;
    storage?: string[] = [];

    constructor(interModalStorage: BridgeModalStorage = {}) {
        this.from = interModalStorage?.from;
        this.to = interModalStorage?.to;
        this.storage = interModalStorage?.storage || [];
    }
}

export class FilterAuthorize {
    name: string;
    affair: number;
    trade: number;

    constructor() {
        this.name = '';
        this.affair = null;
        this.trade = null;
    }
}

export class AffairNavItems {
    back: boolean;
    state: boolean;
    views: boolean;
    collaboratif: boolean;
    equipe: boolean;
}

export class AffairViewsItems {
    isGlobalView: boolean;
    isEtapeView: boolean;
    isCalendarView: boolean;
}

export class AnnuaireNavItems {
    back: boolean;
    state: boolean;
    views: boolean;
    add: boolean;
    filter: boolean;
}

export class AnnuaireViewsItems {
    isSociety: boolean;
    isUser: boolean;
}

export class TokenResult {
    token: string;
    user: User;
    guestAffair: string;
    guestDossier: string;
    contact: {
        id: number;
    };
}

export class StorageView {
    key: string|number;
    value: string;
}

export class AffairFilter {
    search: string;
    state: string;
    step: string;
    user: number;

    constructor() {
    }
}

export class UserFilter {
    name: string;
    trade: number;
    society: number;
    visible: boolean;

    constructor() {
    }
}

export class SocietyFilter {
    name: string;
    user: number;

    constructor() {
    }
}

export class ItemUpload {
    name?: string;
    isProcessing: boolean;
    isUploading: boolean;
    progress: number;

    constructor(name?: string) {
        this.name = name || null;
        this.isProcessing = false;
        this.isUploading = true;
        this.progress = 0;
    }
}
