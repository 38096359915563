import * as moment from 'moment';

import {CalendarDateFormatter, DateFormatterParams} from 'angular-calendar';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {

    public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
        const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Vend', 'Sam', 'Dim'];
        const localeDayOfWeek = moment(date).locale('fr').format('e');
        return days[localeDayOfWeek];
    }
}
