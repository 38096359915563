import {Injectable} from '@angular/core';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {ApiService} from '@src/app/services/api.service';
import {Dossier} from '@src/app/model/dossier.model';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor(private apiService: ApiService) {
    }

    // getFile(blob, name: string): Promise<any> {
    getFile(documentHistorique: DocumentHistorique): Promise<void> {
        return new Promise((resolve, reject) => {
            this.apiService.downloadDocument(documentHistorique.id).subscribe((resultBlob) => {
                if (documentHistorique.nbNotifications.total >= 1) {
                    documentHistorique.nbNotifications.total = documentHistorique.nbNotifications.total - 1;
                }
                const name = documentHistorique.name;
                const url = window.URL.createObjectURL(resultBlob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = name;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                resolve();
            });
        });
    }

    getFiles(dossier: Dossier, parent: Dossier = null): Promise<void> {
        return new Promise((resolve, reject) => {
            this.apiService.downloadAllDocument(dossier.id).subscribe((resultBlob: any) => {
                let name: string;
                if (parent) {
                    name = parent.affair.label + '_' + parent.step.label + '_documents.zip';
                } else {
                    name = dossier.affair.label + '_' + dossier.step.label + '_documents.zip';
                }
                const url = window.URL.createObjectURL(resultBlob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = name;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                resolve();
            }, reject);
        });
    }
}
