import {Component, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import {SimpleChanges} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {ROLES, User} from '@src/app/model/user.model';
import {ENTITIES, Society, THIRD_PARTIES, TYPES} from '@src/app/model/society.model';

@Component({
    selector: 'app-annuaire-user-detail',
    templateUrl: './annuaire-user-detail.component.html',
    styleUrls: ['./annuaire-user-detail.component.scss'],
})
export class AnnuaireUserDetailComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() viewContainerRef: ViewContainerRef;
    @Input() user: User;
    isBusy: boolean;
    society: Society;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.isBusy = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.society = null;
    }

    getRole(key: string): string {
        return ROLES.find(r => r.key === key).label;
    }

    getType(key: number): string {
        return TYPES.find(t => t.key === key).label;
    }

    getLegalEntity(key: number): string {
        return ENTITIES.find(e => e.key === key).label;
    }

    getThirdParty(key: number): string {
        return THIRD_PARTIES.find(tp => tp.key === key).label;
    }

    onTapSociety() {
        this.society = this.user.contact.society;
    }
}
