import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Dossier} from '@src/app/model/dossier.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {ApiService} from '@src/app/services/api.service';
import {ActionResult} from '@src/app/shared/interfaces';

@Component({
    selector: 'app-modal-favori',
    templateUrl: 'modal-favori-component.web.html',
})
export class ModalFavoriComponent implements OnInit, OnDestroy {

    notifier = new Subject();
    shortcut: Dossier;
    version: Dossier;
    isLoading: boolean;
    isSaving: boolean;
    uploading: boolean;
    processing: boolean;
    progress: number;
    formControlObj: FormControl;
    selectedFavoris: DocumentHistorique[] = [];

    constructor(
        public dialogRef: MatDialogRef<ModalFavoriComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {shortcut: Dossier, version: Dossier, isOrphan: boolean},
        private apiService: ApiService,
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.isSaving = false;
        this.version = this.data.version;
        if (this.data.shortcut.id !== this.data.version.id) {
            this.apiService.getDossier(this.data.shortcut.id)
                .pipe(takeUntil(this.notifier))
                .subscribe((dossier: Dossier) => {
                    this.shortcut = dossier;
                    this.getFavoris();
                });
        } else {
            this.shortcut = this.data.shortcut;
            this.getFavoris();
        }
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    getFavoris() {
        this.apiService.getDossierShortcutFavoris(this.version.id, this.shortcut.id)
            .pipe(takeUntil(this.notifier))
            .subscribe((documentHistoriques: DocumentHistorique[]) => {
                this.selectedFavoris = documentHistoriques;
                this.formControlObj = new FormControl(this.selectedFavoris);
                this.formControlObj.valueChanges
                    .pipe(takeUntil(this.notifier))
                    .subscribe(value => this.selectedFavoris = value );
                this.isLoading = false;
            });
    }

    onClose(args = null) {
        this.dialogRef.close(args);
    }

    onSubmit() {
        if (this.data.isOrphan) {
            if (this.formControlObj.valid) {
                this.isSaving = true;
                const data = {documentHistoriques: this.formControlObj.value.map(f => f.id)};
                this.apiService.postDossierShortcutFavoris(this.version.id, this.shortcut.id, data)
                    .pipe(takeUntil(this.notifier))
                    .subscribe((result: ActionResult) => {
                        this.isSaving = false;
                        if (result) { this.onClose(result); }
                    }, () => this.isSaving = false);
            }
        } else {
            this.onClose(this.selectedFavoris);
        }
    }

    trackBy(index, item) {
        return item.id;
    }

    compareList(c1: { id: number }, c2: { id: number }) {
        return c1 && c2 && c1.id === c2.id;
    }
}
