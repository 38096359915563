import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DrawerService {

    public show() {
        // do nothing or add web implementation here
    }

    public hide() {
        // do nothing or add web implementation here
    }
}
