<h1 mat-dialog-title >
    <span class="d-inline-block mt-1">{{data.dossier?.dateLimit ? 'Modifier' : 'Ajouter'}} une date limite</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <form [formGroup]="dossierForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="input-full-width">
                        <mat-label>Date limite</mat-label>
                        <input matInput formControlName="dateLimit" [matDatepicker]="picker" (focusin)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>
<div *ngIf="!isLoading" mat-dialog-actions class="justify-content-end pb-3">
    <button [disabled]="!dossierForm.valid || isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">save</mat-icon>
        {{ dossier.dateLimit ? 'Modifier' : 'Ajouter' }}
    </button>
</div>
