<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">Sélection des personnes</span>
    <button (click)="onClose()" mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div *ngIf="!isLoading" mat-dialog-actions class="py-0 mb-0">
    <form [formGroup]="searchForm" class="flex-fill">
        <div class="row">
            <div *ngIf="dossier.step.id > 1" class="col-auto pt-1">
                <button type="button" (click)="onTapAddUser('user')" mat-icon-button color="primary" matTooltip="Ajouter un utilisateur" matTooltipPosition="above" [class.blink]="!allUsers.length">
                    <mat-icon>person_add</mat-icon>
                </button>
            </div>

            <div *ngIf="dossier.step.id == 1" class="col-auto pt-1">
                <button type="button" (click)="onTapAddUser('guest')" mat-icon-button color="primary" matTooltip="Ajouter un invité" matTooltipPosition="above" [class.blink]="!allUsers.length">
                    <mat-icon>person_add_alt</mat-icon>
                </button>
            </div>

            <div *ngIf="dossier.step.id > 1" class="col-auto pt-2 mt-1">
                <mat-checkbox formControlName="affair" color="primary" [value]="dossier.affair.id">Affaire</mat-checkbox>
            </div>

            <div class="col-auto">
                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Personne ou Société</mat-label>
                    <input matInput formControlName="name">
                </mat-form-field>
            </div>

            <div class="col-auto">
                <mat-form-field appearance="outline" class="input-full-width">
                    <mat-label>Corps d'état</mat-label>
                    <mat-select formControlName="trade">
                        <mat-option value="">Choisir un corps d'état</mat-option>
                        <mat-option *ngFor="let trade of trades" [value]="trade.id">{{trade.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <mat-selection-list [compareWith]="compareList" [formControl]="formControlObj">
            <div class="row">
                <div class="col-md-6">
                    <div mat-subheader>Recherche</div>
                    <mat-list-option *ngFor="let user of allUsers; trackBy: trackByFn;" [value]="user" color="primary">
                        <div mat-line>
                            {{user.contact.fullName}}<br>
                            <small>{{user.contact.email}}</small>
                        </div>
                    </mat-list-option>
                </div>
                <div class="col-md-6">
                    <div mat-subheader>Sélectionnées</div>
                    <mat-list-option *ngFor="let user of selectedUsers; trackBy: trackByFn;" [value]="user" color="primary">
                        <div mat-line>
                            {{user.contact.fullName}}<br>
                            <small>{{user.contact.email}}</small>
                        </div>
                    </mat-list-option>
                </div>
            </div>
        </mat-selection-list>
    </div>
</div>
<div *ngIf="!isLoading" mat-dialog-actions class="justify-content-end pb-3">
    <button [disabled]="isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">{{data.parentDossier?.code === 'CHIFFRAGE_1' ? 'email' : 'save'}}</mat-icon>
        {{data.parentDossier?.code === 'CHIFFRAGE_1' ? 'Diffuser' : 'Valider'}}
    </button>
</div>
