import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpParams, HttpEvent} from '@angular/common/http';
import {environment} from '@src/environments/environment';
import {StorageService} from '@src/app/services/storage.service';
import {Observable, throwError} from 'rxjs';
import {Affair} from '@src/app/model/affair.model';
import {catchError, flatMap, map} from 'rxjs/operators';
import {Society} from '@src/app/model/society.model';
import {Trade} from '@src/app/model/trade.model';
import { User} from '@src/app/model/user.model';
import {Contact} from '@src/app/model/contact.model';
import {Dossier} from '@src/app/model/dossier.model';
import {
    Activity,
    ActivityResult,
    AffairsResult, ActionResult,
    Feed,
    FeedResult,
    Message,
    MessageResult,
    Notify,
    NotifyResult,
    Planning,
    SocietiesResult,
    TradesResult,
    UsersResult, ActionVersionResult, ActionStateResult
} from '@src/app/shared/interfaces';
import {AuthService} from '@src/app/services/auth.service';
import {SearchDocumentFilters, SearchRestResult} from '@src/app/shared/search.interfaces';
import {SearchParameters} from '@src/app/search/search.component';
import {AffairStep} from '@src/app/model/affair-step.model';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {DialogService} from '@src/app/services/dialog.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    apiUrl = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private authService: AuthService,
        private dialogService: DialogService,
    ) {
        // console.log('environment', environment);
    }

    public getOption() {
        return {
            headers: new HttpHeaders({
                accept: 'application/json',
                Authorization: 'Bearer ' + this.storageService.token
            })
        };
    }

    public getOptionDownload() {
        return {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.storageService.token,
            }),
            responseType: 'blob'
        };
    }

    public getOptionUpload() {
        return {
            reportProgress: true,
            headers: new HttpHeaders({
                accept: 'application/json',
                Authorization: 'Bearer ' + this.storageService.token
            })
        };
    }

    private handleError = (
        error: HttpErrorResponse,
        caught: Observable<any>,
        authService: AuthService,
        method: string,
        arg1: any = null,
        arg2: any = null,
        arg3: any = null,
        arg4: any = null,
    ) => {
        if (error.status === 401) {
            return authService.autoLogin()
                .pipe(
                    flatMap(res => {
                        const callback: Observable<any> = this[method](arg1, arg2, arg3, arg4);
                        return callback;
                    })
                );
        } else if (error.status === 403) {
            this.dialogService.alert(error);
            return throwError('You don\'t have permission to make this request.');
        }
        return throwError('Something bad happened; please try again later.');
    }

    public getData() {
        return this.http.get('get-data-request');
    }

    public getHighlight() {
        return this.apiUrl;
    }

    // ------------------AFFAIR-----------------
    public getAffairs(page: number = 1, itemPerPage: number = 24): Observable<AffairsResult> {
        return this.http.get<AffairsResult>(
            `${this.apiUrl}/rest/api/affairs/list?page=${page}&itemPerPage=${itemPerPage}`,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'getAffairs', page, itemPerPage);
            }),
        );
    }

    public getChantier(): Observable<AffairsResult> {
        return this.http.get<AffairsResult>(`${this.apiUrl}/rest/api/affairs/chantier`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getChantier');
                }),
            );
    }

    public getProjet(): Observable<AffairsResult> {
        return this.http.get<AffairsResult>(`${this.apiUrl}/rest/api/affairs/projet`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getProjet');
                }),
            );
    }

    public getAffair(id: number|string): Observable<Affair> {
        return this.http.get<Affair>(`${this.apiUrl}/rest/api/affairs/detail-edit/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getAffair', id);
                }),
            );
    }

    public getAffairLight(id: number|string): Observable<Affair> {
        return this.http.get<Affair>(`${this.apiUrl}/rest/api/affairs/light/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getAffairLight', id);
                }),
            );
    }

    public postAffair(formData: FormData): Observable<Affair> {
        return this.http.post<Affair>(`${this.apiUrl}/rest/api/affairs/edit`, formData, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postAffair', formData);
                }),
            );
    }

    public putAffair(id: number, formData: FormData): Observable<Affair> {
        return this.http.post<Affair>(`${this.apiUrl}/rest/api/affairs/edit/${id}`, formData, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'putAffair', id, formData);
                }),
            );
    }

    public deleteAffair(id: number|string): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/affairs/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteAffair', id);
                }),
            );
    }

    public getAffairsFilter(data: any, page: number = 1, itemPerPage: number = 24): Observable<AffairsResult> {
        return this.http.post<AffairsResult>(
            `${this.apiUrl}/rest/api/affairs/filter?page=${page}&itemPerPage=${itemPerPage}`,
            data,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'getAffairsFilter', data, page, itemPerPage);
            })
        );
    }

    public getAffairSteps(id: number, stepId: number = null): Observable<Affair> {
        return this.http.get<Affair>(`${this.apiUrl}/rest/api/affairs/${id}` + (stepId ? `/${stepId}` : ''), this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getAffairSteps', id, stepId);
                }),
            );
    }

    public getAffairStep(id: number, stepId: number): Observable<AffairStep> {
        return this.http.get<AffairStep>(`${this.apiUrl}/rest/api/affairs/step/${id}/${stepId}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getAffairStep', id, stepId);
                }),
            );
    }

    public getAffairGlobal(id: number|string): Observable<Affair> {
        return this.http.get<Affair>(`${this.apiUrl}/rest/api/affairs/global/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getAffairGlobal', id);
                }),
            );
    }

    public updateStateStepAffair(id: number, state: number): Observable<Affair> {
        return this.http.post<Affair>(`${this.apiUrl}/rest/api/affair-step/update/${id}/${state}`, null, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'updateStateStepAffair', id, state);
                }),
            );
    }

    public updateAffairUserStatus(affairId: number, userId: number, status: number): Observable<ActionResult> {
        return this.http.get<ActionResult>(`${this.apiUrl}/rest/api/affairs/change-user/${affairId}/${userId}/${status}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'updateAffairUserStatus', affairId, userId, status);
                }),
            );
    }

    // ------------------DOSSIER-----------------
    public getDossier(id: number): Observable<Dossier> {
        return this.http.get<Dossier>(`${this.apiUrl}/rest/api/dossiers/${id}`, this.getOption())
            .pipe(
                map(res => Object.assign(new Dossier(), res as Dossier)),
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getDossier', id);
                }),
            );
    }

    public getDossierLight(id: number): Observable<Dossier> {
        return this.http.get<Dossier>(`${this.apiUrl}/rest/api/dossiers/light/${id}`, this.getOption())
            .pipe(
                map(res => Object.assign(new Dossier(), res as Dossier)),
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getDossierLight', id);
                }),
            );
    }

    public getDossierShortcut(id: number): Observable<Dossier[]> {
        return this.http.get<Dossier[]>(`${this.apiUrl}/rest/api/dossiers/${id}/shortcut`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getDossierShortcut', id);
                }),
            );
    }

    public getDossierShortcutFavoris(dossierId: number, shortcutId: number): Observable<DocumentHistorique[]> {
        return this.http.get<DocumentHistorique[]>(
            `${this.apiUrl}/rest/api/dossiers/list/shortcut/favoris/${dossierId}/${shortcutId}`,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'getDossierShortcutFavoris', dossierId, shortcutId);
            }),
        );
    }

    public getDossierShortcutRefreshFavoris(id: number): Observable<Dossier> {
        return this.http.get<Dossier>(`${this.apiUrl}/rest/api/dossiers/shortcut-refresh/favoris/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getDossierShortcutRefreshFavoris', id);
                }),
            );
    }

    public postDossierShortcutFavoris(dossierId: number, shortcutId: number, data: any): Observable<ActionResult> {
        return this.http.post<ActionResult>(
            `${this.apiUrl}/rest/api/dossiers/shortcut/favoris/${dossierId}/${shortcutId}`,
            data,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'postDossierShortcutFavoris', dossierId, shortcutId, data);
            }),
        );
    }

    public postDossierShortcut(id: number, data: any): Observable<DocumentHistorique[]> {
        return this.http.post<DocumentHistorique[]>(`${this.apiUrl}/rest/api/dossiers/shortcut-all/favoris/${id}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postDossierShortcut', id, data);
                }),
            );
    }

    // TODO typer les donner en FormData dans les différente vue
    public addDossier(affairId: number, stepId: number, formData: any, parentId?: number): Observable<Dossier> {
        return this.http.post<Dossier>(
            `${this.apiUrl}/rest/api/dossiers/add/${affairId}/${stepId}` + (parentId ? `/${parentId}` : ''),
            formData,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'addDossier', affairId, stepId, formData, parentId);
            }),
        );
    }

    // TODO typer les donner en FormData dans les différente vue
    public postDossier(id: number, formData: any): Observable<Dossier> {
        return this.http.post<Dossier>(`${this.apiUrl}/rest/api/dossiers/edit/${id}`, formData, this.getOption())
            .pipe(
                map(res => Object.assign(new Dossier(), res as Dossier)),
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postDossier', id, formData);
                }),
            );
    }

    public updateStateDossier(dossierId: number, state: number, isStep: boolean = false): Observable<ActionStateResult> {
        return this.http.post<ActionStateResult>(
            `${this.apiUrl}/rest/api/dossiers/update/${dossierId}/state/${state}`,
            {isStep: isStep},
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'updateStateDossier', dossierId, state, isStep);
            }),
        );
    }

    public getDiffuseMessage(id: number): Observable<{message: string}> {
        return this.http.get<{message: string}>(`${this.apiUrl}/rest/api/dossiers/diffuse-message/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getDiffuseMessage', id);
                }),
            );
    }

    public sendDiffuse(id: number, data: any = {message: ''}): Observable<Dossier> {
        return this.http.post<Dossier>(`${this.apiUrl}/rest/api/dossiers/diffuse/${id}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'sendDiffuse', id, data);
                }),
            );
    }

    public validCAResponse(id: number, state: number): Observable<Dossier> {
        return this.http.get<Dossier>(`${this.apiUrl}/rest/api/dossiers/valid-response-ca/${id}/${state}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'validCAResponse', id, state);
                }),
            );
    }

    public validResponse(id: number, state: number): Observable<Dossier> {
        return this.http.get<Dossier>(`${this.apiUrl}/rest/api/dossiers/valid-response/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'validResponse', id, state);
                }),
            );
    }

    public addVersionDossier(id: number): Observable<ActionVersionResult> {
        return this.http.get<ActionVersionResult>(`${this.apiUrl}/rest/api/dossiers/version/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'addVersionDossier', id);
                }),
            );
    }

    public deleteDossier(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/dossiers/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteDossier', id);
                }),
            );
    }

    public putDossierDateLimite(id: number, data: any): Observable<ActionResult> {
        return this.http.post<ActionResult>(
            `${this.apiUrl}/rest/api/dossiers/date-limit/${id}`,
            data,
            this.getOption()
        )
        .pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'putDossierDateLimite', id, data);
            }),
        );
    }

    // ------------------DOCUMENT-----------------

    public getDocument(id: number): Observable<Document> {
        return this.http.get<Document>(`${this.apiUrl}/rest/api/documents/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getDocument', id);
                }),
            );
    }

    public getPreview(id: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/documents/preview/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getPreview', id);
                }),
            );
    }

    public renameDocument(id: number, name: string): Observable<DocumentHistorique> {
        return this.http.post<DocumentHistorique>(
            `${this.apiUrl}/rest/api/documents/rename/${id}`,
            {name: name},
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'renameDocument', id, name);
            }),
        );
    }

    public putDocumentRead(id: number): Observable<ActionResult> {
        return this.http.get<ActionResult>(
            `${this.apiUrl}/rest/api/documents/read/${id}`,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'putDocumentRead', id);
            }),
        );
    }

    public updateStateDocument(id: number, state: number): Observable<ActionResult> {
        return this.http.post<ActionResult>(
            `${this.apiUrl}/rest/api/documents/update/${id}/state/${state}`,
            null,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'updateStateDocument', id, state);
            }),
        );
    }

    public updateAllStateDocument(id: number, state: number): Observable<Dossier> {
        return this.http.post<Dossier>(
            `${this.apiUrl}/rest/api/dossiers/${id}/documents/update/state/${state}`,
            null,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'updateAllStateDocument', id, state);
            }),
        );
    }

    public postShareDocument(id: number, data: any): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${this.apiUrl}/rest/api/documents/partage/${id}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postShareDocument', id, data);
                }),
            );
    }

    public downloadDocument(id: number): Observable<any> {
        // @ts-ignore
        return this.http.get<any>(`${this.apiUrl}/rest/api/documents/download/${id}`, this.getOptionDownload())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'downloadDocument', id);
                }),
            );
    }

    public downloadAllDocument(id: number): Observable<any> {
        // @ts-ignore
        return this.http.get<any>(`${this.apiUrl}/rest/api/documents/download-all/${id}`, this.getOptionDownload())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'downloadAllDocument', id);
                }),
            );
    }

    // TODO créer class pour ce retour
    public uploadDocument(id: number, formData: FormData): Observable<any> {
        const req = new HttpRequest('POST', `${this.apiUrl}/rest/api/documents/upload/${id}`, formData, this.getOptionUpload());
        return this.http.request(req)
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'uploadDocument', id, formData);
                }),
            );
    }

    // TODO créer class pour ce retour
    public uploadDocuments(id: number, formData: FormData): Observable<any> {
        const req = new HttpRequest('POST', `${this.apiUrl}/rest/api/documents/upload-multiple/${id}`, formData, this.getOptionUpload());
        return this.http.request(req)
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'uploadDocuments', id, formData);
                }),
            );
    }

    public updateDocument(id: number, formData: FormData): Observable<HttpEvent<any>|DocumentHistorique> {
        const req = new HttpRequest('POST', `${this.apiUrl}/rest/api/documents/upload-version/${id}`, formData, this.getOptionUpload());
        return this.http.request(req)
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'updateDocument', id, formData);
                }),
            );
    }

    public deleteDocument(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/documents/delete/historique/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteDocument', id);
                }),
            );
    }

    public addFavorisDocument(id: number): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${this.apiUrl}/rest/api/favoris/add/${id}`, null, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'addFavorisDocument', id);
                }),
            );
    }

    public deleteFavorisDocument(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/favoris/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteFavorisDocument', id);
                }),
            );
    }

    public addSuivisDocument(id: number): Observable<ActionResult> {
        return this.http.post<ActionResult>(`${this.apiUrl}/rest/api/suivis/add/${id}`, null, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'addSuivisDocument', id);
                }),
            );
    }

    public deleteSuivisDocument(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/suivis/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteSuivisDocument', id);
                }),
            );
    }

    public getActivityList(id: number): Observable<Activity[]> {
        return this.http.get<Activity[]>(`${this.apiUrl}/rest/api/activite/document/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getActivityList', id);
                }),
                map((data: ActivityResult) => data.activites)
            );
    }

    public postActivityMessage(id: number, data): Observable<Activity[]> {
        return this.http.post<Activity[]>(`${this.apiUrl}/rest/api/discution/add-global-message/${id}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postActivityMessage', id, data);
                }),
                map((result: ActivityResult) => result.activites)
            );
    }

    // ------------------SOCIETY-----------------
    public getSocieties(page: number = 1, itemPerPage: number = 10): Observable<SocietiesResult> {
        return this.http.get<SocietiesResult>(
            `${this.apiUrl}/rest/api/params/society/list?page=${page}&itemPerPage=${itemPerPage}`,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'getSocieties', page, itemPerPage);
            }),
        );
    }

    public getSocietiesFilter(data: any, page: number = 1, itemPerPage: number = 10): Observable<SocietiesResult> {
        return this.http.post<SocietiesResult>(
            `${this.apiUrl}/rest/api/params/society/filter?page=${page}&itemPerPage=${itemPerPage}`,
            data,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'getSocietiesFilter', data, page, itemPerPage);
            }),
        );
    }

    public getSociety(id: number): Observable<Society> {
        return this.http.get<Society>(`${this.apiUrl}/rest/api/params/society/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getSociety', id);
                }),
            );
    }

    public getFilterSociety(): Observable<Society> {
        return this.http.get<Society>(`${this.apiUrl}/rest/api/params/society/filter`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getFilterSociety');
                }),
            );
    }

    public putSociety(id: number, data): Observable<Society> {
        return this.http.post<Society>(`${this.apiUrl}/rest/api/params/society/edit/${id}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'putSociety', id, data);
                }),
            );
    }

    public postSociety(data: any): Observable<Society> {
        return this.http.post<Society>(`${this.apiUrl}/rest/api/params/society/edit`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postSociety', data);
                }),
            );
    }

    public deleteSociety(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/params/society/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteSociety', id);
                }),
            );
    }

    // ------------------TRADE-----------------
    public getTrades(): Observable<TradesResult> {
        return this.http.get<TradesResult>(`${this.apiUrl}/rest/api/trades/list`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getTrades');
                }),
            );
    }

    public getTrade(id: number): Observable<Trade> {
        return this.http.get<Trade>(`${this.apiUrl}/rest/api/trades/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getTrade', id);
                }),
            );
    }

    public postTrade(data: any): Observable<Trade> {
        return this.http.post<Trade>(`${this.apiUrl}/rest/api/trades/edit`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postTrade', data);
                }),
            );
    }

    public putTrade(id: number, data: any): Observable<Trade> {
        return this.http.post<Trade>(`${this.apiUrl}/rest/api/trades/edit/${id}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'putTrade', id, data);
                }),
            );
    }

    public deleteTrade(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/trades/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteTrade', id);
                }),
            );
    }

    // ------------------USER-----------------
    // TODO voir le type de donnée retourner, faire class si besoin
    public getUserInfosDashboard(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/rest/api/users/infos`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getUserInfosDashboard');
                }),
            );
    }

    public getUsers(page: number = 1, itemPerPage: number = 50): Observable<UsersResult> {
        return this.http.get<UsersResult>(`${this.apiUrl}/rest/api/users/list?page=${page}&itemPerPage=${itemPerPage}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getUsers', page, itemPerPage);
                }),
            );
    }

    public getUsersFilter(data: any, page: number = 1, itemPerPage: number = 50): Observable<User[]> {
        return this.http.post<UsersResult>(
            `${this.apiUrl}/rest/api/users/filter?page=${page}&itemPerPage=${itemPerPage}`,
            data,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'getUsersFilter', data, page, itemPerPage);
            }),
            map((usersResult: UsersResult) => usersResult.users)
        );
    }

    public getUsersAnnuaire(data: any, page: number = 1, itemPerPage: number = 50): Observable<UsersResult> {
        return this.http.post<UsersResult>(
            `${this.apiUrl}/rest/api/users/filter?page=${page}&itemPerPage=${itemPerPage}`,
            data,
            this.getOption()
        ).pipe(
            catchError((err, caught) => {
                return this.handleError(err, caught, this.authService, 'getUsersAnnuaire', data, page, itemPerPage);
            }),
        );
    }

    public getUsersEmail(data: any): Observable<string[]> {
        return this.http.post<string[]>(`${this.apiUrl}/rest/api/users/email/filter`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getUsersEmail', data);
                })
            );
    }

    public getChargeAffairs(): Observable<UsersResult> {
        return this.http.get<UsersResult>(`${this.apiUrl}/rest/api/users/ca`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getChargeAffairs');
                }),
            );
    }

    public getUser(id: number): Observable<User> {
        return this.http.get<User>(`${this.apiUrl}/rest/api/users/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getUser', id);
                }),
            );
    }

    public getPlanning(): Observable<Planning> {
        return this.http.get<Planning>(`${this.apiUrl}/rest/api/planning/list`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getPlanning');
                }),
            );
    }

    public deleteUser(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/users/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteUser', id);
                }),
            );
    }

    // ------------------Contact-----------------
    public putAccount(id: number, data: any): Observable<Contact> {
        return this.http.post<Contact>(`${this.apiUrl}/rest/api/users/edit/${id}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'putAccount', id, data);
                }),
            );
    }

    public getContact(id: number): Observable<Contact> {
        return this.http.get<Contact>(`${this.apiUrl}/rest/api/contact/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getContact', id);
                }),
            );
    }

    public postContact(contact, society: string|number): Observable<User> {
        return this.http.post<User>(`${this.apiUrl}/rest/api/contact/edit/${society}`, contact, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postContact', contact, society);
                }),
            );
    }

    public postGuest(contact): Observable<Contact> {
        return this.http.post<Contact>(`${this.apiUrl}/rest/api/contact/guest/edit`, contact, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'postContact', contact);
                }),
            );
    }

    public putGuest(contact, guest: number): Observable<Contact> {
        return this.http.post<Contact>(`${this.apiUrl}/rest/api/contact/guest/edit/${guest}`, contact, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'putGuest', contact, guest);
                }),
            );
    }

    public putContact(societyId: string|number, contactId: string|number, data: any): Observable<Contact> {
        return this.http.post<Contact>(`${this.apiUrl}/rest/api/contact/edit/${societyId}/${contactId}`, data, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'putContact', societyId, contactId, data);
                }),
            );
    }

    public deleteContact(id: number): Observable<ActionResult> {
        return this.http.delete<ActionResult>(`${this.apiUrl}/rest/api/contact/delete/${id}`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'deleteContact', id);
                }),
            );
    }

    // ------------- Feed List---------------------

    public getFeedList(): Observable<Feed[]> {
        return this.http.get<Feed[]>(`${this.apiUrl}/rest/api/actualite/list`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getFeedList');
                }),
                map((data: FeedResult) => data.actualite)
            );
    }

    public getAffairList(): Observable<Affair[]> {
        return this.http.get<Affair[]>(`${this.apiUrl}/rest/api/affairs/list-progress`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getAffairList');
                }),
                map((data: AffairsResult) => data.affairs)
            );
    }

    public getMessageList(): Observable<Message[]> {
        return this.http.get<Message[]>(`${this.apiUrl}/rest/api/users/discutions`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getMessageList');
                }),
                map((data: MessageResult) => data.discutions)
            );
    }

    public getNotificationList(): Observable<Notify[]> {
        return this.http.get<Notify[]>(`${this.apiUrl}/rest/api/users/notify`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getNotificationList');
                }),
                map((data: NotifyResult) => data.notifys)
            );
    }

    public setTagsToFile(id: number, tags): Observable<ActionResult> {
        return this.http.post<ActionResult>(this.apiUrl + `/rest/api/documents/tags/${id}`, {tags}, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'setTagsToFile', id, tags);
                }),
                map((data) => data)
            );
    }

    public setTagsToMultipleFile(id: number, tags): Observable<ActionResult> {
        return this.http.post<ActionResult>(this.apiUrl + `/rest/api/documents/tags-multiple/${id}`, {tags}, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'setTagsToMultipleFile', id, tags);
                }),
                map((data) => data)
            );
    }

    // TODO a déplacer dans document service
    public searchDocument(searchParameters: SearchParameters): Observable<DocumentHistorique[]> {
        let params = new HttpParams();
        params = params.set('terms', searchParameters.terms);
        params = params.append('filtres[dirid]', searchParameters.filters.dirids?.map(data => data.id).join(','));
        params = params.append('filtres[personne]', searchParameters.filters.personnes?.map(data => data.id).join(','));
        params = params.append('filtres[affair]', searchParameters.filters.affairs?.map(data => data.id).join(','));
        params = params.append('filtres[step]', searchParameters.filters.steps?.map(data => data.id).join(','));
        params = params.append('filtres[date]', searchParameters.filters.date);
        params = params.append('filtres[after]', searchParameters.filters.after);
        params = params.append('filtres[before]', searchParameters.filters.before);

        const options = {...this.getOption(), params};

        return this.http.get<DocumentHistorique[]>(`${this.apiUrl}/rest/api/documents/search`, options)
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'searchDocument', searchParameters);
                }),
                map((data: SearchRestResult) => data.documents)
            );
    }

    public getDocumentsFilters(): Observable<SearchDocumentFilters> {
        return this.http.get<SearchDocumentFilters>(`${this.apiUrl}/rest/api/documents/filter-search`, this.getOption())
            .pipe(
                catchError((err, caught) => {
                    return this.handleError(err, caught, this.authService, 'getDocumentsFilters');
                }),
                map((data: SearchDocumentFilters) => data)
            );
    }
}
