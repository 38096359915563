<div *ngIf="!uploading" class="row no-gutters flex-nowrap align-items-center mt-2 overflow-hidden">
    <div class="col-auto pr-2">
        <span [class.invisible]="documentHistorique.nbDiscutions == 0"
              class="d-inline-flex badge justify-content-center align-items-center">{{documentHistorique.nbDiscutions}}</span>
    </div>
    <div class="col overflow-hidden">
        <div class="row no-gutters align-items-center rounded overflow-hidden border-left-5"
             [class.border-left-white]="!open || (disabled && open)" [class.border-left-primary]="!disabled && open"
             [class.border-left-danger]="documentHistorique.nbNotifications.total > 0" [class.bg-white]="!open || (disabled && open)"
             [class.bg-primary]="!disabled && open" [class.text-white]="!disabled && open">
            <div class="col-auto">
                <button (click)="toggleFavoriDocument()" [disabled]="spinner.favoris" mat-icon-button>
                    <mat-spinner *ngIf="spinner.favoris" class="btn-spinner" [class.btn-spinner-white]="open"></mat-spinner>
                    <mat-icon *ngIf="documentHistorique.document.isFavoris && !spinner.favoris" class="text-warning">star</mat-icon>
                    <mat-icon *ngIf="!documentHistorique.document.isFavoris && !spinner.favoris">star_outline</mat-icon>
                </button>
            </div>
            <div (click)="!disabled && onTapViewer()" [matRippleDisabled]="disabled" [matTooltipDisabled]="disabled"
                 class="has-ripple col overflow-hidden cursor-pointer d-flex align-self-stretch px-2"
                 matRipple matTooltip="Visualiser le document" matTooltipPosition="above">
                <div class="row no-gutters align-items-center overflow-hidden flex-fill">
                    <div class="col-xl-6 text-truncate overflow-hidden">
                        {{documentHistorique.name}}
                    </div>
                    <div class="d-none d-xl-block col-xl-6 text-right">
                        <span class="d-inline-block">{{documentHistorique.document.user.fullName}}</span>
                        <span class="d-inline-block ml-2">{{documentHistorique.dateCreated | date: 'dd/MM/yyyy'}}</span>
                        <span class="d-inline-block ml-2">{{documentHistorique.version}}</span>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <button (click)="toggle()" [disabled]="disabled" [matTooltipDisabled]="disabled" mat-icon-button matTooltip="Afficher les actions" matTooltipPosition="above">
                    <mat-icon *ngIf="!open || (disabled && open)">expand_more</mat-icon>
                    <mat-icon *ngIf="!disabled && open">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="uploading" class="d-flex flex-row align-items-center mt-2">
    <div class="d-flex flex-row align-items-center flex-grow-1 bg-white rounded p-2 ml-4 ">
        <mat-icon *ngIf="!processing">publish</mat-icon>
        <mat-spinner *ngIf="processing" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <div class="d-flex flex-grow-1 mx-2">
            <mat-progress-bar *ngIf="!processing" mode="determinate" [value]="progress"></mat-progress-bar>
            <span *ngIf="processing">Traitement du fichier en cours ...</span>
        </div>
    </div>
</div>
<div *ngIf="!disabled && open" class="d-flex flex-row flex-wrap align-items-center ml-4">
    <button *ngIf="documentHistorique.document.nbVersions > 1" (click)="toggleVersion()" mat-button
            class="btn-document-action">
        <mat-icon>timeline</mat-icon>
        Versions
    </button>
    <button (click)="onTapShare()" mat-button class="btn-document-action">
        <mat-icon>share</mat-icon>
        Partager
    </button>
    <label *ngIf="documentHistorique.allowTo.edit && !isShortcut" matRipple class="btn-document-action mat-button cursor-pointer overflow-hidden"
           for="fileDoc"><span class="mat-button-wrapper"><mat-icon>file_upload</mat-icon> Modifier</span></label>
    <input *ngIf="documentHistorique.allowTo.edit && !isShortcut" class="d-none" type="file" id="fileDoc"
           (change)="handleFileInput($event.target.files)">
    <button *ngIf="documentHistorique.allowTo.edit && !isShortcut" (click)="onTapRename()" mat-button class="btn-document-action">
        <mat-icon>edit</mat-icon>
        Renommer
    </button>
    <button *ngIf="documentHistorique.allowTo.delete && !isShortcut" (click)="onTapDelete()" mat-button
            class="btn-document-action">
        <mat-spinner *ngIf="spinner.delete" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.delete" class="text-danger">delete_outline</mat-icon>
        Supprimer
    </button>
    <button (click)="onTapDownload()" mat-button class="btn-document-action">
        <mat-spinner *ngIf="spinner.download" class="btn-spinner btn-spinner-sm btn-spinner-black"></mat-spinner>
        <mat-icon *ngIf="!spinner.download">get_app</mat-icon>
        Télécharger
    </button>
    <button (click)="onTapActivity()" mat-button class="btn-document-action">
        <mat-icon>chat</mat-icon>
        Activités
    </button>
</div>
<div *ngIf="version && open && documentHistorique?.document.versions" class="d-flex flex-column ml-4">
    <div *ngFor="let versionDocumentHistorique of documentHistorique.document.versions"
         class="d-flex flex-row align-items-center ">
        <div *ngIf="versionDocumentHistorique.id"
             class="d-flex flex-row align-items-center flex-fill  rounded px-2 mt-2 ml-4 bg-white">
            <div class="d-flex justify-content-between flex-fill mx-2 cursor-pointer">
                <div class="text-truncate w-50">{{versionDocumentHistorique.name}}</div>
                <div class="d-none d-xl-block ml-auto mr-4">{{versionDocumentHistorique.user.fullName}}.</div>
                <div class="d-none d-xl-block mr-4">{{versionDocumentHistorique.dateCreated | date: 'dd/MM/yyyy'}}</div>
                <div class="d-none d-xl-flex flex-row align-content-center mr-2">{{versionDocumentHistorique.version}}</div>
            </div>
            <button (click)="onTapDownload(versionDocumentHistorique)" mat-icon-button
                    class="ml-auto align-self-end cursor-pointer">
                <mat-icon>get_app</mat-icon>
            </button>
        </div>
    </div>
</div>
