<div class="d-flex flex-column bg-white rounded pt-3 px-3">
    <div *ngIf="data.nbDiscutions > 0" class="list-affair-notification">
        <i class="far fa-comment"></i> {{data.nbDiscutions}}
    </div>
    <div [routerLink]="['/affaire', data.id]" class="d-flex flex-row text-left cursor-pointer pb-3">
        <div class="d-flex flex-column pr-3">
            <img *ngIf="data.imageUrl" [src]="data.imageUrl" width="120" height="auto" alt=""
                 class="mb-2 align-self-center">
            <div *ngIf="data.stepLabel.step"
                 class="bg-white text-center rounded mt-auto p-2">{{data.stepLabel.step.label}}</div>
            <div *ngIf="data.stepLabel.avancement" class="affair-grid-progress">
                <div *ngIf="data.stepLabel.avancement[3].pourcent > 0" class="bg-green"
                     [style]="'width: ' + data.stepLabel.avancement[3].pourcent + '%'"></div>
                <div *ngIf="data.stepLabel.avancement[2].pourcent > 0" class="bg-yellow"
                     [style]="'width: ' + data.stepLabel.avancement[2].pourcent + '%'"></div>
                <div *ngIf="data.stepLabel.avancement[1].pourcent > 0" class="bg-red"
                     [style]="'width: ' + data.stepLabel.avancement[1].pourcent + '%'"></div>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div class="fz-18 font-weight-bold">{{data.label}}</div>
            <div>• {{data.reference}}</div>
            <div class="mb-2">{{data.postalCode}}</div>
            <ul class="list-unstyled mt-auto mb-0 fz-12">
                <li *ngIf="data.dateOffer">Offre : {{ convertDate(data.dateOffer) }}</li>
                <li *ngIf="data.datePose">Pose : {{ convertDate(data.datePose) }}</li>
            </ul>
        </div>
    </div>
</div>
