import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dossier, DOSSIER_STATUS} from '@src/app/model/dossier.model';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {DossierService} from '@src/app/services/dossier.service';
import {Affair} from '@src/app/model/affair.model';
import {ActionStateResult, AffairNavItems, AffairViewsItems} from '@src/app/shared/interfaces';
import {ModalService} from '@src/app/services/modal.service';
import {AppComponent} from '@src/app/app.component';
import {ApiService} from '@src/app/services/api.service';
import {AlertService} from '@src/app/services/alert.service';
import {ToastService} from '@src/app/services/toast.service';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
  selector: 'app-affair-nav',
  templateUrl: './affair-nav.component.html',
  styleUrls: ['./affair-nav.component.scss']
})
export class AffairNavComponent extends BaseComponent implements OnInit {

  @Input() viewContainerRef: ViewContainerRef;
  @Input() dossier: Dossier;
  @Input() affair: Affair;
  @Input() back: any[];
  @Input() backQueryParams: any;
  @Input() title: string;
  @Input() querySearch: any;
  @Input() isBusy: boolean;
  @Input() items: AffairNavItems;
  @Output() viewEmitter = new EventEmitter<AffairViewsItems>();
  statusLabel = DOSSIER_STATUS;
  views: AffairViewsItems = {
    isGlobalView: false,
    isEtapeView: true,
    isCalendarView: false,
  };

  constructor(
      private router: Router,
      private appComponent: AppComponent,
      private activatedRoute: ActivatedRoute,
      private dossierService: DossierService,
      private modalService: ModalService,
      private apiService: ApiService,
      private alertService: AlertService,
      private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
      if (urls[0].path === 'affaire') {
        const affairId = parseInt(urls[1].path, 10);
        this.activatedRoute.params.subscribe((params) => {
          if (params['view']) {
            this.updateView(params['view']);
            this.storageService.setAffairStepView(affairId, params['view']);
          } else {
            const storageView = this.storageService.getAffairStepView(affairId);
            if (storageView) {
              this.updateView(storageView.value);
            }
          }
        });
      }
    });
  }

  goBack(): void {
    this.router.navigate(this.back);
  }

  updateView(page: string): void {
    switch (page) {
      case 'global':
        this.views.isEtapeView = this.views.isCalendarView = false;
        this.views.isGlobalView = true;
        break;
      case 'etape':
        this.views.isCalendarView = this.views.isGlobalView = false;
        this.views.isEtapeView = true;
        break;
      case 'calendar':
        this.views.isGlobalView = this.views.isEtapeView = false;
        this.views.isCalendarView = true;
        break;
    }
    this.viewEmitter.emit(this.views);
  }

  setStorageView() {
    let pageView;
    if (this.views.isGlobalView) {
      pageView = 'global';
    } else if (this.views.isEtapeView) {
      pageView = 'etape';
    } else if (this.views.isCalendarView) {
      pageView = 'calendar';
    }
    this.storageService.setAffairStepView(this.affair.id, pageView);
  }

  onTapView(page: string): void {
    this.updateView(page);
    this.setStorageView();
  }

  // TODO affair service
  onTapTeamAffair() {
    const affair = this.affair || this.dossier.affair;
    this.modalService.show(
        { affair: affair },
        'team-affair',
        this.viewContainerRef,
        false,
    );
  }

  onTapDo(dossier: Dossier): void {
    this.dossierService.state(dossier, 1).subscribe((result: ActionStateResult) => {
      if (result) { this.dossier.state = 1; }
    });
  }

  onTapWork(dossier: Dossier): void {
    this.dossierService.state(dossier, 2).subscribe((result: ActionStateResult) => {
      if (result) { this.dossier.state = 2; }
    });
  }

  onTapValid(dossier: Dossier): void {
    this.dossierService.state(dossier, 3).subscribe((result: ActionStateResult) => {
      if (result) { this.dossier.state = 3; }
    });
  }

  onTapMore(dossier: Dossier, type: string) {
    this.dossierService.more(dossier, type, this.viewContainerRef)
        .pipe(takeUntil(this.notifier))
        .subscribe(result => {
          if (result && result.action) {
            setTimeout(() => {
              this[result.action](dossier);
            }, 300);
          }
        });
  }

  navigateToSearchPage(): void {
    this.router.navigate(['/rechercher'], {queryParams: this.querySearch});
  }

  onTapEditAffair(): void {
    this.modalService.show(
        { id: this.affair.id, type: 'affair-edit' },
        'base-modal',
        this.viewContainerRef,
        true
    ).subscribe((result) => {
      if (result) {
        this.appComponent.title = this.title = result.label + ' - ' + result.reference;
      }
    });
  }

  onTapDeleteAffair(): void {
    const text = 'Vous allez définitivement supprimer une affaire.\n' +
        'Cela supprimera aussi tous les dossiers, sous-dossiers et documents rattachés à cette affaire.\n' +
        'Vous ne pourrez pas récupérer cette affaire !';
    this.alertService.show(text).then((result) => {
      if (result.isConfirmed) {
        this.apiService.deleteAffair(this.affair.id).subscribe(() => {
          this.toastService.show(`L'affaire "${this.affair.label}" a bien été supprimé.`);
          this.router.navigate(['affaires']);
        });
      }
    });
  }

}
