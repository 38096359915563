<h1 mat-dialog-title>
    <span class="d-inline-block mt-1">Renommer ce fichier</span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <mat-form-field appearance="outline" class="input-full-width">
            <mat-label>Message</mat-label>
            <input matInput type="text" [(ngModel)]="name" required>
        </mat-form-field>

        <div class="text-center mt-3">
            <button [disabled]="name && name.length == 0 || isSaving" mat-raised-button color="primary" (click)="onSubmit()">
                <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
                <mat-icon *ngIf="!isSaving">create</mat-icon>
                Renommer
            </button>
        </div>
    </div>
</div>
