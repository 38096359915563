import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {User} from '@src/app/model/user.model';
import {StorageService} from '@src/app/services/storage.service';
import jwt_decode from 'jwt-decode';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@src/environments/environment';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {ActionUserResult, TokenResult} from '@src/app/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private storageService: StorageService,
    ) {
    }

    public login(user: User): Observable<TokenResult> {
        return this.http.post<TokenResult>(environment.apiUrl + '/authentication_token', {
            'email': user.email,
            'password': user.password
        });
    }

    public guestLogin(token): Observable<TokenResult> {
        return this.http.post<TokenResult>(environment.apiUrl + '/guest/authentication_token', {
            'token': token,
        });
    }

    public autoLogin(): Observable<boolean> {
        const roles = this.storageService.roles;

        if (roles.includes('ROLE_GUEST')) {
            const guestToken = this.storageService.guestToken;

            return this.guestLogin(guestToken)
                .pipe(
                    map(result => {
                        if (result.token) {
                            this.storageService.token = result.token;
                            return true;
                        } else {
                            return false;
                        }
                    })
                );
        } else {
            const user = new User();
            user.email = this.storageService.email;
            user.password = this.storageService.password;

            return this.login(user)
                .pipe(
                    map(result => {
                        if (result.token) {
                            this.storageService.token = result.token;
                            return true;
                        } else {
                            return false;
                        }
                    })
                );
        }
    }

    public authenticated() {
        const timestamp = moment().unix();
        try {
            const token = <any>jwt_decode(this.storageService.token);
            if (token.hasOwnProperty('exp')) {
                return timestamp < token.exp;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    public logout(redirect: boolean = true) {
        this.storageService.remove();
        if (redirect) {
            this.router.navigate(['login']);
        }
    }

    public resetPassword(email): Observable<ActionUserResult> {
        return this.http.post<ActionUserResult>(environment.apiUrl + '/rest/api/security/reset-password', {
            'email': email,
        });
    }

    public resetPasswordConfirm(token, password): Observable<ActionUserResult> {
        return this.http.post<ActionUserResult>(environment.apiUrl + '/rest/api/security/reset-password/confirm/' + token, {
            'password': password,
        });
    }
}
