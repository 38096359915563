import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {ApiService} from '@src/app/services/api.service';
import {SearchDocumentFilters} from '@src/app/shared/search.interfaces';
import {SimpleResponseObject} from '@src/app/shared/interfaces';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {DialogService} from '@src/app/services/dialog.service';
import {ModalService} from '@src/app/services/modal.service';
import {PICTOS} from '@src/app/shared/fa-picto-unicode';
import {Dossier} from '@src/app/model/dossier.model';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

export interface SearchParameters {
    terms: string;
    filters: SearchDocumentFilters;
}

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent extends BaseComponent implements OnInit, AfterViewInit {

    @Input() documentHistorique: DocumentHistorique;
    @Input() documentHistoriques: DocumentHistorique[];
    @Input() preview: boolean;
    public title: 'Recherche';
    public mode: MatDrawerMode = 'over';
    public backdrop = true;
    public documentList$: DocumentHistorique[] = null;
    public showDropdown: Array<boolean> = [];
    public dossier: Dossier;
    public documentsFiltersList: SearchDocumentFilters;
    public listDocumentsHistorique: DocumentHistorique;
    public searchParameters: SearchParameters = {
        terms: '',
        filters: {
            affairs: [],
            personnes: [],
            steps: [],
            dirids: [],
            date: null,
            after: null,
            before: null,
        }
    };
    public date: any;
    public pictos = PICTOS;

    @ViewChild('searchWeb') searchInputWeb: ElementRef;
    @ViewChild('search') searchInput: ElementRef;

    public constructor(
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private dialogService: DialogService,
        private modalService: ModalService,
        public viewContainerRef: ViewContainerRef,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    public ngOnInit(): void {

        if (this.preview) {
            // tslint:disable-next-line:no-unused-expression
            this.onTapViewer;
        }

        this.apiService.getDocumentsFilters()
            .pipe(takeUntil(this.notifier))
            .subscribe((data) => {
                this.documentsFiltersList = data;
                this.activatedRoute.queryParams.subscribe((params) => {
                    if (params['affair']) {
                        const affair = this.documentsFiltersList['affairs'].filter(
                            filterIndex => parseInt('' + filterIndex.id, 10) === parseInt(params['affair'], 10)
                        );
                        if (affair && affair.length) {
                            this.filterAdded('affairs', affair[0], false);
                        }
                    }
                    if (params['step']) {
                        const stepId = parseInt(params['step'], 10);
                        const step = this.documentsFiltersList['steps'].filter(filterIndex => parseInt('' + filterIndex.id, 10) === stepId);
                        if (step && step.length) {
                            this.filterAdded('steps', step[0], false);
                        }
                    }
                    if (params['dirid']) {
                        const dirId = parseInt(params['dirid'], 10);
                        const dirLabel = params['dirName'];
                        const dir = {id: dirId, label: dirLabel};
                        this.searchParameters.filters['dirids'].push(dir);
                    }
                });
                // this.searchDocument();
            });
    }

    setFocus() {
        this.searchInputWeb.nativeElement.focus();
    }

    ngAfterViewInit(): void {
        this.searchInputWeb.nativeElement.focus();
    }

    public searchDocument(args = null): void {
        if (args) {
            const searchField = args.object;
            searchField.dismissSoftInput();
        }
        this.apiService.searchDocument(this.searchParameters).subscribe((result) => {
            this.documentList$ = result;
        });
    }

    public clearSearchPhrase(args = null): void {
        if (args) {
            const searchField = args.object;
            searchField.dismissSoftInput();
        }
        this.searchParameters.terms = '';
        this.searchDocument();
    }

    public onTextChanged(args): void {
        const searchBar = args.object;
        this.searchParameters.terms = searchBar.text;
    }

    public onLinkTap(routerParams: any[]): void {
        this.router.navigate(routerParams);
    }

    public onDropdownClick(dropDownIndex): void {
        this.showDropdown[dropDownIndex] = !this.showDropdown[dropDownIndex];
    }

    public onSelectionChange(event, index: string) {
        this.documentsFiltersList[index] = this.documentsFiltersList[index].filter(filterIndex => filterIndex.id !== event.value.id);
        this.searchParameters.filters[index].push(event.value);
        this.searchDocument();
    }

    onTapViewer(documentHistorique: DocumentHistorique) {
        this.modalService.show({
            documentHistorique: documentHistorique,
            documentHistoriques: this.documentList$
        }, 'viewer', this.viewContainerRef, true);
    }

    public filterAdded(index: string, filter: SimpleResponseObject, search: boolean = true) {
        this.documentsFiltersList[index] = this.documentsFiltersList[index].filter(filterIndex => filterIndex.id !== filter.id);
        this.searchParameters.filters[index].push(filter);
        if (search) {
            this.searchDocument();
        }
    }

    public removeSearchCategoryFromList(index: string, filter: SimpleResponseObject, push: boolean = true) {
        this.searchParameters.filters[index] = this.searchParameters.filters[index].filter(filterIndex => filterIndex.id !== filter.id);
        if (push) {
            this.documentsFiltersList[index].push(filter);
        }
        this.searchDocument();
    }

    public addDateEvent($event, type: string = 'after') {
        this.searchParameters.filters[type] = moment($event.value).format('YYYY-MM-DD');
        this.searchDocument();
    }

    public onTapDialog(index: string) {
        const data = this.documentsFiltersList[index].map(el => el.label);
        this.dialogService.show(index, data).then((result) => {
            const filter = this.documentsFiltersList[index].find(el => el.label === result);
            if (filter) {
                this.onSelectionChange({value: this.documentsFiltersList[index].find(el => el.label === result)}, index);
            }
        });
    }

    public onTapDatepicker(type: string = 'after') {
        this.modalService.show(this.searchParameters.filters.date, 'datepicker', this.viewContainerRef, false).subscribe(result => {
            if (result) {
                this.addDateEvent({value: result}, type);
            }
        });
    }

    public goBack(): void {
        if (this.searchInput) {
            this.searchInput.nativeElement.dismissSoftInput();
        }
        this.location.back();
    }

    public goToStep(id: number) {
        this.router.navigate(['affaire', id]);
    }

    goToDossier(dossier: Dossier) {
        if (dossier.specificList) {
            this.router.navigate(['specific', dossier.id]);
        } else {
            this.router.navigate(['affair-dossier', dossier.id]);
        }
    }

    public showPreview(documentHistorique: DocumentHistorique) {
        return this.apiService.getPreview(documentHistorique.id);
    }
}
