import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@src/app/services/auth.service';
import {ActionResult} from '@src/app/shared/interfaces';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.web.html',
  styleUrls: ['./reset-password.component.web.scss']
})
export class ResetPasswordComponent extends BaseComponent {

  title = 'Mot de passe oublié';
  mode: MatDrawerMode = 'over';
  backdrop = true;
  isBusy = false;
  email: string;

  constructor(
      private router: Router,
      private authService: AuthService,
  ) {
    super();
    this.sharedService.updateTitle(this.title);
    this.sharedService.updateMode(this.mode);
    this.sharedService.updateBackdrop(this.backdrop);
  }

  submit() {
    this.isBusy = true;
    this.authService.resetPassword(this.email)
        .pipe(takeUntil(this.notifier))
        .subscribe((result: ActionResult) => {
          if (result.success) {
            this.router.navigate(['/login']);
          }
          this.isBusy = false;
        }, () => this.isBusy = false);
  }

}
