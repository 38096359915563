<div class="container-fluid page-container py-3">
    <div>
        <a routerLink="/affaires" mat-stroked-button color="primary" class="btn-rounded">
            <mat-icon>arrow_back_ios</mat-icon>
            Retour
        </a>
    </div>
    <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <form *ngIf="affairForm" [formGroup]="affairForm" (ngSubmit)="onSubmit()">
        <mat-vertical-stepper [linear]="isLinear" #stepper formArrayName="formArray">
            <!-- form general -->
            <mat-step [completed]="!isLinear" formGroupName="0" [stepControl]="formArray?.get([0])" label="Général">
                <div class="row">
                    <div class="col-md-5">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Nom</mat-label>
                            <input matInput type="text" formControlName="label" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Référence</mat-label>
                            <input matInput type="text" formControlName="reference" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>État</mat-label>
                            <mat-select formControlName="state" required>
                                <mat-option value="" disabled>Choisir un état</mat-option>
                                <mat-option *ngFor="let state of states"
                                            [value]="state.key">{{state.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Description</mat-label>
                            <textarea matInput type="text" formControlName="description" rows="5"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Chargés d'affaires</mat-label>
                            <mat-select multiple formControlName="charge_affairs" required>
                                <mat-option value="" disabled>Choisir des chargés d'affaires</mat-option>
                                <mat-option *ngFor="let chargeAffair of chargeAffairs"
                                            [value]="chargeAffair.id">{{chargeAffair.fullName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="outline" class="input-full-width">
                                    <mat-label>Utilisateurs</mat-label>
                                    <mat-select multiple formControlName="members">
                                        <mat-option value="" disabled>Choisir des utilisateurs</mat-option>
                                        <mat-option *ngFor="let user of users"
                                                    [value]="user.id">{{user.fullName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-auto mt-1">
                                <button mat-stroked-button color="primary" type="button" (click)="onAddUser()">
                                    <mat-icon>person_add</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Vignette</mat-label>
                            <ngx-mat-file-input matInput formControlName="file" placeholder="Vignette"></ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 mb-4">
                        <mat-checkbox formControlName="archive" color="primary">Archive</mat-checkbox>
                    </div>
                </div>
                <div>
                    <button mat-stroked-button matStepperNext color="primary" type="button" class="mr-2">
                        Suivant <mat-icon>navigate_next</mat-icon>
                    </button>
                    <button [disabled]="!affairForm.valid" mat-flat-button color="primary" type="submit">
                        <mat-spinner *ngIf="isUploading" class="btn-spinner btn-spinner-sm btn-spinner-white"></mat-spinner>
                        <mat-icon *ngIf="!isUploading">save</mat-icon>
                        Valider
                    </button>
                </div>
            </mat-step>
            <!-- form localisation -->
            <mat-step [completed]="!isLinear" formGroupName="1" [stepControl]="formArray?.get([1])" label="Localisation">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Adresse</mat-label>
                            <input matInput type="text" formControlName="address">
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Code postal</mat-label>
                            <input matInput type="text" formControlName="postalCode">
                        </mat-form-field>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Ville</mat-label>
                            <input matInput type="text" formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Pays</mat-label>
                            <mat-select formControlName="country">
                                <mat-option value="" disabled>Choisir un pays</mat-option>
                                <mat-option *ngFor="let p of pays" [value]="p">{{p}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <button mat-stroked-button matStepperPrevious color="primary" type="button" class="mr-2">
                        <mat-icon>navigate_before</mat-icon>
                        Précédent
                    </button>
                    <button mat-stroked-button matStepperNext color="primary" type="button" class="mr-2">Suivant
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                    <button [disabled]="!affairForm.valid" mat-flat-button color="primary" type="submit">
                        <mat-spinner *ngIf="isUploading" class="btn-spinner btn-spinner-sm btn-spinner-white"></mat-spinner>
                        <mat-icon *ngIf="!isUploading">save</mat-icon>
                        Valider
                    </button>
                </div>
            </mat-step>
            <!-- form dates -->
            <mat-step [completed]="!isLinear" formGroupName="2" [stepControl]="formArray?.get([2])" label="Dates">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Remise de l'offre</mat-label>
                            <input [matDatepicker]="pickerOffre" (click)="pickerOffre.open()"
                                   matInput formControlName="dateOffer" autocomplete="off" required>
                            <mat-datepicker-toggle matSuffix [for]="pickerOffre"></mat-datepicker-toggle>
                            <mat-datepicker #pickerOffre></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Pose</mat-label>
                            <input [matDatepicker]="pickerPose" (click)="pickerPose.open()"
                                   matInput formControlName="datePose" autocomplete="off" required>
                            <mat-datepicker-toggle matSuffix [for]="pickerPose"></mat-datepicker-toggle>
                            <mat-datepicker #pickerPose></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Consultation</mat-label>
                            <input [matDatepicker]="pickerConsultation" (click)="pickerConsultation.open()"
                                   matInput formControlName="dateConsultation" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="pickerConsultation"></mat-datepicker-toggle>
                            <mat-datepicker #pickerConsultation></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Hypothèse</mat-label>
                            <input [matDatepicker]="pickerHypothese" (click)="pickerHypothese.open()"
                                   matInput formControlName="dateHypothese" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="pickerHypothese"></mat-datepicker-toggle>
                            <mat-datepicker #pickerHypothese></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Conception</mat-label>
                            <input [matDatepicker]="pickerConception" (click)="pickerConception.open()"
                                   matInput formControlName="dateConception" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="pickerConception"></mat-datepicker-toggle>
                            <mat-datepicker #pickerConception></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Fabrication</mat-label>
                            <input [matDatepicker]="pickerFabrication" (click)="pickerFabrication.open()"
                                   matInput formControlName="dateFabrication" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="pickerFabrication"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFabrication></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Livraison</mat-label>
                            <input [matDatepicker]="pickerLivraison" (click)="pickerLivraison.open()"
                                   matInput formControlName="dateLivraison" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="pickerLivraison"></mat-datepicker-toggle>
                            <mat-datepicker #pickerLivraison></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Chantier</mat-label>
                            <input [matDatepicker]="pickerChantier" (click)="pickerChantier.open()"
                                   matInput formControlName="dateChantier" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="pickerChantier"></mat-datepicker-toggle>
                            <mat-datepicker #pickerChantier></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="input-full-width">
                            <mat-label>Réception</mat-label>
                            <input [matDatepicker]="pickerReception" (click)="pickerReception.open()"
                                   matInput formControlName="dateReception" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="pickerReception"></mat-datepicker-toggle>
                            <mat-datepicker #pickerReception></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <button mat-stroked-button matStepperPrevious color="primary" type="button" class="mr-2">
                        <mat-icon>navigate_before</mat-icon>
                        Précédent
                    </button>
                    <button [disabled]="!affairForm.valid" mat-flat-button color="primary" type="submit">
                        <mat-spinner *ngIf="isUploading" class="btn-spinner btn-spinner-sm btn-spinner-white"></mat-spinner>
                        <mat-icon *ngIf="!isUploading">save</mat-icon>
                        Valider
                    </button>
                </div>
            </mat-step>
        </mat-vertical-stepper>
    </form>
</div>
