import {Injectable} from '@angular/core';
import CryptoJS from 'crypto-js';
import {Config} from '@src/app/shared/config';
import {User} from '@src/app/model/user.model';
import {AffairFilter, RoleType, SocietyFilter, StorageView, TokenResult, UserFilter} from '@src/app/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private tokenKey = '_t';
    private userIdKey = '_ui';
    private contactIdKey = '_ci';
    private rolesKey = '_r';
    private initialeKey = '_i';
    private fullNameKey = '_f';
    private emailKey = '_e';
    private passwordKey = '_p';
    private affairListViewKey = '_alv';
    private affairListFilterKey = '_alf';
    private userListFilterKey = '_ulf';
    private societyListFilterKey = '_slf';
    private affairStepViewKey = '_asv';
    private guestTokenKey = '_gt';
    private guestDossierIdKey = '_gdi';

    private secureStorage: any;

    private encrypt(value) {
        return CryptoJS.AES.encrypt(value, Config.hash).toString();
    }

    private decrypt(value) {
        if (value) {
            return CryptoJS.AES.decrypt(value, Config.hash).toString(CryptoJS.enc.Utf8);
        }
        return false;
    }

    get token() {
        return this.decrypt(localStorage.getItem(this.tokenKey));
    }

    set token(value: string) {
        localStorage.setItem(this.tokenKey, this.encrypt(value));
    }

    get userId(): number {
        return parseInt(this.decrypt(localStorage.getItem(this.userIdKey)), 10);
    }

    set userId(value: number) {
        localStorage.setItem(this.userIdKey, this.encrypt(value + ''));
    }

    get contactId(): number {
        return parseInt(this.decrypt(localStorage.getItem(this.contactIdKey)), 10);
    }

    set contactId(value: number) {
        localStorage.setItem(this.contactIdKey, this.encrypt(value + ''));
    }

    get roles(): RoleType[] {
        return JSON.parse(this.decrypt(localStorage.getItem(this.rolesKey)));
    }

    set roles(values: RoleType[]) {
        localStorage.setItem(this.rolesKey, this.encrypt(JSON.stringify(values)));
    }

    get initiale() {
        return this.decrypt(localStorage.getItem(this.initialeKey));
    }

    set initiale(value: string) {
        localStorage.setItem(this.initialeKey, this.encrypt(value));
    }

    get fullName() {
        return this.decrypt(localStorage.getItem(this.fullNameKey));
    }

    set fullName(value: string) {
        localStorage.setItem(this.fullNameKey, this.encrypt(value));
    }

    get email(): string {
        return this.decrypt(localStorage.getItem(this.emailKey));
    }

    set email(value: string) {
        localStorage.setItem(this.emailKey, this.encrypt(value));
    }

    get password(): string {
        return this.decrypt(localStorage.getItem(this.passwordKey));
    }

    set password(value: string) {
        localStorage.setItem(this.passwordKey, this.encrypt(value));
    }

    get affairListView(): string {
        return this.decrypt(localStorage.getItem(this.affairListViewKey));
    }

    set affairListView(value: string) {
        localStorage.setItem(this.affairListViewKey, this.encrypt(value));
    }

    get affairListFilter(): AffairFilter {
        return JSON.parse(localStorage.getItem(this.affairListFilterKey));
    }

    set affairListFilter(value: AffairFilter) {
        localStorage.setItem(this.affairListFilterKey, JSON.stringify(value));
    }

    get userListFilter(): UserFilter {
        return JSON.parse(localStorage.getItem(this.userListFilterKey));
    }

    set userListFilter(value: UserFilter) {
        localStorage.setItem(this.userListFilterKey, JSON.stringify(value));
    }

    get societyListFilter(): SocietyFilter {
        return JSON.parse(localStorage.getItem(this.societyListFilterKey));
    }

    set societyListFilter(value: SocietyFilter) {
        localStorage.setItem(this.societyListFilterKey, JSON.stringify(value));
    }

    get guestToken(): string {
        return this.decrypt(localStorage.getItem(this.guestTokenKey));
    }

    set guestToken(value: string) {
        localStorage.setItem(this.guestTokenKey, this.encrypt(value));
    }

    get guestDossierId(): string {
        return this.decrypt(localStorage.getItem(this.guestDossierIdKey));
    }

    set guestDossierId(value: string) {
        localStorage.setItem(this.guestDossierIdKey, this.encrypt(value + ''));
    }

    getAffairStepViews(): StorageView[] {
        const items = JSON.parse(localStorage.getItem(this.affairStepViewKey));
        return Array.isArray(items) ? items : [];
    }

    getAffairStepView(key: number): StorageView {
        const inputs = this.getAffairStepViews();
        return inputs.find(i => i.key === key);
    }

    setAffairStepView(key: number, value: string): void {
        const inputs = this.getAffairStepViews();
        if (inputs.find(i => i.key === key)) {
            inputs.map(i => i.value = value);
        } else {
            const input: StorageView = {
                key: key,
                value: value,
            };
            inputs.push(input);
        }
        localStorage.setItem(this.affairStepViewKey, JSON.stringify(inputs));
    }

    afterLoginSave(result: TokenResult, user: User = null) {
        this.token = result.token;
        this.userId = result.user.id;
        this.contactId = result.contact.id;
        this.roles = result.user.roles;
        this.initiale = result.user.initiale;
        this.fullName = result.user.fullName;
        if (user) {
            this.email = user.email;
            this.password = user.password;
        }
    }

    getUser(): User {
        return {
            id: this.userId,
            email: this.email,
            fullName: this.fullName,
            roles: this.roles,
            initiale: this.initiale,
            contact: {
                id: this.contactId,
                fullName: this.fullName,
                initiale: this.initiale,
                email: this.email,
            }
        };
    }

    remove() {
        localStorage.clear();
    }
}
