<mat-sidenav-container >
    <!---------- Menu filtres ---------->
    <mat-sidenav  class="filter-sidenav" mode="over" position="end" #filter>

        <!-- bouton pour fermer la fenêtre filtres -->
        <div class="d-flex justify-content-end">
            <button mat-button (click)="filter.close()">
                <span>Reduire</span>
                <mat-icon fontSet="fas" fontIcon="fa-angle-double-right"></mat-icon>
            </button>
        </div>

        <!---------- Appel menu filtres ---------->
        <app-annuaire-user-filter *ngIf="views.isUser" (usersFiltred)="userFilterHandler($event)"></app-annuaire-user-filter>
        <app-annuaire-society-filter *ngIf="views.isSociety" (societiesFiltred)="societyFilterHandler($event)"></app-annuaire-society-filter>
    </mat-sidenav>

    <mat-sidenav-content #content class="bg-white">
        <div class="d-flex flex-column">
            <app-annuaire-nav [back]="back" [title]="isBusy ? title : 'Annuaires'"
            [querySearch]="querySearch" [isBusy]="isBusy" [items]="items"
            (viewEmitter)="viewHandler($event)" (filterEmitter)="filterHandler()"
            [isUserFilterActif]="isUserFilterActif" [isSocietyFilterActif]="isSocietyFilterActif"
            class="d-flex flex-row justify-content-between align-items-center bg-primary-light p-3">
            </app-annuaire-nav>

            <app-annuaire-user *ngIf="views.isUser" [container]="content" [viewContainerRef]="viewContainerRef" [usersResult$]="usersResult$"></app-annuaire-user>
            <app-annuaire-societe *ngIf="views.isSociety" [container]="content" [viewContainerRef]="viewContainerRef" [societiesResult$]="societiesResult$"></app-annuaire-societe>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
