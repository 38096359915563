import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Affair, AffairMember} from '@src/app/model/affair.model';
import {ApiService} from '@src/app/services/api.service';

@Component({
    selector: 'app-modal-team-affair',
    templateUrl: './modal-team-affair.component.web.html',
})
export class ModalTeamAffairComponent implements OnInit {

    isLoading: boolean;
    affair: Affair;
    members: AffairMember[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {affair: Affair},
        private apiService: ApiService,
    ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.apiService.getAffairGlobal(this.data.affair.id).subscribe((affair) => {
            this.affair = affair;
            this.members = this.affair.members.affair;
            this.isLoading = false;
        });
    }

}
