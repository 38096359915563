<div fxFlex fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="isBusy" class="d-flex flex-column justify-content-center align-items-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <h1>Récupération des informations</h1>
    </div>
    <div *ngIf="isAuthenticating" class="d-flex flex-column justify-content-center align-items-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <h1>Connexion</h1>
    </div>
</div>
