import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AnnuaireNavItems, AnnuaireViewsItems} from '@src/app/shared/interfaces';
import {BaseComponent} from '@src/app/shared/base.component';
import {Location} from '@angular/common';
import {ModalService} from '@src/app/services/modal.service';
import {User} from '@src/app/model/user.model';
import {Society} from '@src/app/model/society.model';

@Component({
  selector: 'app-annuaire-nav',
  templateUrl: './annuaire-nav.component.html',
  styleUrls: ['./annuaire-nav.component.scss']
})
export class AnnuaireNavComponent extends BaseComponent implements OnInit {

  @Input() viewContainerRef: ViewContainerRef;
  @Input() back: any[];
  @Input() title: string;
  @Input() querySearch: any;
  @Input() isBusy: boolean;
  @Input() items: AnnuaireNavItems;
  @Input() isUserFilterActif: boolean;
  @Input() isSocietyFilterActif: boolean;
  @Output() viewEmitter = new EventEmitter<AnnuaireViewsItems>();
  @Output() filterEmitter = new EventEmitter<string>();
  @Output() userEmitter = new EventEmitter<User>();
  @Output() societyEmitter = new EventEmitter<Society>();
  views: AnnuaireViewsItems = {
    isUser: true,
    isSociety: false,
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['view']) {
        this.onTapView(params['view']);
      }
    });
  }

  onTapView(page: string): void {
    switch (page) {
      case 'society':
        this.views.isUser = false;
        this.views.isSociety = true;
        break;
      case 'user':
        this.views.isSociety = false;
        this.views.isUser = true;
        break;
    }
    const url = this.router.createUrlTree(['annuaire', page]).toString();
    this.location.replaceState(url);
    this.viewEmitter.emit(this.views);
  }

  onTapFilter(): void {
    this.filterEmitter.emit(this.views.isUser ? 'user' : 'society');
  }

  onTapAddUser() {
    // this.router.navigate(['annuaire', 'user', 'edit']);
    this.modalService.show(
        {},
        'user',
        null,
        false
    ).subscribe((result) => {
      if (result) { this.userEmitter.emit(result); }
    });
  }

  onTapAddSociety() {
    // this.router.navigate(['annuaire', 'society', 'edit']);
    this.modalService.show(
        {},
        'society',
        null,
        false
    ).subscribe((result: Society) => {
      if (result) {
        this.societyEmitter.emit(result);
      }
    });
  }
}
