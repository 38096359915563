import {Component, EventEmitter, Input, Output, OnChanges, ViewContainerRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {DocumentHistorique} from '@src/app/model/document-historique.model';
import {Dossier} from '@src/app/model/dossier.model';
import {ActionResult, ClicEmitter, SimpleChanges} from '@src/app/shared/interfaces';
import {ActionBottomSheetService} from '@src/app/services/action-bottom-sheet.service';
import {UploadImageService} from '@src/app/services/upload-image.service';
import {DocumentService} from '@src/app/services/document.service';
import {BaseComponent} from '@src/app/shared/base.component';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
    providers: [ActionBottomSheetService]
})
export class DocumentComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() documentHistorique: DocumentHistorique;
    @Input() documentHistoriques: DocumentHistorique[];
    @Input() selectedDocumentHistorique: DocumentHistorique;
    @Input() dossier: Dossier;
    @Input() status: number;
    @Input() preview: boolean;
    @Input() viewContainerRef: ViewContainerRef;
    @Output() documentClicked = new EventEmitter<ClicEmitter>();
    @Output() activityAdded = new EventEmitter<DocumentHistorique>();
    open = false;
    version = false;
    spinner = {
        favoris: false,
        state: false,
        follow: false,
        download: false,
        delete: false,
    };
    // TODO Refactor avec upload
    uploading = false;
    processing = false;
    progress = 0;
    // upload = {
    //     isUploading: false,
    //     isProcessing: false,
    //     progress: 0,
    // };
    isActive: boolean;

    constructor(
        private router: Router,
        private uploadImageService: UploadImageService,
        private documentService: DocumentService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.preview) {
            this.onTapViewer();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.selectedDocumentHistorique &&
            changes.selectedDocumentHistorique.currentValue &&
            this.documentHistorique &&
            changes.selectedDocumentHistorique.currentValue.id === this.documentHistorique.id
        ) {
            this.open = true;
        }

        if (
            changes.selectedDocumentHistorique &&
            changes.selectedDocumentHistorique.currentValue &&
            this.documentHistorique &&
            changes.selectedDocumentHistorique.currentValue.id !== this.documentHistorique.id
        ) {
            this.open = false;
        }

        if (changes.status) {
            if (changes.status.currentValue === 1 && (this.documentHistorique.state === 1 || this.documentHistorique.state === 4)) {
                this.isActive = true;
            } else if (changes.status.currentValue === 2 && this.documentHistorique.state === 2) {
                this.isActive = true;
            } else if (changes.status.currentValue === 3 && this.documentHistorique.state === 3) {
                this.isActive = true;
            } else if (changes.status.currentValue === 0) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        }
    }

    toggle() {
        this.open = !this.open;
    }

    toggleVersion() {
        this.version = !this.version;
    }

    handleFileInput(file) {
        const formData: FormData = new FormData();
        formData.append('file', file[0], file[0].name);
        this.progress = 0;
        this.uploading = true;
        this.processing = false;
        this.documentService.update(this.documentHistorique, formData)
            .subscribe((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        this.processing = true;
                        break;
                    case HttpEventType.ResponseHeader:
                        // TODO voir si utile
                        break;
                    case HttpEventType.UploadProgress:
                        this.progress = Math.round(event.loaded / event.total * 100);
                        break;
                    case HttpEventType.Response:
                        this.router.navigate(['affair-dossier', this.dossier.id, 'ajouter', event.body.id, file[0].name]);
                        break;
                }
            });
    }

    toggleInformationDocument() {
        this.toggle();
        this.documentClicked.emit({documentHistorique: this.documentHistorique, isOpen: this.open});
    }

    toggleFavoriDocument() {
        this.spinner.favoris = true;
        if (this.documentHistorique.document.isFavoris) {
            this.documentService.unfavoris(this.documentHistorique)
                .subscribe((result: ActionResult) => {
                    if (result.success) {
                        this.documentHistorique.document.isFavoris = false;
                        this.spinner.favoris = false;
                        this.activityAdded.emit(this.documentHistorique);
                    }
                }, () => this.spinner.favoris = false);
        } else {
            this.documentService.favoris(this.documentHistorique)
                .subscribe(() => {
                    this.documentHistorique.document.isFavoris = true;
                    this.spinner.favoris = false;
                    this.activityAdded.emit(this.documentHistorique);
                }, () => this.spinner.favoris = false);
        }
    }

    onTapDownload(documentHistorique: DocumentHistorique = null) {
        this.spinner.download = true;
        if (documentHistorique === null) { documentHistorique = this.documentHistorique; }
        this.documentService.download(documentHistorique)
            .then(() => this.spinner.download = false)
            .catch(() => this.spinner.download = false);
    }

    onDelete() {
        this.spinner.delete = true;
        this.documentService.delete(this.documentHistorique)
            .subscribe((result: ActionResult) => {
                if (result.success) {
                    this.dossier.documentHistoriques = this.dossier.documentHistoriques.filter(d => d.id !== this.documentHistorique.id);
                    this.documentClicked.emit({documentHistorique: this.documentHistorique, isOpen: false});
                }
                this.spinner.delete = false;
            }, () => this.spinner.delete = false);
    }

    onTapValid() {
        this.spinner.state = true;
        this.documentService.state(this.documentHistorique, 3)
            .subscribe((result: ActionResult) => {
                if (result.success) {
                    this.documentHistorique.state = 3;
                    this.activityAdded.emit(this.documentHistorique);
                }
                this.spinner.state = false;
                this.isActive = false; // TODO Why ?
            }, () => this.spinner.state = false);
    }

    onTapWork() {
        this.spinner.state = true;
        this.documentService.state(this.documentHistorique, 2)
            .subscribe((result: ActionResult) => {
                if (result.success) {
                    this.documentHistorique.state = 2;
                    this.activityAdded.emit(this.documentHistorique);
                }
                this.spinner.state = false;
                this.isActive = false; // TODO Why ?
            }, () => this.spinner.state = false);
    }

    onTapDo() {
        this.spinner.state = true;
        this.documentService.state(this.documentHistorique, 1)
            .subscribe((result: ActionResult) => {
                if (result.success) {
                    this.documentHistorique.state = 1;
                    this.activityAdded.emit(this.documentHistorique);
                }
                this.spinner.state = false;
                this.isActive = false; // TODO Why ?
            }, () => this.spinner.state = false);
    }

    toggleSuivis() {
        this.spinner.follow = true;
        if (this.documentHistorique.document.isSuivis) {
            this.documentService.unfollow(this.documentHistorique)
                .subscribe((result: ActionResult) => {
                    if (result.success) {
                        this.documentHistorique.document.isSuivis = false;
                        this.activityAdded.emit(this.documentHistorique);
                    }
                    this.spinner.follow = false;
                }, () => this.spinner.follow = false);
        } else {
            this.documentService.follow(this.documentHistorique)
                .subscribe((result: ActionResult) => {
                    if (result.success) {
                        this.documentHistorique.document.isSuivis = true;
                        this.activityAdded.emit(this.documentHistorique);
                    }
                    this.spinner.follow = false;
                }, () => this.spinner.follow = false);
        }
    }

    onTapShare() {
        this.documentService.share(this.documentHistorique, this.viewContainerRef);
    }

    onTapRename() {
        this.documentService.rename(this.documentHistorique, this.viewContainerRef);
        // TODO ajouter this.activityAdded.emit(this.documentHistorique); dans subscribe
    }

    onTapViewer() {
        if (this.documentHistorique.nbNotifications.total > 0) {
            this.documentService.isReaded(this.documentHistorique)
                .subscribe((result: ActionResult) => {
                    if (result.success) {
                        this.documentHistorique.nbNotifications.total = 0;
                    }
                });
        }

        this.documentService.viewer(this.documentHistorique, this.documentHistoriques, this.viewContainerRef);
    }

    private updateFile(documentHistorique: DocumentHistorique, event) {
        this.documentService.waitingFile(documentHistorique, event, 'update', this.viewContainerRef)
            .subscribe((result) => {
                if (result) {
                    setTimeout(() => {
                        this.router.navigate(
                            ['affair-dossier', this.dossier.id, 'ajouter', result.response.id, result.response.document.name],
                            {skipLocationChange: true}
                        );
                    }, 300);
                }
            });
    }

    onTapAddMedia(documentHistorique: DocumentHistorique) {
        this.uploadImageService.uploadMedia('update').then((event) => {
            this.updateFile(documentHistorique, event);
        });
    }

    onTapAddFile(documentHistorique: DocumentHistorique) {
        this.uploadImageService.uploadFile('update').then((event) => {
            this.updateFile(documentHistorique, event);
        });
    }

    onTapAddCamera(documentHistorique: DocumentHistorique) {
        this.uploadImageService.uploadCamera('update').then((event) => {
            this.updateFile(documentHistorique, event);
        });
    }

    onTapEdit() {
        this.documentService.moreFile(this.dossier, 'update', this.viewContainerRef)
            .subscribe(result => {
                if (result && result.action) {
                    setTimeout(() => {
                        this[result.action](this.documentHistorique);
                    }, 300);
                }
            });
    }

    onTapActivity() {
        this.documentHistorique.nbDiscutions = 0;
        this.documentHistorique.nbNotifications.total = 0;
        this.documentService.activity(this.documentHistorique, this.viewContainerRef);
    }

    onTapMore() {
        this.documentService.moreAction(this.documentHistorique, this.viewContainerRef)
            .subscribe(result => {
                if (result && result.action) {
                    setTimeout(() => { this[result.action](); }, 300);
                }
            });
    }
}
