export const PICTOS = {
    'fa-file-excel': '',
    'fa-file-pdf': '',
    'fa-file-video': '',
    'fa-file-image': '',
    'fa-file-word': '',
    'fa-file-powerpoint': '',
    'fa-file-archive': '',
    'fa-file-alt': '',
    'fa-file': '',
};
