import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from '@src/app/app.component';
import {ApiService} from '@src/app/services/api.service';
import {AuthService} from '@src/app/services/auth.service';
import {BaseComponent} from '@src/app/shared/base.component';
import {MatDrawerMode} from '@angular/material/sidenav';

@Component({
    selector: 'app-guest',
    templateUrl: './guest.component.web.html',
    styleUrls: ['./guest.component.web.scss'],
})
export class GuestComponent extends BaseComponent implements OnInit {
    title = 'Connexion Invité';
    mode: MatDrawerMode = 'over';
    backdrop = true;
    isBusy: boolean;
    isAuthenticating: boolean;
    token: string;
    idDossier: string;
    affair: any;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private appComponent: AppComponent,
        private apiService: ApiService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        super();
        this.sharedService.updateTitle(this.title);
        this.sharedService.updateMode(this.mode);
        this.sharedService.updateBackdrop(this.backdrop);
    }

    ngOnInit(): void {
        this.isBusy = true;
        this.activatedRoute.params.subscribe((params) => {
            this.token = params['token'];
            this.idDossier = params['dossier'];
            this.authService.logout(false);
            this.isBusy = false;
            this.login();
        });
    }

    login(): void {
        this.isAuthenticating = true;
        this.authService.guestLogin(this.token).subscribe(
            (result) => {
                result.user.roles = ['ROLE_GUEST'];
                this.storageService.afterLoginSave(result);
                this.storageService.guestToken = this.token;
                this.storageService.guestDossierId = this.idDossier;
                this.appComponent.initiale = result.user.initiale;
                // this.appComponent.roles = result.user.roles;
                this.sharedService.updateRoles(result.user.roles);
                this.appComponent.connected = true;
                // this.router.navigate(['affaire', this.storageService.guestAffairId]);
                this.router.navigate(['specific-detail', this.storageService.guestDossierId, this.storageService.userId]);
            },
            (error) => {
                alert('Malheureusement, nous n\'avons pas pu trouver de compte lié à votre token.');
                this.isAuthenticating = false;
            }
        );
    }

}
