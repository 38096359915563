<mat-sidenav-container class="affairList__container">

    <!---------- Menu filtres ---------->
    <mat-sidenav class="filter-sidenav" mode="over" position="end" #filter>

        <!-- bouton pour fermer la fenêtre filtres -->
        <div class="d-flex justify-content-end">
            <button mat-button (click)="filter.close()">
                <span>Reduire</span>
                <mat-icon fontSet="fas" fontIcon="fa-angle-double-right"></mat-icon>
            </button>
        </div>

        <!---------- Appel menu filtres ---------->
        <!-- On applique un écouteur lors de l'appel de app-affair-filter avec en paramètre un fonction déclarée dans affair-list.componenet.ts qui nous permet d'instentier au html le filtre sur la liste des affaires présentes dans l'API, et de le rendre fonctionnel / visuel -->
        <app-affair-filter (affairsFiltred)="affairFilterHandler($event)"></app-affair-filter>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="container-fluid container-affair-list d-flex flex-column ">
            <div class="row align-items-center py-3">
                <div class="col">
                    <a *ngIf="hasRoles(['ROLE_AFFAIRE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])" routerLink="/affair-form" mat-stroked-button color="primary" class="btn-rounded">
                        <mat-icon>add</mat-icon>
                        Ajouter une affaire
                    </a>
                </div>
                <div class="col d-flex align-items-center justify-content-center">
                    <mat-icon (click)="onToggleView(true)"
                              [class.active]="isGrid" [class.has-ripple-light]="isGrid"
                              matRipple matTooltip="Vue grille" matTooltipPosition="above"
                              class="icon-30 text-primary cursor-pointer has-ripple"
                              fontSet="fas" fontIcon="fa-th-large"></mat-icon>
                    <mat-icon (click)="onToggleView(false)" [class.active]="!isGrid" [class.has-ripple-light]="!isGrid"
                              matRipple matTooltip="Vue liste"
                              matTooltipPosition="above" class="icon-30 text-primary cursor-pointer has-ripple">menu
                    </mat-icon>
                </div>

                <div class="col d-flex align-items-center justify-content-end">
<!--                    <mat-form-field class="mr-2 pb-0" appearance="outline" (click)="navigateToSearchPage()">-->
<!--                        <mat-label>Saisir ma recherche</mat-label>-->
<!--                        <span matPrefix><mat-icon>search</mat-icon></span>-->
<!--                        <input matInput>-->
<!--                    </mat-form-field>-->

                    <!---------- Boutton d'ouverture du menu filtres ---------->
                    <mat-icon (click)="filter.open()" matRipple matTooltip="Menu filtres" matTooltipPosition="above"
                              class="icon-30 text-primary cursor-pointer has-ripple" [class.active]="isFilterActif" [class.has-ripple-light]="isFilterActif">
                        filter_alt
                    </mat-icon>
                </div>
            </div>

            <mat-paginator *ngIf="affairs" [length]="length"
                           [pageSize]="pageSize" [pageIndex]="pageIndex"
                           [pageSizeOptions]="pageSizeOptions"
                           [showFirstLastButtons]="true"
                           (page)="pageEventHandler($event)">
            </mat-paginator>

            <div *ngIf="isBusy" class="d-flex justify-content-center align-items-center">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>


            <p *ngIf="!isBusy && !affairs.length" class="no-result d-flex justify-content-center align-items-center text-danger">
                <mat-icon>report</mat-icon>
                Aucun résultat ! Essayer une autre recherche.
            </p>

            <div *ngIf="!isBusy" [class.row]="isGrid">
                <app-list-item *ngFor="let affair of affairs; trackBy: trackBy;"
                               [affair]="affair" (affairDelete)="affairDeleteHandler($event)"
                               [isGrid]="isGrid" class="d-block mb-3"
                               [ngClass]="{'col-md-6 col-lg-4 col-xl-3': isGrid}">
                </app-list-item>
            </div>

            <mat-paginator *ngIf="affairs" [length]="length"
                           [pageSize]="pageSize" [pageIndex]="pageIndex"
                           [pageSizeOptions]="pageSizeOptions"
                           [showFirstLastButtons]="true"
                           (page)="pageEventHandler($event)">
            </mat-paginator>

        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
