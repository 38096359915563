<div class="d-flex flex-row justify-content-start align-items-center text-left bg-primary-light rounded my-2 pl-3 py-1">
    <div (click)="onTapUser()" class="d-flex flex-row justify-content-start align-items-center cursor-pointer w-100">
        <div class="d-flex flex-row align-items-center">
            <mat-icon>{{ user.visible ? 'person' : 'person_outline' }}</mat-icon>
            <span class="ml-1 mr-3">{{user.fullName}}</span>
            <span *ngIf="user.contact && user.contact.society" class="chip__blue fz-12">
                {{user.contact.society?.name}}
            </span>
        </div>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menuUser" [matMenuTriggerData]="{user: user}"
            matTooltip="Menu édition" matTooltipPosition="above" class="ml-auto">
        <mat-icon>more_vert</mat-icon>
    </button>
</div>

<mat-menu #menuUser="matMenu" xPosition="before">
    <ng-template matMenuContent let-user="user">
        <a *ngIf="user.contact.phone" mat-menu-item href="tel:{{user.contact.phone}}">
            <mat-icon>phone</mat-icon>
            <span>{{user.contact.phone}}</span>
        </a>
        <a *ngIf="user.email" mat-menu-item href="mailto:{{user.email}}">
            <mat-icon>mail_outline</mat-icon>
            <span>{{user.email}}</span>
        </a>
        <button *ngIf="!user.visible" mat-menu-item (click)="onTapEditToUser()">
            <mat-icon>edit</mat-icon>
            <span>Transformer</span>
        </button>
        <button mat-menu-item (click)="onTapEdit()">
            <mat-icon>edit</mat-icon>
            <span>Modifier</span>
        </button>
        <button mat-menu-item (click)="onTapDelete()">
            <mat-icon class="text-danger">delete_outline</mat-icon>
            <span>Supprimer</span>
        </button>
    </ng-template>
</mat-menu>
