<h1 mat-dialog-title >
    <span class="d-inline-block mt-1">Favoris du raccourci <span *ngIf="!isLoading">{{shortcut.label}}</span></span>
    <button mat-dialog-close mat-icon-button color="warn" class="float-right ml-3">
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center p-5">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <mat-selection-list [compareWith]="compareList" [formControl]="formControlObj">
            <mat-list-option *ngFor="let documentHistorique of shortcut.documentHistoriques" [value]="documentHistorique" color="primary">
                <div mat-line>{{documentHistorique.name}}</div>
            </mat-list-option>
        </mat-selection-list>
    </div>
</div>
<div *ngIf="!isLoading" mat-dialog-actions class="justify-content-end pb-3">
    <button [disabled]="isSaving" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
        <mat-spinner *ngIf="isSaving" class="btn-spinner btn-spinner-white"></mat-spinner>
        <mat-icon *ngIf="!isSaving">save</mat-icon>
        Enregistrer
    </button>
</div>
